<template>
<div class="px-4 mb-2">
    <div class="container">
        <div class="col-md-12 table-responsive" id="div-tabla-anular">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                <div class="input-group input-group">
                    <span class="input-group-text text-white bg-main"
                    ><i class="fas fa-search"></i
                    ></span>
                    <input
                    v-model="search"
                    type="text"
                    class="form-control form-control"
                    placeholder="Buscar Paciente"
                    />
                </div>
            </div>
            <table id="tb-anular" class="table table-bordered">
                <caption></caption>
                <thead class="bg-main" style="font-size: 12px;">
                    <tr class="text-white text-center">
                        <th scope="col" class="align-middle">
                            N°
                        </th>
                        <th scope="col" class="align-middle">
                            FECHA
                        </th>
                        <th scope="col" class="align-middle">
                            PACIENTE
                        </th>
                        <th scope="col" class="align-middle">
                            DOCTOR
                        </th>
                        <th scope="col" class="align-middle">
                            ORIGEN
                        </th>
                        <th scope="col" colspan="1" class="align-middle">
                            ESTADO
                        </th>
                        <th scope="col" colspan="1" class="align-middle">
                            ACCIONES
                        </th>
                    </tr>
                </thead>
        
                <tbody class="border-main" style="font-size: 12px;">
                    <tr class="tbody-table align-middle" v-for="(item, index) in cpData" :key="index">
                        <td  class="clickCarrito">
                            {{mtdNumber(index)}}
                        </td>
                        <td>
                            {{ item.created_at | formatDateTime }}
                        </td>
                        <td>
                            {{ item.patient ? item.patient.name : ' ' }}  ({{ item.patient ? item.patient.document : ' ' }})
                        </td>
                        <td>
                            {{ item.doctor ? item.doctor.name : '-' }}
                        </td>
                        <td class="text-nowrap" v-if="item.origin">
                            {{ item.origin }} - {{ item.type | formatType }}
                        </td>
                        <td class="text-nowrap" v-else>
                            {{ item.type | formatType }}
                        </td>
        
                        <td class="text-center">
                            <div class="badge text-bg-danger" v-if="item.state == 1">
                                <div>
                                    <strong>SIN ATENDER</strong>
                                </div>
                            </div>
                            <div class="badge text-bg-warning" v-else-if="item.state == 2">
                                <div>
                                    <strong>EN PROCESO</strong>
                                </div>
                            </div>
                            <div class="badge text-bg-success" v-else-if="item.state == 3">
                                <div>
                                    <strong>COMPLETADO</strong>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex d-md-block">
                                <button
                                    @click="openEmit(item)" class="btn btn-primary btn-sm rounded-circle bootom me-1">
                                    <i class="fas fa-pills icono-pills"></i>
                                </button>
                                <button 
                                    @click="viewDetail(item)" class="btn btn-primary btn-sm rounded-circle bootom me-1">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button 
                                    @click="viewRecomend(item.indicaciones)" class="btn btn-primary btn-sm rounded-circle bootom" title="Indicaciones Medicas">
                                    <i class="fas fa-notes-medical"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="!cpData.length > 0" class="text-center">
                        <th scope="col" colspan="7">NO SE ENCONTRARON RESULTADOS</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-center" style="align-items: center; margin-top: -10px;">
            <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="anterior-siguiente page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                    </li>
                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                    </li>
                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                    </li>
                    <li v-if="
                  limitedStepPagination[limitedStepPagination.length - 1] <
                  stepPagination.length - 1
                " class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
                    <li v-if="
                  limitedStepPagination[limitedStepPagination.length - 1] !==
                  stepPagination.length
                " class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                    </li>
                    <li class="page-item">
                        <a class="anterior-siguiente page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <cModalViewDetailVue
      :title="modalDeta.title"
      :boo_modal="modalDeta.boo_modal"
      :item="modalDeta.item"
      @mtdCloseModalView="mtdCloseModal"
    />
    <cModalViewRecomendVue
        :title="modalRe.title"
        :boo_modal="modalRe.boo_modal"
        :item="modalRe.item"
        @mtdCloseModalRe="mtdCloseModalRe"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalViewDetailVue from "../Modals/cModalViewDetail.vue";
import cModalViewRecomendVue from "../Modals/cModalViewRecomend.vue";

export default {
    name: "listadoRecetas",
    components: {
        SweetAlert,
        cModalViewDetailVue,
        cModalViewRecomendVue
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            search: "",
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 7,
            modalDeta: {
                title: "Detalles de Receta",
                item: [],
                boo_modal: false,
                pos: null,
            },
            modalRe: {
                title: "Recomendación",
                item: null,
                boo_modal: false,
            },
            selectedItem: [],
            acto: [],
        };
    },
    computed: {
        cpData() {
            const recordsPerPage = 7;
            let filteredData = this.listaRecipe;

            if (this.search.length >= 3) {
                let query = this.search.toString().toUpperCase();
                filteredData = filteredData.filter((element) => {
                    let patientName = element.patient ? element.patient.name.toString().toUpperCase() : "";
                    let patientDocument = element.patient ? element.patient.document.toString() : "";
                    return (
                        patientName.includes(query) || patientDocument.includes(query)
                    );
                });
            }

            let totalPages = Math.ceil(filteredData.length / recordsPerPage);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * recordsPerPage;
            let endIndex = startIndex + recordsPerPage - 1;
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;
            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    props: {
        listaRecipe: {
            type: Array,
            default: () => [],
        },
    },

    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            /** paginación */
            let cantidad = parseInt(this.listaRecipe.length / 20);
            let residuo = parseInt(this.listaRecipe.length % 20);
            cantidad = residuo > 0 ? cantidad + 1 : cantidad;
            for (let index = 0; index < cantidad; index++) {
                this.stepPagination.push(index + 1);
            }
        },

        //Componentes 
        openEmit(item) {
            this.acto = {
                acto_id: item.medical_act_id, 
                patient_id: item.patient_id, 
                patient: item.patient, 
            };
            this.$emit("openRecipeDetail", item, this.acto);
        },

        viewDetail(item) {
            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/viewDetail",
                params: { id: item.id },
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                if (response.data.length > 0) {
                    this.modalDeta.item = response.data;
                    this.modalDeta.boo_modal = true;
                } else {
                    this.modalDeta.boo_modal = false;
                    this.$refs.SweetAlert.showWarning("Sin Detalles de Receta");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdBack: function () {
            this.view = "data";
        },

        mtdCloseModal: function () {
            this.modalDeta = {
                title: "Detalles de Despacho",
                item: [],
                boo_modal: false,
                pos: null,
            };
        },

        viewRecomend(indicaciones) {
            if (indicaciones && indicaciones.trim()) {
                this.modalRe.title = "Recomendación";
                this.modalRe.item = indicaciones.charAt(0).toUpperCase() + indicaciones.slice(1);
                this.modalRe.boo_modal = true;
            } else {
                this.modalRe.boo_modal = false;
                this.$refs.SweetAlert.showWarning("No existe Recomendación");
            }
        },

        mtdCloseModalRe() {
            this.modalRe = {
                title: "",
                item: null,
                boo_modal: false,
            };
        },

        /** pagination */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function(index){
            return (this.selectPage-1)*7 + (index + 1)
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
        formatType(type) {
            const types = {
                1: 'CONSULTAS',
                2: 'INTERCONSULTA',
                3: 'HOSPITALIZACIÓN',
                4: 'UCI ADULT.',
                5: 'UCINEO',
                6: 'SOP Y URPA',
                7: 'EMERGENCIA'
            };
            return types[type] || '-';
        }
    },
};
</script>

<style scoped>
@media (max-width: 536px) {
    .responsive-default {
        flex-direction: column;
    }

    .responsive-default>.py-1 {
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 0px;
    }

    .responsive-default-div {
        border-radius: 10px !important;
        text-align: center !important;
        font-size: 11px;
    }
}
.bootom {
  background-color: #900052 !important; 
  border: 0.5px solid #900052 !important;
}
</style>
