<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0 d-flex">
            <div class="col-lg-4 col-md-6">
                <label for="dni" class="mb-3 mt-2">DNI</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-id-card"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" id="dni" placeholder="12345678" @keydown="filterKeyDni" v-model="data_detail.dni" maxlength="8" />
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <label for="name" class="mb-3 mt-2">Nombre</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" id="name" placeholder="Nombres" v-model="data_detail.name" @keydown="filterKeyLetter" />
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <label for="lastName" class="mb-3 mt-2">Apellidos</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" id="lastName" @keydown="filterKeyLetter" placeholder="Apellidos" v-model="data_detail.last_name" />
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <label for="honorario" class="mb-3 mt-2">Honorarios Res. Individual</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Honorarios Res.Individual" id="honorario" v-model="data_detail.honorario_res_individual" />
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <label for="cpm" class="mb-3 mt-2">CPM</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="CPM" id="cpm" v-model="data_detail.cpm" @input="validateCPM" />
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <label for="rne" class="mb-3 mt-2">RNE</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user-md"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="RNE" id="rne" v-model="data_detail.rne" />
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <label for="ic" class="mb-3 mt-2">Precio IC</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-money-check"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="0.00" id="ic" v-model="data_detail.price_ic" />
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <label for="ic" class="mb-3 mt-2">Precio Consulta</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-money-check"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="0.00" id="consulta" v-model="data_detail.price_consulta" />
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-5" v-if="num === 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="data_detail.state" :checked="data_detail.state == 1 ? true : false" aria-checked="false" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">
                        {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-doctor",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                name: "",
                last_name: "",
                dni: "",
                specialty_id: "",
                sub_specialtie_id: "",
                cpm: "",
                rne: "",
                price_ic: 0,
                price_consulta: 0,
                state: "",
                honorario_res_individual: "",
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
    },
    computed: {
        cpButton() {
            return !(this.data_detail.name != '');

        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail = {
                name: "",
                dni: "",
                last_name: "",
                id: "",
                state: "",
                sub_specialtie_id: "",
                cpm: "",
                rne: "",
                subsub_categorie_id: "",
                honorario_res_individual: "",
            };
            this.$emit("closeModal");
        },
        filterKeyDni: function (e) {
            let b = false;
            b = /^\d$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        filterKeyLetter: function (e) {
            let b = false;
            b = /^[a-zA-ZÀ-ÿ\s]$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        validateCPM() {
            // Remover caracteres no numéricos
            this.data_detail.cpm = this.data_detail.cpm.replace(/[^\d]/g, "");

            // Limitar a 6 caracteres
            if (this.data_detail.cpm.length > 6) {
                this.data_detail.cpm = this.data_detail.cpm.slice(0, 6);
            }
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.mtdClose();
        },
    },

};
</script>
