<template>
<div>
    <div class="row">
        <div class="col-md-12 py-2">
            <div class="row d-flex mx-0">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-2">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-4">Desde</span>
                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-2">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-5">Hasta</span>
                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.enddate" @change="mtdGetData" />
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-10 col-sm-10">
                    <div class="row p-2">
                        <div class="col-12">
                            <div class="row d-flex card-filter">
                                <div class="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineCheckbox11" value="1" v-model="filter.type" @change="mtdGetData" />
                                        <label class="form-check-label" for="inlineCheckbox11">Consultas</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineCheckbox12" value="2" v-model="filter.type" @change="mtdGetData" />
                                        <label class="form-check-label" for="inlineCheckbox12">Operaciones</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineCheckbox12" value="3" v-model="filter.type" @change="mtdGetData" />
                                        <label class="form-check-label" for="inlineCheckbox12">Hospitalizaciones</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineCheckbox12" value="4" v-model="filter.type" @change="mtdGetData" />
                                        <label class="form-check-label" for="inlineCheckbox12">Uci</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineCheckbox12" value="5" v-model="filter.type" @change="mtdGetData" />
                                        <label class="form-check-label" for="inlineCheckbox12">Emergencia</label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-lg-1 col-sm-2 col-xs-2 text-center btn-home mt-2">
                    <button type="button" name="accion" class="btn btn-sm mb-3 w-100" @click="mtdViewPdf()">
                        <i class="fas fa-file-pdf"></i> Exportar
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center px-4">
            <!-- paginado -->
            <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                <nav aria-label="Page navigation example">
                    <ul class="pagination mb-1">
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                        </li>
                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                        </li>
                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>

                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                            </a>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="col-md-12" id="content-timelinetable">
            <table class="table table-bordered border-main">
                <caption class="py-0 my-0"></caption>
                <thead class="bg-main">
                    <tr class=" table-th text-white text-center text-xs">
                        <th>N°</th>
                        <th>CHECKIN</th>
                        <th>PACIENTE</th>
                        <th v-if="filter.type !== '3' && filter.type !== '4' && filter.type !== '5' ">FECHA - HORA</th>
                        <th v-if="filter.type == 3 || filter.type == 4 || filter.type == 5">FECHA - HORA INGRESO</th>
                        <th v-if="filter.type !== '3' && filter.type !== '4' && filter.type !== '5'">CONSULTORIO</th>
                        <th v-if="filter.type == 6">FECHA PROGRAMADA</th>
                        <th v-if="filter.type !== 1">HABITACION</th>
                        <!-- <th>CONVENIO</th> -->
                        <!-- <th>SERVICIO</th> -->
                        <th>ESPECIALIDAD</th>
                        <th v-if="filter.type !== '3' && filter.type !== '4' && filter.type !== '5'">MEDICO</th>
                        <th class="table-th-number">PAGO</th>
                        <th v-if="filter.type == 2" class="table-th-number">ACCIONES</th>
                    </tr>
                </thead>
                <tbody class="border-main">
                    <tr class="table-body text-center" v-if="cpData.length == 0">
                        <td colspan="12" class="text-center">No se encontraron resultados</td>
                    </tr>
                    <tr class="table-body text-center text-xs" v-for="(item, index) in cpData" :key="index">
                        <td>{{ mtdNumber(index) }}</td>
                        <td>
                            <template>
                                <div v-if="item.checkin === null">

                                    <button @click="handleCheckIn(item)" type="button" class="btn btn-sm btn-outline-success">
                                        <i class="fas fa-file-check"></i> CheckIn
                                    </button>

                                     <!-- <button @click="checkInSinAsis(item)" type="button" class="btn btn-sm btn-outline-info">
                                            <i class="fas fa-file-check"></i> CheckIn
                                        </button>  -->
                                </div>
                                <div v-else>
                                    {{ item.checkin }}
                                </div>
                            </template>
                        </td>
                        <td>{{item.document}} - {{ item.patient }}</td>
                        <td>{{ item.date }} {{ item.hour }}</td>
                        <td v-if="filter.type !== '3' && filter.type !== '4' && filter.type !== '5' ">{{ item.room }}</td>
                        <td v-if="filter.type !== 1">{{ item.bed }}</td>
                        <td>{{ item.servicio }}</td>
                        <td v-if="filter.type !== '3' && filter.type !== '4' && filter.type !== '5'">{{ item.doctor }}</td>
                        <td>
                            <div class="text-center">
                                <!-- 0:pagado 1:pagado parcialmente 2:pendiente' -->
                                <span v-if="item.payment == 0" class="badge text-bg-success text-xs">Pagado</span>
                                <span v-if="item.payment == 1" class="badge text-bg-warning text-xs">Pagado parcialmente</span>
                                <span v-if="item.payment == 2" class="badge text-bg-danger text-xs">Debe</span>
                            </div>
                        </td>
                        <td v-if="filter.type == 2">
                            <div class="text-center">
                                <button type="button" title="Insumos y prótesis" class="mt-1 btn btn-secondary btn-sm text-xs">
                                    <i class="fas fa-scalpel"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" @confirm="confirm" @confirmSinAsis="confirmSinAsis" />
        <Filiacion :boo_modal="modal.modal_form" :title="modal.title" @mtdCloseModal="mtdCloseModal" :item="modal.item" />

    </div>
</div>
</template>

    
<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Filiacion from '../modal/cModalDatosAfiliacion.vue';

export default {
    name: "c-admision-pages-reservas",
    components: {
        SweetAlert,
        Filiacion,
    },
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
                type: 1,
                campu: this.$store.getters.get__campus,
            },
            swal: null,
            search: "",
            data: [],
            dataShow: [],
            modal: {
                title: "",
                modal_form: false,
                item: {},
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.getFirstDayOfMonth();

    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        mtdGetData() {
            this.post({
                    url: this.$store.getters.get__url + "/Admision/reservations",
                    token: this.$store.getters.get__token,
                    params: this.filter
                })
                .then((response) => {
                    this.data = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        handleCheckIn(item) {
            this.$refs.SweetAlert.confirmCheckin(item);
        },
        confirm(item) {
            this.post({
                    url: this.$store.getters.get__url + "/Admision/cola/checkin",
                    token: this.$store.getters.get__token,
                    params: {
                        option: this.filter.type,
                        item: item
                    }
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        if (item.acts == 0) {
                            this.showModal(item);
                        } else {
                            this.mtdGetData();
                        }
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        showModal(item) {
            let itemCast = {
                patient : item.patient_obj,
                hora: item.hour,
                reservation_id: item.reservation_id,
            }
            //llamar al formulario
            this.modal = {
                title: "DATOS DE FILIACION",
                modal_form: true,
                item: item ? itemCast : null,
            };
        },
        mtdCloseModal: function (pos, item) {
            this.modal = {
                title: "",
                modal_form: false,
                item: {},
            };
            if (pos == 0) {
                this.mtdGetData();
                this.showPdfAfiliacion(item);
            }

        },
        showPdfAfiliacion: function (item, opt) {
            let type = this.filter.type !== "" ? this.filter.type : opt;
            window.open(
                this.$store.getters.get__url + "/viewpdfAfiliacion/" + item.patient.id + "/" + item.reservation_id + "/" + type,
                "_blank"
            );

        },
        //Sin Asistencial
        checkInSinAsis(item) {
            this.$refs.SweetAlert.confirmCheckinSinAsis(item);
        },
        confirmSinAsis(item) {
            this.post({
                    url: this.$store.getters.get__url + "/Admision/cola/checkinSinAsis",
                    token: this.$store.getters.get__token,
                    params: {
                        option: this.filter.type,
                        item: item
                    }
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        //console.log(item);
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.showModal(item);
                        this.mtdGetData();
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        //Fin Sin Asistencial
        mtdViewPdf: function () {
            let selectedOption =
                this.filter.type == 1 ? 'consultations' :
                this.filter.type == 2 ? 'operations' :
                this.filter.type == 3 ? 'hospitalization' :
                this.filter.type == 4 ? 'uciAdulto' :
                this.filter.type == 5 ? 'emergencia' :
                '';
            if (selectedOption != 0) {
                window.open(
                    this.$store.getters.get__url +
                    "/Admision/viewpdf/reserva/" +
                    selectedOption + "/" + this.filter.begindate + "/" + this.filter.enddate,
                    "_blank"
                );
            }
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.patient.name.toString().toUpperCase();
                    let dni = element.patient.document.toString().toUpperCase();
                    return name.includes(query) || dni.includes(query);
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },
    }
}
</script>
