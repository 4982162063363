<template>
<div>
    <div id="app" class=" mt-3 text-center ">
        <div class="order-md-1 order-lg-2 py-1 py-xl-0 px-0 d-flex justify-content-between">
            <div class="row px-1 mx-1">
                <div class="col-md-10 d-inline-flex w-100 px-0 ms-1" v-if="!validation">
                    <button type="button" class="btn text-white btn-sm bg-main text-xs w-100" @click="saveBalance">
                        Guardar Balance
                    </button>
                </div>
            </div>
            <div class="row d-flex justify-content-end">
                <div class="col-12 px-0">
                    <nav aria-label="Page navigation example" class="pagination-container">
                        <ul class="pagination pagination-xs justify-content-end mb-0">
                            <li class="page-item">
                                <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                            </li>
                            <template>
                                <li class="page-item">
                                    <a class="page-link active" href="javascript:void(0)">{{ water_balance.date_name }}</a>
                                </li>
                            </template>
                            <li class="page-item">
                                <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-2 d-flex responsive-container mb-3">
            <div v-if="this.type_patient == 'particular'" class="">
                <div class="row mx-0 px-0 mb-2 text-start ">
                    <div class="px-0">
                        <span class="color-main fw-bold ">PACIENTE: </span><span >{{ this.patient_name }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA INGRESO: </span><span >{{ formatDate(this.date_in) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA SALIDA: </span><span >{{ formatDate(this.date_out) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">PESO: </span><span >{{ peso }}<span v-if="peso !='-'">KG</span></span>
                    </div>
                </div>
            </div>
            <div v-if="this.type_patient == 'eps'" class="">
                <div class="row mx-0 px-0 mb-2 text-start ">
                    <div class="px-0">
                        <span class="color-main fw-bold ">PACIENTE: </span><span >{{ this.patient_name }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">CONVENIO: </span><span >{{ this.insurance_name }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA INGRESO: </span><span >{{ formatDate(this.date_in) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA SALIDA: </span><span >{{ formatDate(this.date_out) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">PESO: </span><span >{{ peso }} <span v-if="peso !='-'">KG</span></span>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                <!-- Ingresos -->
                <div class="row mx-0 mb-3 px-0">
                    <table class="w-100">
                        <caption class="div-text my-0 py-0"></caption>
                        <thead class="txt-xs">
                            <tr>
                                <th class="text-start text-xs pb-2" scope="col" colspan="5">Ingresos:</th>
                            </tr>
                        </thead>
                        <tbody class="div-text">
                            <tr class="px-1">
                                <td>
                                    <select v-model="ingreso.name" class="form-control form-control-input form-control-sm" :disabled="validation" v-if="ingreso.name != 'otros'">
                                        <option value="" disabled selected>Seleccione Ingreso</option>
                                        <option value="dieta">Dieta</option>
                                        <option value="tratamiento">Tratamiento Endocrino</option>
                                        <option value="via_oral">Vía Oral</option>
                                        <option value="sonda_nasogastrica">Por sonda Nasogástrica</option>
                                        <option value="tratamiento_entera">Tratamiento para Enterar</option>
                                        <option value="transfusiones">Transfusiones</option>
                                        <option value="agua_metabolica">Agua Metabólica</option>
                                        <option value="otros">Otros</option>
                                    </select>
                                    <input v-if="ingreso.name === 'otros'" type="text" v-model="ingreso.otherText" placeholder="Ingresa Nombre" class="form-control form-control-input form-control-sm" :disabled="validation"/>
                                </td>
                                <td><input v-on:keypress="isNumber($event)" type="text" v-model="ingreso.mañana" placeholder="Turno Mañana" class="form-control form-control-input form-control-sm" :disabled="validation"/></td>
                                <td><input v-on:keypress="isNumber($event)" type="text" v-model="ingreso.tarde" placeholder="Turno Tarde" class="form-control form-control-input form-control-sm" :disabled="validation"/></td>
                                <td><input v-on:keypress="isNumber($event)" type="text" v-model="ingreso.noche" placeholder="Turno Noche" class="form-control form-control-input form-control-sm" :disabled="validation"/></td>
                                <td><button type="button" @click="mtdSaveBalance(1)" :disabled="incompleteIngreso" class="btn btn-success btn-sm px-1 py-0"><i class="fas fa-plus text-white"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row div-text mx-0 px-0 mb-2">
                    <table class="table table-bordered border-main mb-0 px-0 text-xs">
                        <caption class="my-0 py-0"></caption>
                        <thead class="txt-xs">
                            <tr class="text-white border-white text-center bg-main">
                                <th scope="col" class="ingreso-egreso">N°</th>
                                <th scope="col" class="ingreso-egreso">Nombre</th>
                                <th scope="col" class="ingreso-egreso">7:30 am. - 1:30 pm.</th>
                                <th scope="col" class="ingreso-egreso">1:30 pm. - 7:30 pm.</th>
                                <th scope="col" class="ingreso-egreso">Parcial</th>
                                <th scope="col" class="ingreso-egreso">7:30 pm. - 7:30 am.</th>
                                <th scope="col" class="ingreso-egreso">Total</th>
                                <th scope="col" class="ingreso-egreso">Acción</th>
                            </tr>
                        </thead>
                        <tbody class="div-text" v-if=" water_balance.dataIngreso.length > 0">
                            <tr v-for="(item, index) in  water_balance.dataIngreso" :key="index" class="px-1 border border-main align">
                                <td><span>{{ index + 1 }}</span></td>
                                <td><span>{{ nameTableIngreso(item.name, item.otherText) }}</span></td>
                                <td><span>{{ parseFloat(item.mañana).toFixed(2) }}</span></td>
                                <td><span>{{ parseFloat(item.tarde).toFixed(2) }}</span></td>
                                <td><span>{{ getParcial(item) }}</span></td>
                                <td><span>{{ parseFloat(item.noche).toFixed(2) }}</span></td>
                                <td><span>{{ getTotal(item) }}</span></td>
                                <td><button type="button" @click="mtdDeleteBalance(item.id, 1)" class="btn btn-danger btn-sm px-1 py-0" :disabled="validation"><i class="fas fa-trash-alt text-white"></i></button></td>
                            </tr>
                        </tbody>
                        <tbody class="text-center" v-else>
                            <tr>
                                <td colspan="8" class="text-center"><strong>No hay Ingresos</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                <!-- Egresos -->
                <div class="row mx-0 mb-3 px-0">
                    <table class="w-100">
                        <caption class="div-text my-0 py-0"></caption>
                        <thead class="txt-xs">
                            <tr>
                                <th class="text-start text-xs pb-2" scope="col" colspan="5">Egresos:</th>
                            </tr>
                        </thead>
                        <tbody class="div-text">
                            <tr class="px-1">
                                <td>
                                    <select v-model="egreso.name" class="form-control form-control-input form-control-sm" :disabled="validation" v-if="egreso.name != 'otros'">
                                        <option value="" disabled selected>Seleccione Egreso</option>
                                        <option value="diuresis">Diuresis</option>
                                        <option value="deposiciones">Deposiciones</option>
                                        <option value="vomitos">Vómitos</option>
                                        <option value="drenes">Drenes</option>
                                        <option value="perdidas">Pérdidas Insensibles</option>
                                        <option value="otros">Otros</option>
                                    </select>
                                    <input v-if="egreso.name === 'otros'" type="text" v-model="egreso.otherText" placeholder="Ingresa Nombre" class="form-control form-control-input form-control-sm" :disabled="validation"/>
                                </td>
                                <td><input v-on:keypress="isNumber($event)" type="text" v-model="egreso.mañana" placeholder="Turno Mañana" class="form-control form-control-input form-control-sm" :disabled="validation"/></td>
                                <td><input v-on:keypress="isNumber($event)" type="text" v-model="egreso.tarde" placeholder="Turno Tarde" class="form-control form-control-input form-control-sm" :disabled="validation"/></td>
                                <td><input v-on:keypress="isNumber($event)" type="text" v-model="egreso.noche" placeholder="Turno Noche" class="form-control form-control-input form-control-sm" :disabled="validation"/></td>
                                <td><button type="button" @click="mtdSaveBalance(2)" :disabled="incompleteEgreso" class="btn btn-success btn-sm px-1 py-0"><i class="fas fa-plus text-white"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row div-text mx-0 px-0 mb-2">
                    <table class="table table-bordered border-main mb-0 px-0 text-xs">
                        <caption class="my-0 py-0"></caption>
                        <thead class="txt-xs">
                            <tr class="text-white border-white text-center bg-main">
                                <th scope="col" class="ingreso-egreso">N°</th>
                                <th scope="col" class="ingreso-egreso">Nombre</th>
                                <th scope="col" class="ingreso-egreso">7:30 am. - 1:30 pm.</th>
                                <th scope="col" class="ingreso-egreso">1:30 pm. - 7:30 pm.</th>
                                <th scope="col" class="ingreso-egreso">Parcial</th>
                                <th scope="col" class="ingreso-egreso">7:30 pm. - 7:30 am.</th>
                                <th scope="col" class="ingreso-egreso">Total</th>
                                <th scope="col" class="ingreso-egreso">Acción</th>
                            </tr>
                        </thead>
                        <tbody class="div-text" v-if="water_balance.dataEgreso.length > 0">
                            <tr v-for="(item, index) in water_balance.dataEgreso" :key="index" class="px-1 border border-main align">
                                <td><span>{{ index + 1 }}</span></td>
                                <td><span>{{ nameTableEgreso(item.name, item.otherText) }}</span></td>
                                <td><span>{{ parseFloat(item.mañana).toFixed(2) }}</span></td>
                                <td><span>{{ parseFloat(item.tarde).toFixed(2) }}</span></td>
                                <td><span>{{ getParcial(item) }}</span></td>
                                <td><span>{{ parseFloat(item.noche).toFixed(2) }}</span></td>
                                <td><span>{{ getTotal(item) }}</span></td>
                                <td><button type="button" @click="mtdDeleteBalance(item.id, 2)" class="btn btn-danger btn-sm px-1 py-0" :disabled="validation"><i class="fas fa-trash-alt text-white"></i></button></td>
                            </tr>
                        </tbody>
                        <tbody class="text-center" v-else>
                            <tr>
                                <td colspan="8" class="text-center"><strong>No hay Egresos</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-asistencial-hospitalizacion-historia-balance',
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            act_id: 0,
            ingreso: {
                id: null,
                otherText: '',
                name: '',
                mañana: '',
                tarde: '',
                noche: '',
            },
            dataIngreso: {
                id: null,
                name: '',
                otherText: '',
                mañana: '',
                tarde: '',
                noche: '',
            },
            egreso: {
                id: null,
                name: '',
                otherText: '',
                mañana: '',
                tarde: '',
                noche: '',
            },
            dataEgreso: {
                id: null,
                name: '',
                otherText: '',
                mañana: '',
                tarde: '',
                noche: '',
            },
            patient_name: '',
            insurance_id: '',
            insurance_name: '',
            type_patient: null,
            peso: '',
            pos: null,
            validation: false,
            
            balance: [],
            water_balance: {
                date_name: 'BH ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                dataIngreso: [
                ],
                dataEgreso: [
                ],
            },
        }
    },

    created() {},
    props: {
        medical_act_id: {
            type: Number,
            default: 0
        },
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0) {
                    this.mtdGetBalance();
                    this.mtdtypepatient();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        incompleteIngreso() {
            return (
                !this.ingreso.name.trim() ||
                !this.ingreso.mañana.trim() ||
                !this.ingreso.tarde.trim() ||
                !this.ingreso.noche.trim()
            );
        },
        incompleteEgreso() {
            return (
                !this.egreso.name.trim() ||
                !this.egreso.mañana.trim() ||
                !this.egreso.tarde.trim() ||
                !this.egreso.noche.trim()
            );
        },
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdtypepatient: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Uciadulto/salePatient",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id
                    },
                })
                .then((response) => { //   
                    this.sale = response.data,
                        this.type_patient = response.data.type_patient,
                        this.date_in = response.data.date_ind,
                        this.date_out = response.data.date_out,
                        this.insurance_id = response.data.insurance ? response.data.insurance.id : null,
                        this.insurance_name = response.data.insurance ? response.data.insurance.name  : ''
                        this.patient_name = response.data.patient.name
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        getParcial(item) {
            const mañana = parseFloat(item.mañana) || 0;
            const tarde = parseFloat(item.tarde) || 0;
            return (mañana + tarde).toFixed(2);
        },

        getTotal(item) {
            const parcial = parseFloat(this.getParcial(item)) || 0;     
            const noche = parseFloat(item.noche) || 0;
            return (parcial + noche).toFixed(2);
        },

        mtdSaveBalance: function (type) {
            if (type == 1) {
                const exists = this.water_balance.dataIngreso.some(item => item.name.trim() === this.ingreso.name.trim());
                const i = this.nameTableIngreso(this.ingreso.name.trim(), this.ingreso.otherText);
                if (exists) {
                    this.$refs.SweetAlert.showWarning(`Ingreso "${i}" ya Agregado`);
                } else {
                    this.ingreso.id = this.water_balance.dataIngreso.length + 1;
                    this.water_balance.dataIngreso.push(this.ingreso);
                }
                this.ingreso = {
                    name: '',
                    otherText: '',
                    mañana: '',
                    tarde: '',
                    noche: '',
                }
            } else if (type == 2) {
                const exists = this.water_balance.dataEgreso.some(item => item.name.trim() === this.egreso.name.trim());
                const e = this.nameTableEgreso(this.egreso.name.trim(), this.egreso.otherText);
                if (exists) {
                    this.$refs.SweetAlert.showWarning(`Egreso "${e}" ya Agregado`);
                } else {
                    const newId = this.water_balance.dataEgreso.length + 1;
                    const newEgreso = {
                        id: newId,
                        ...this.egreso
                    };
                    this.water_balance.dataEgreso.push(newEgreso);
                }
                this.egreso = {
                    name: '',
                    otherText: '',
                    mañana: '',
                    tarde: '',
                    noche: '',
                }
            }
        },

        saveBalance: function(){
            if (!this.water_balance.dataIngreso.length && !this.water_balance.dataEgreso.length) {
                this.$refs.SweetAlert.showWarning("Llena al menos un Ingreso o Egreso");
                return;
            }
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro de Guardar estos Balances?", "warning", "Guardar")
            .then((result) => {
                if (result.value) {
                    /*
                        if (this.water_balance.dataIngreso.length == 0) {
                            this.water_balance.dataIngreso.push(this.dataIngreso);
                        }
                        if (this.water_balance.dataEgreso.length == 0) {
                            this.water_balance.dataEgreso.push(this.dataEgreso);
                        }
                    */
                    this.water_balance.medical_act_id = this.medical_act_id;
                    this.post({
                        url: this.$store.getters.get__url + "/Uciadulto/balance",
                        token: this.$store.getters.get__token,
                        params: this.water_balance,
                    })
                    .then((response) => {
                        if(response.data.state == 0){
                            this.$refs.SweetAlert.showSuccess(response.message);
                            this.water_balance.dataIngreso = [];
                            this.water_balance.dataEgreso = [];
                            this.mtdGetBalance();
                        }else if(response.data.state == 1){
                            this.$refs.SweetAlert.showWarning(response.message);
                            this.water_balance.dataIngreso = [];
                            this.water_balance.dataEgreso = [];
                        }
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
                }
            });
        },

        mtdDeleteBalance: function (id, type) {
            if (type == 1) {
                this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro eliminar este Balance de Ingreso?", "warning", "Eliminar")
                    .then((result) => {
                        if (result.value) {
                            const indice = this.water_balance.dataIngreso.findIndex(item => item.id === id);
                            if (indice !== -1) {
                                this.water_balance.dataIngreso.splice(indice, 1);
                            }
                        }
                    });
            } else if (type == 2) {
                this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro eliminar este Balance de Egreso?", "warning", "Eliminar")
                    .then((result) => {
                        if (result.value) {
                            const indice = this.water_balance.dataEgreso.findIndex(item => item.id === id);
                            if (indice !== -1) {
                                this.water_balance.dataEgreso.splice(indice, 1);
                            }
                        }
                    });
            }
        },

        mtdDelete: function (id, type) {
            this.get({
                    url: this.$store.getters.get__url + "/Uciadulto/deleteBalance/" + id,
                    token: this.$store.getters.get__token,
                }).then((response) => {
                    this.$refs.SweetAlert.showSuccess("Eliminado Correctamente");
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        mtdGetBalance: function () {
                this.get({
                    url: this.$store.getters.get__url + "/Uciadulto/mtdGetBalance/" + this.medical_act_id,
                    token: this.$store.getters.get__token,
                }).then((response) => {
                    if (response.statusCode === 200) {
                        this.balance=[]

                        response.data.balance.forEach(item => {
                            let balancedetails = {
                                date_name: item.datename,
                                id: item.id,
                                medical_act_id: item.medical_act_id,
                                date: item.date,
                                hour: item.hour,
                                dataIngreso: JSON.parse(item.dataIngreso), 
                                dataEgreso:JSON.parse(item.dataEgreso) 
                            }
                            this.balance.push(balancedetails);
                            

                        })
                        this.balance.push(this.water_balance);
                        this.pos = this.balance.length - 1
                        this.peso = response.data.peso
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        nameTableIngreso(name, otherText) {
            switch (name) {
            case 'dieta':
                return "Dieta";
            case 'tratamiento':
                return "Tratamiento Endocrino";
            case 'via_oral':
                return "Via Oral";
            case 'sonda_nasogastrica':
                return "Por sonda Nasogástrica";
            case 'tratamiento_entera':
                return "Tratamiento para Enterar";
            case 'transfusiones':
                return "Transfusiones";
            case 'agua_metabolica':
                return "Agua Metabólica";
            case 'otros':
                return otherText;
            default:
                return "-";
            }
        },

        nameTableEgreso(name, otherText) {
            switch (name) {
            case 'diuresis':
                return "Diuresis";
            case 'deposiciones':
                return "Deposiciones";
            case 'vomitos':
                return "Vómitos";
            case 'drenes':
                return "Drenes";
            case 'perdidas':
                return "Pérdidas Insensibles";
            case 'otros':
                return otherText;
            default:
                return "-";
            }
        },

        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },

        //Paginacion Balance
        nextPage: function () {
            this.pos = (this.pos == this.balance.length - 1) ? this.pos : this.pos + 1
            this.water_balance = this.balance[this.pos]
            this.validation = this.water_balance.date_name != 'BH ACTUAL'
        },

        backPage: function () {
            this.pos = (this.pos == 0) ? this.pos : this.pos - 1
            this.water_balance = this.balance[this.pos]
            this.validation = this.water_balance.date_name != 'BH ACTUAL'
        },
        
    }
}
</script>

<style>
.ingreso-egreso {
    white-space: nowrap !important;
    vertical-align: middle !important;
}

.align {
    vertical-align: middle !important;
}
</style>
