<template>
<div>
    <div class="">
        <div class="row mx-0 px-0">
            <div class="col-md-12 px-0 py-3 d-flex justify-content-between " style="color: #900052; text-align: center; position: relative;">
                <div class="d-flex">
                    <button v-if="!dete_out" type="button" @click="OpenModal(2)" class="btn btn-info btn-sm text-white"> <i class="fas fa-eye"></i> Ver Insumos </button>
                    <button v-if="!dete_out" type="button" @click="OpenModal(3)" class="btn btn-success btn-sm text-white ms-1"> <i class="fas fa-eye"></i> Ver Soluciones </button>
                </div>
                <div class="superior-derecha">
                    <span class="libre mt-2"></span> Aplicado &nbsp;
                    <span class="reservado mt-2"></span> No aplicado &nbsp;
                    <span class="ocupado mt-2"></span> Sin aplicar
                </div>
            </div>
        </div>

        <div class="table-responsive-edit">
            <table class="table table-bordered text-xxs mb-0">
                <thead class="bg-main text-white text-center">
                    <tr id="cabecera-fija">
                        <th class="align-middle" scope="col" rowspan="2">N°</th>
                        <th class="align-middle" scope="col" rowspan="2">INDICACIONES MÉDICAS</th>
                        <th class="align-middle" scope="col" rowspan="2">CONCENTRACIÓN</th>
                        <th class="align-middle" scope="col" rowspan="2">VIA</th>
                        <th class="align-middle" scope="col" rowspan="2">HORA</th>
                        <th class="align-middle" scope="col" rowspan="2">INDICACIONES</th>
                        <th v-for="(item, index) in datatime" :key="index" scope="col" colspan="3">{{ item }}</th>
                    </tr>
                    <tr id="cabecera">
                        <template v-for="n in datatime.length">
                            <th>M</th>
                            <th>T</th>
                            <th>N</th>
                        </template>
                    </tr>
                </thead>
                <tbody v-if="recipes.length > 0">
                    <template v-for="(item1) in recipes">
                        <tr>
                            <td>{{item1.medicamentos[0].count  }}</td>
                            <td>
                                <span v-if="item1.medicamentos[0].group !=0 " class=" px-2 rounded bg-info"> {{ 'Grupo '+ item1.medicamentos[0].group  }}</span> {{ item1.medicamentos[0].name }}
                            </td>
                            <td>{{ item1.medicamentos[0].dosis  }}</td>
                            <td>{{ item1.medicamentos[0].via }}</td>
                            
                            <td style="width: 120px;">{{ item1.medicamentos[0].hora }}
                                <button @click="mtdaddhorario(item1.medicamentos[0])" v-if="item1.medicamentos[0].prn && !dete_out  && !item1.medicamentos[0].cancelation" type="button" class="btn btn-warning ms-4 py-0 px-1"><i class="fa fa-clock"></i></button>
                                <button @click="mtdaddhorario(item1.medicamentos[0])" v-if="!item1.medicamentos[0].prn && !item1.medicamentos[0].start  && !dete_out  && !item1.medicamentos[0].cancelation" type="button" class="btn btn-info ms-4 py-0 px-1"><i class="fa fa-clock"></i></button>
                                <span>
                                    <a @click="mtdinsule(item1.medicamentos[0].insuline)" v-if="item1.medicamentos[0].insuline" type="button" :class="item1.medicamentos[0].start && !item1.medicamentos[0].prn ? 'ms-4': 'ms-2'" class="btn btn-success  py-0 px-1"><i class="fas fa-solid fa-eye"></i></a>
                                </span>
                            </td>
                            <td  class=" text-start h-100" :rowspan="item1.medicamentos.length" v-html="item1.medicamentos[0].indicaciones.replace(/\n/g, '<br>')">
                            </td>
                            <template v-for="(horario) in item1.medicamentos[0].horario">
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <div v-for="(mañana, index) in horario.mañana" :key="index" style="margin: 0px 1px 0px 1px;">
                                            <button :class="mañana.status == 0 ? 'btn-danger' : mañana.status == 1 ? 'btn-success' : mañana.status == 3 ? 'btn-secondary': mañana.status == 2 && mañana.comment ? 'btn-success': 'btn-warning'" class="btn btn-sm px-1 py-1 text-xxxs" @click="mtdChange(mañana)" :disabled="mañana.status == 3 || (dete_out && mañana.status == 0) || (dete_out &&  mañana.status == 2 && !mañana.comment)">
                                                <span> <strong class="fw-bold text-xs text-warning"> {{mañana.aplicacion}} </strong>{{ formatTime(mañana.time_kardex)  }} </span>
                                            </button>
                                            <div style="font-size: 7px;">F.R:{{ formatTime(mañana.time_register) }}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <div v-for="(tarde, index) in horario.tarde" :key="index" style="margin: 0px 1px 0px 1px;">
                                            <button :class="tarde.status == 0 ? 'btn-danger' : tarde.status == 1 ? 'btn-success' : tarde.status == 3 ? 'btn-secondary': tarde.status == 2 && tarde.comment ? 'btn-success': 'btn-warning'" class="btn btn-sm  px-1 py-1 text-xxxs" @click="mtdChange(tarde)" :disabled="tarde.status == 3 || (dete_out && tarde.status == 0) || (dete_out &&  tarde.status == 2 && !tarde.comment)">
                                                <span> <strong class="fw-bold text-xs text-warning"> {{tarde.aplicacion}} </strong>{{ formatTime(tarde.time_kardex)}} </span>
                                            </button>
                                            <div style="font-size: 7px;">F.R:{{formatTime(tarde.time_register)}}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <div v-for="(noche, index) in horario.noche" :key="index" style="margin: 0px 1px 0px 1px;">
                                            <button :class="noche.status == 0 ? 'btn-danger' : noche.status == 1 ? 'btn-success' : noche.status == 3 ? 'btn-secondary': noche.status == 2 && noche.comment ? 'btn-success': 'btn-warning'" class="btn btn-sm  px-1 py-1 text-xxxs" @click="mtdChange(noche)" :disabled="noche.status == 3 || (dete_out && noche.status == 0) || (dete_out &&  noche.status == 2 && !noche.comment)">
                                                <span> <strong class="fw-bold text-xs text-warning"> {{noche.aplicacion}} </strong>{{formatTime(noche.time_kardex) }} </span>
                                            </button>
                                            <div style="font-size: 7px;">F.R:{{ formatTime(noche.time_register) }}</div>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </tr>
                        <template v-if="item1.medicamentos.length > 1">
                            <tr v-for="(medicine, index) in item1.medicamentos.slice(1)" >
                                    <td>{{ medicine.count  }}</td>
                                    <td>
                                        <span v-if="medicine.group !=0 " class=" px-2 rounded bg-info"> {{ 'Grupo '+ medicine.group  }}</span> {{ medicine.name }}

                                    </td>
                                    <td>{{ medicine.dosis  }}</td>
                                    <td>{{ medicine.via }}</td>
                                    <td style="width: 120px;">{{ medicine.hora }}
                                        <button @click="mtdaddhorario(medicine)" v-if="medicine.prn && !dete_out  && !medicine.cancelation" type="button" class="btn btn-warning ms-4 py-0 px-1"><i class="fa fa-clock"></i></button>
                                        <button @click="mtdaddhorario(medicine)" v-if="!medicine.prn && !medicine.start  && !dete_out  && !medicine.cancelation" type="button" class="btn btn-info ms-4 py-0 px-1"><i class="fa fa-clock"></i></button>
                                        <span>
                                            <a @click="mtdinsule(medicine.insuline)" v-if="medicine.insuline" type="button" :class="medicine.start && !medicine.prn ? 'ms-4': 'ms-2'" class="btn btn-success  py-0 px-1"><i class="fas fa-solid fa-eye"></i></a>
                                        </span>
                                    </td>
                                    <template v-for="(horario) in medicine.horario">
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div v-for="(mañana, index) in horario.mañana" :key="index" style="margin: 0px 1px 0px 1px;">
                                                    <button :class="mañana.status == 0 ? 'btn-danger' : mañana.status == 1 ? 'btn-success' : mañana.status == 3 ? 'btn-secondary': mañana.status == 2 && mañana.comment ? 'btn-success': 'btn-warning'" class="btn btn-sm px-1 py-1 text-xxxs" @click="mtdChange(mañana)" :disabled="mañana.status == 3 || (dete_out && mañana.status == 0) || (dete_out &&  mañana.status == 2 && !mañana.comment)">
                                                        <span> <strong class="fw-bold text-xs text-warning"> {{mañana.aplicacion}} </strong>{{ formatTime(mañana.time_kardex)  }} </span>
                                                    </button>
                                                    <div style="font-size: 7px;">F.R:{{ formatTime(mañana.time_register) }}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div v-for="(tarde, index) in horario.tarde" :key="index" style="margin: 0px 1px 0px 1px;">
                                                    <button :class="tarde.status == 0 ? 'btn-danger' : tarde.status == 1 ? 'btn-success' : tarde.status == 3 ? 'btn-secondary': tarde.status == 2 && tarde.comment ? 'btn-success': 'btn-warning'" class="btn btn-sm  px-1 py-1 text-xxxs" @click="mtdChange(tarde)" :disabled="tarde.status == 3 || (dete_out && tarde.status == 0) || (dete_out &&  tarde.status == 2 && !tarde.comment)">
                                                        <span> <strong class="fw-bold text-xs text-warning"> {{tarde.aplicacion}} </strong>{{ formatTime(tarde.time_kardex)}} </span>
                                                    </button>
                                                    <div style="font-size: 7px;">F.R:{{formatTime(tarde.time_register)}}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div v-for="(noche, index) in horario.noche" :key="index" style="margin: 0px 1px 0px 1px;">
                                                    <button :class="noche.status == 0 ? 'btn-danger' : noche.status == 1 ? 'btn-success' : noche.status == 3 ? 'btn-secondary': noche.status == 2 && noche.comment ? 'btn-success': 'btn-warning'" class="btn btn-sm  px-1 py-1 text-xxxs" @click="mtdChange(noche)" :disabled="noche.status == 3 || (dete_out && noche.status == 0) || (dete_out &&  noche.status == 2 && !noche.comment)">
                                                        <span> <strong class="fw-bold text-xs text-warning"> {{noche.aplicacion}} </strong>{{formatTime(noche.time_kardex) }} </span>
                                                    </button>
                                                    <div style="font-size: 7px;">F.R:{{ formatTime(noche.time_register) }}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </template>
                                </tr> 
                        
                        </template>

                    </template>

                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="6" class="text-center">
                            <span class="fw-bold"> No hay medicamentos </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive-edit mt-3">
            <table class="table-2 table-bordered text-xxs mb-0">
                <thead class="bg-main text-white text-center">
                    <tr id="cabecera-fija">
                        <th class="align-middle" colspan="5" scope="col" rowspan="2"></th>
                        <th v-for="(item, index) in datatime2" :key="index" scope="col" colspan="3">{{ item }}</th>
                    </tr>
                    <tr id="cabecera">
                        <template v-for="n in datatime2.length">
                            <th>M</th>
                            <th>T</th>
                            <th>N</th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="5" class="fw-bold">CURACIONES </td>
                        <template v-for="(history) in historys">
                            <td class="text-xxxs text-center ">
                                <div class="d-flex justify-content-center">
                                    <span v-for="(mañana, index) in history.mañana " :key="index">
                                        <span :class=" mañana.result_date ? 'bg-success': 'bg-secondary ' " class="py-2 px-2 rounded rounded-circle " style="font-size: 6px; margin: 0px 1px 0px 1px;">
                                            <i class="fas fa-check text-white" style="font-size: 8px;"></i>
                                        </span>
                                        <div class="mt-2 fw-bold" style="font-size: 7px;">{{ mañana.hour}}</div>
                                    </span>
                                </div>
                            </td>
                            <td class="text-xxxs text-center">
                                <div class="d-flex justify-content-center">
                                    <span v-for="(tarde, index) in history.tarde " :key="index">
                                        <span :class=" tarde.result_date ? 'bg-success': 'bg-secondary ' " class="py-2 px-2 rounded rounded-circle " style="font-size: 6px ; margin: 0px 1px 0px 1px;">
                                            <i class="fas fa-check text-white" style="font-size: 8px;"></i>
                                        </span>
                                        <div class="mt-2 fw-bold" style="font-size: 7px;">{{ tarde.hour}}</div>
                                    </span>
                                </div>
                            </td>
                            <td class="text-xxxs text-center ">
                                <div class="d-flex justify-content-center">
                                    <span v-for="(noche, index) in history.noche " :key="index" style=" margin: 0px 1px 0px 1px;">
                                        <span :class=" noche.result_date ? 'bg-success': 'bg-secondary ' " class="py-2 px-2 rounded rounded-circle " style="font-size: 6px;">
                                            <i class="fas fa-check text-white" style="font-size: 8px;"></i>
                                        </span>
                                        <div class="mt-2 fw-bold" style="font-size: 7px;">{{ noche.hour}}</div>
                                    </span>
                                </div>
                            </td>
                        </template>

                    </tr>
                    <tr>
                        <td colspan="5" class="fw-bold">ESCARAS </td>
                        <template v-for="(note) in notes">
                            <td class="text-xxxs text-center">
                                <span v-if="note.mañana " @click="mtdviewdetalle(note.mañana)" class="py-2 px-2 rounded rounded-circle bg-success " style="font-size: 6px;">
                                    <i class="fas fa-check text-white" style="font-size: 8px;"></i>
                                </span>
                            </td>
                            <td class="text-xxxs text-center">
                                <span v-if="note.tarde " @click="mtdviewdetalle(note.tarde)" class="py-2 px-2 rounded rounded-circle bg-success " style="font-size: 6px;">
                                    <i class="fas fa-check text-white" style="font-size: 8px;"></i>
                                </span>
                            </td>
                            <td class="text-xxxs text-center">
                                <span v-if="note.noche " @click="mtdviewdetalle(note.noche)" class="py-2 px-2 rounded rounded-circle  bg-success" style="font-size: 6px;">
                                    <i class="fas fa-check text-white" style="font-size: 8px;"></i>
                                </span>
                            </td>
                        </template>

                    </tr>
                    <tr>
                        <td rowspan='2' colspan="2" class="fw-bold">CONTROL DE GLICEMIAS </td>
                        <td colspan="3" class="fw-bold column-second1">VALOR</td>
                        <template v-for="(kardex) in nursing_kardexes_1">
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.mañana)">
                                    <span v-for="(mañana, index) in kardex.mañana" :key="index">
                                        <li>
                                            <span>{{ mañana.description }}</span>
                                            <span @click="mtdInputKardexView(mañana)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </span>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="mtdInputKardex(kardex.date, 'glicemia_valor')" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.tarde)">
                                    <span v-for="(tarde, index) in kardex.tarde" :key="index">
                                        <li>
                                            <span>{{ tarde.description }}</span>
                                            <button type="button" @click="mtdInputKardexView(tarde)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </button>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="mtdInputKardex(kardex.date, 'glicemia_valor')" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.noche)">
                                    <span v-for="(noche, index) in kardex.noche" :key="index">
                                        <li>
                                            <span>{{ noche.description }}</span>
                                            <button type="button" @click="mtdInputKardexView(noche)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </button>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="mtdInputKardex(kardex.date, 'glicemia_valor')" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td colspan="3" class="fw-bold column-second2">C/ H</td>
                        <template v-for="(kardex) in nursing_kardexes_2">
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.mañana)">
                                    <span v-for="(mañana, index) in kardex.mañana" :key="index">
                                        <li>
                                            <span>{{ mañana.description }}</span>
                                            <span @click="mtdInputKardexView(mañana)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </span>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="mtdInputKardex(kardex.date, 'glicemia_ch')" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.tarde)">
                                    <span v-for="(tarde, index) in kardex.tarde" :key="index">
                                        <li>
                                            <span>{{ tarde.description }}</span>
                                            <button type="button" @click="mtdInputKardexView(tarde)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </button>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="mtdInputKardex(kardex.date, 'glicemia_ch')" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.noche)">
                                    <span v-for="(noche, index) in kardex.noche" :key="index">
                                        <li>
                                            <span>{{ noche.description }}</span>
                                            <button type="button" @click="mtdInputKardexView(noche)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </button>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="mtdInputKardex(kardex.date, 'glicemia_ch')" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td colspan="5" class="fw-bold">OXIGENOTERAPIA</td>
                        <template v-for="(kardex) in nursing_kardexes_6">
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.mañana)">
                                    <span v-for="(mañana, index) in kardex.mañana" :key="index">
                                        <li>
                                            <span>FIO2: {{ mañana.fio2 }} | LITROS: {{ mañana.liter }}</span>
                                            <span @click="mtdInputOxigenoView(mañana)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </span>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="OpenModalOxigeno" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.tarde)">
                                    <span v-for="(tarde, index) in kardex.tarde" :key="index">
                                        <li>
                                            <span>FIO2: {{ tarde.fio2 }} | LITROS: {{ tarde.liter }}</span>
                                            <button type="button" @click="mtdInputOxigenoView(tarde)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </button>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="OpenModalOxigeno" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.noche)">
                                    <span v-for="(noche, index) in kardex.noche" :key="index">
                                        <li>
                                            <span>FIO2: {{ noche.fio2 }} | LITROS: {{ noche.liter }}</span>
                                            <button type="button" @click="mtdInputOxigenoView(noche)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </button>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="OpenModalOxigeno" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td colspan="5" class="fw-bold">TERAPIA FISICA Y RESPIRATORIA</td>
                        <template v-for="(kardex) in nursing_kardexes_5">
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.mañana)">
                                    <span v-for="(mañana, index) in kardex.mañana" :key="index">
                                        <li>
                                            <span>{{ mañana.description }}</span>
                                            <span @click="mtdInputKardexView(mañana)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </span>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="mtdInputKardex(kardex.date, 'terapia')" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.tarde)">
                                    <span v-for="(tarde, index) in kardex.tarde" :key="index">
                                        <li>
                                            <span>{{ tarde.description }}</span>
                                            <button type="button" @click="mtdInputKardexView(tarde)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </button>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="mtdInputKardex(kardex.date, 'terapia')" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                            <td class="text-xxxs text-center cell-with-icon">
                                <span v-if="hasElements(kardex.noche)">
                                    <span v-for="(noche, index) in kardex.noche" :key="index">
                                        <li>
                                            <span>{{ noche.description }}</span>
                                            <button type="button" @click="mtdInputKardexView(noche)" class="icon-circle">
                                                <i class="fas fa-info"></i>
                                            </button>
                                        </li>
                                    </span>
                                </span>
                                <span v-else>
                                    <button :disabled="dete_out" type="button" @click="mtdInputKardex(kardex.date, 'terapia')" class="btn btn-secondary btn-sm" style="padding-top: .65rem; padding-bottom: .65rem; padding-left: .8rem; padding-right: .8rem;"></button>
                                </span>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td colspan="5" class="fw-bold">DIETA</td>
                        <!-- <td colspan="5" class="fw-bold">AGUA LIBRE</td> -->
                        <template v-for="(dieta) in dietas">
                            <td class="text-xxxs text-center">
                                <span v-for="(mañana, index) in dieta.mañana " :key="index">
                                    <button @click="wiewdieta(mañana)" type="button" class="btn btn-info btn-sm py-0 px-2" style="margin: 0px 1px 0px 1px;">
                                        <i class="fas fa-book" style="font-size: 11px;"></i>
                                    </button>

                                </span>

                            </td>
                            <td class="text-xxxs text-center">
                                <span v-for="(tarde, index) in dieta.tarde " :key="index">
                                    <button @click="wiewdieta(tarde)" type="button" class="btn btn-info btn-sm py-0 px-2 " style="margin: 0px 1px 0px 1px;">
                                        <i class="fas fa-book" style="font-size: 11px; "></i>
                                    </button>
                                </span>
                            </td>
                            <td class="text-xxxs text-center">
                                <div v-for="(noche, index) in dieta.noche " :key="index">
                                    <button @click="wiewdieta(noche)" type="button" class="btn btn-info btn-sm py-0 px-2">
                                        <i class="fas fa-book" style="font-size: 11px; "></i>
                                    </button>
                                </div>
                            </td>
                        </template>

                    </tr>

                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="mt-3 mb-3 ">
            <div class="row mx-0 px-0">
                <div class=" responsive-container-div col-xl-4 px-0 pe-xl-1 mb-3">
                    <div>
                        <table class=" plan w-100 table-bordered border-white  text-center ">
                            <caption class=" py-0 my-0"></caption>
                            <thead class=" text-white text-xxs ">
                                <tr>
                                    <th class="py-1 text-left" colspan="4">PROCEDIMIENTOS INVASIVOS</th>
                                </tr>
                                <tr>
                                    <th class="py-1 cabecera-kardex">PROCEDIMIENTO</th>
                                    <th class="py-1 cabecera-kardex">HISTORIAL</th>
                                    <th class="py-1 cabecera-kardex">FECHA DE INICIO</th>
                                    <th class="py-1 cabecera-kardex">FECHA DE CAMBIO</th>
                                </tr>
                            </thead>
                            <tbody class="div-text-md">
                                <tr v-for="(proc, index) in this.procedimiento_invasivo" :key="index" class="px-1 mb-1">
                                    <td class="border text-start px-1 fw-bold cell-with-icon">{{ proc.name }}
                                        <button type="button" @click="OpenModalProcInvasivo(proc.name,proc.idx)" class="icon-circle-proc">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </td>
                                    <td class="border">
                                        <button type="button" @click="OpenModalProcInvasivoView(proc.name,proc.idx)" class="icon-circle-proc-view">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </td>
                                    <td class="border" v-if="proc.date_start !== ''">{{ formatDateString(proc.date_start) }}</td>
                                    <td class="border" v-else></td>
                                    <td class="border" v-if="proc.date_end !== ''">{{ formatDateString(proc.date_end) }}</td>
                                    <td class="border" v-else></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class=" responsive-container-div col-xl-4 px-0 pe-xl-1 mb-3">
                    <div>
                        <table class=" plan w-100 table-bordered border-white  text-center ">
                            <caption class=" py-0 my-0"></caption>
                            <thead class=" text-white text-xxs ">
                                <tr>
                                    <th class="py-1 text-left" colspan="4">PROCEDIMIENTOS INVASIVOS</th>
                                </tr>
                                <tr>
                                    <th class="py-1 cabecera-kardex">PROCEDIMIENTO</th>
                                    <th class="py-1 cabecera-kardex">HISTORIAL</th>
                                    <th class="py-1 cabecera-kardex">FECHA </th>
                                    <th class="py-1 cabecera-kardex">DETALLE</th>
                                </tr>
                            </thead>
                            <tbody class="div-text-md">
                                <tr v-for="(proc2, index) in this.procedimiento_invasivo2" :key="index" class="px-1 mb-1">

                                    <td class="border text-start px-1 fw-bold cell-with-icon  justify-content-between">
                                        {{ proc2.name }}

                                        <button v-if="proc2.pdf" @click="openPdf(proc2.pdf)" type="button" class="icon-circle-proc2">
                                            <i class="fa fa-file-pdf"></i>
                                        </button>
                                        <button type="button" @click="OpenModalProcInvasivo2(proc2.name,proc2.idx)" class="icon-circle-proc">
                                            <i class="fas fa-plus"></i>
                                        </button>

                                    </td>
                                    <td class="border">
                                        <button type="button" @click="OpenModalProcInvasivoNewView(proc2.name,proc2.idx)" class="icon-circle-proc-view">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </td>
                                    <td class="border" v-if="proc2.date_start !== ''">{{ formatDateString(proc2.date_start) }}</td>
                                    <td class="border" v-else></td>
                                    <td class="border" v-if="proc2.detalle !== ''">{{ shortenedDetalle(proc2.detalle) }}</td>
                                    <td class="border" v-else></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class=" responsive-container-div col-xl-4 px-0 mb-3">
                    <div>
                        <table class=" plan w-100 table-bordered border-white  text-center ">
                            <caption class=" py-0 my-0"></caption>
                            <thead class=" text-white text-xxs ">
                                <tr>
                                    <th class="py-1" colspan="3">ESCALA CORRECTIVA</th>
                                </tr>
                            </thead>
                            <tbody class="div-text" style="height: 100px;">
                                <td class="">
                                    <div v-if="insulina" class="px-2 py-2 ">
                                        <textarea v-model="insulina" class="form-control text-xs" id="exampleFormControlTextarea1" rows="4" readonly></textarea>
                                    </div>
                                    <div v-else class="text-center">
                                        <strong>No hay Resultado</strong>
                                    </div>
                                </td>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table class=" plan w-100 table-bordered border-white  text-center ">
                            <caption class=" py-0 my-0"></caption>
                            <thead class=" text-white text-xxs ">
                                <tr>
                                    <th class="py-1" colspan="3">LOCALIZACIÓN DE CATÉTERES Y UPP</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <td>
                                    <div class="py-3 px-2">
                                        <div class=" card">
                                            <div class="card-body">
                                                <div class=" text-xs text-start">
                                                    <strong> Zona:</strong>
                                                </div>
                                                <div class="text-xs text-start my-2">
                                                    <div class="ms-5 py-3 px-3 rounded rounded-2" style=" background-color:#D2E0F2;">{{ upp.zona ? upp.zona : 'No hay Resultado' }} </div>
                                                </div>
                                                <div class="text-xs text-start">
                                                    <strong> Grado:</strong>
                                                </div>
                                                <div class="text-xs text-start my-2">
                                                    <div class="ms-5 py-3 px-3 rounded rounded-2" style=" background-color:#D2E0F2;"> {{ upp.grado ? upp.grado : 'No hay Resultado' }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-12 px-0 mt-3 mb-3">
            <div class="row mx-0 px-0">
                <div class=" responsive-container-div col-xl-6 px-0  pe-xl-1   mb-3">
                    <div>
                        <table class=" plan w-100 table-bordered border-white  text-center ">
                            <caption class=" py-0 my-0"></caption>
                            <thead class=" text-white text-xxs ">
                                <tr>
                                    <th class="py-1" colspan="4">LABORATORIOS</th>
                                </tr>
                                <tr>
                                    <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                    <th class="py-1">FECHA DE SOLICITUD</th>
                                    <th class="py-1">FECHA DE RESULTADO</th>
                                    <th class="py-1 ">FECHA DE APLICACIÓN</th>
                                </tr>
                            </thead>
                            <tbody class="div-text" v-if="plan_laboratorio.length > 0">
                                <tr class="px-1 mb-1" v-for="(laboratorio , index) in plan_laboratorio" :key="index">

                                    <td class="border">
                                        {{ laboratorio.name }}
                                    </td>
                                    <td class="border">
                                        {{ laboratorio.aplicacion }}
                                    </td>
                                    <td class="border">
                                        {{ laboratorio.resultado ? laboratorio.resultado : '-' }}
                                    </td>
                                    <td v-if="laboratorio.aplicacion_nurse" class="border">
                                        {{ laboratorio.aplicacion_nurse }}
                                    </td>
                                    <td v-else class="border py-1">
                                        <button @click="aplicacion(laboratorio)"  type="button" class="btn py-0 text-white  btn-sm  btn-success text-xs">
                                        Aplicar
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                            <tbody class=" text-center" v-else>
                                <tr>
                                    <td colspan="4" class=" text-center text-xs">
                                        <strong> No hay resultados</strong>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div class=" responsive-container-div col-xl-6 px-0   mb-3">
                    <div>
                        <table class=" plan w-100 table-bordered border-white  text-center ">
                            <caption class=" py-0 my-0"></caption>
                            <thead class=" text-white text-xxs ">
                                <tr>
                                    <th class="py-1" colspan="4">IMAGENES</th>
                                </tr>
                                <tr>
                                    <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                    <th class="py-1">FECHA DE SOLICITUD</th>
                                    <th class="py-1">FECHA DE RESULTADO</th>
                                    <th class="py-1 ">FECHA DE APLICACIÓN</th>
                                </tr>
                            </thead>
                            <tbody class="div-text" v-if="plan_imagenes.length > 0">
                                <tr class="px-1 mb-1" v-for="(imagen , index) in plan_imagenes" :key="index">

                                    <td class="border">
                                        {{ imagen.name }}
                                    </td>
                                    <td class="border">
                                        {{ imagen.aplicacion }}
                                    </td>
                                    <td class="border">
                                        {{ imagen.resultado ? imagen.resultado : '-' }}
                                    </td>
                                    <td v-if="imagen.aplicacion_nurse" class="border">
                                        {{ imagen.aplicacion_nurse }}
                                    </td>
                                    <td v-else class="border py-1">
                                        <button @click="aplicacion(imagen)"  type="button" class="btn py-0 text-white  btn-sm  btn-success text-xs">
                                        Aplicar
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                            <tbody class=" text-center" v-else>
                                <tr>
                                    <td colspan="4" class=" text-center text-xs">
                                        <strong> No hay resultados</strong>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div class=" responsive-container-div col-xl-6 px-0  pe-xl-1   mb-3">
                    <div>
                        <table class=" plan w-100 table-bordered border-white  text-center ">
                            <caption class=" py-0 my-0"></caption>
                            <thead class=" text-white text-xxs ">
                                <tr>
                                    <th class="py-1" colspan="4">PROCEDIMIENTOS</th>
                                </tr>
                                <tr>
                                    <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                    <th class="py-1">FECHA DE SOLICITUD</th>
                                    <th class="py-1">FECHA DE RESULTADO</th>
                                    <th class="py-1 ">FECHA DE APLICACIÓN</th>
                                </tr>
                            </thead>
                            <tbody class="div-text" v-if="plan_procedimientos.length > 0">
                                <tr class="px-1 mb-1" v-for="(Procedimiento , index) in plan_procedimientos" :key="index">

                                    <td class="border">
                                        {{ Procedimiento.name }}
                                    </td>
                                    <td class="border">
                                        {{ Procedimiento.aplicacion }}
                                    </td>
                                    <td class="border">
                                        {{ Procedimiento.resultado ? Procedimiento.resultado : '-' }}
                                    </td>
                                    <td v-if="Procedimiento.aplicacion_nurse" class="border">
                                        {{ Procedimiento.aplicacion_nurse }}
                                    </td>
                                    <td v-else class="border py-1">
                                        <button @click="aplicacion(Procedimiento)"  type="button" class="btn py-0 text-white  btn-sm  btn-success text-xs">
                                        Aplicar
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                            <tbody class=" text-center" v-else>
                                <tr>
                                    <td colspan="4" class=" text-center text-xs">
                                        <strong> No hay resultados</strong>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div class=" responsive-container-div col-xl-6 px-0   mb-3">
                    <div>
                        <table class=" plan w-100 table-bordered border-white  text-center ">
                            <caption class=" py-0 my-0"></caption>
                            <thead class=" text-white text-xxs ">
                                <tr>
                                    <th class="py-1" colspan="4">INTERCONSULTAS</th>
                                </tr>
                                <tr>
                                    <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                    <th class="py-1">FECHA DE SOLICITUD</th>
                                    <th class="py-1">FECHA DE RESULTADO</th>
                                    <th class="py-1">RESPUESTA</th>
                                </tr>
                            </thead>
                            <tbody class="div-text" v-if="plan_interconsultas.length > 0">
                                <tr class="px-1 mb-1" v-for="(interconsulta , index) in plan_interconsultas" :key="index">

                                    <td class="border">
                                        {{ interconsulta.name }}
                                    </td>
                                    <td class="border">
                                        {{ interconsulta.aplicacion }}
                                    </td>
                                    <td class="border">
                                        {{ interconsulta.resultado ? interconsulta.resultado : '-' }}
                                    </td>
                                    <td v-if="interconsulta.resultado" class="border">
                                        <div>
                                            <span>
                                                <a @click="mtdAnswer(interconsulta.interconsulta)"><i class="color-main fas fa-solid fa-eye"></i></a>
                                            </span>
                                        </div>
                                    </td>
                                    <td v-else class="border">
                                        -
                                    </td>
                                </tr>

                            </tbody>
                            <tbody class=" text-center" v-else>
                                <tr>
                                    <td colspan="4" class=" text-center text-xs">
                                        <strong> No hay resultados</strong>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>

    </div>

    <SweetAlert :swal="swal" ref="SweetAlert" @aplicaciondata="aplicaciondata" @mtdaddinsulina="mtdaddinsulina" @mtdChangenew="mtdChangenew" @mtdInputKardexnew="mtdInputKardexnew" @mtdhorario="mtdhorario" />
    <cModalInsumo :type_product="type_product" :id_medical="id_medical" :receta_details="receta_details" :number="number" :title="modal.title" :boo_modal="modal.modal_form" @mtdclosemodalinsumo="mtdclosemodalinsumo" @mtdopenmodal="mtdopenmodal" />
    <cModalOxigeno :id_medical="id_medical" :patient_id="datapatients.patient_id" :title="modaloxigeno.title" :boo_modal="modaloxigeno.modal_form" @mtdclosemodaloxigeno="mtdclosemodaloxigeno" />
    <cModalProcInvasivo :id_medical="id_medical" :patient_id="datapatients.patient_id" :type_kardex="type_kardex" :title="modalprocinvasivo.title" :boo_modal="modalprocinvasivo.modal_form" @mtdclosemodalprocinvasivo="mtdclosemodalprocinvasivo" />
    <cModalProcInvasivoView :id_medical="id_medical" :patient_id="datapatients.patient_id" :type_kardex="type_kardex" :title="modalprocinvasivoview.title" :boo_modal="modalprocinvasivoview.modal_form" @mtdclosemodalprocinvasivoview="mtdclosemodalprocinvasivoview" />
    <Medicine :type="modalmedicine.type" :posmedicine="posmedicine" :receta="receta" :title="modalmedicine.title" :boo_modal="modalmedicine.modal_form" @mtdclosemodalmedicine="mtdclosemodalmedicine" @addreceta="addreceta" />
    <cModalProcInvasivosNew :id_medical_new="id_medical_new" :patient_id="datapatients.patient_id" :type_kardex="type_kardex" :title="modalprocinvasivo2.title" :boo_modal="modalprocinvasivo2.modal_form" @mtdclosemodalprocinvasivo2="mtdclosemodalprocinvasivo2" />
    <cModalProcInvasivoNewView :id_medical_view="id_medical_view" :patient_id="datapatients.patient_id" :type_kardex="type_kardex" :title="modalprocinvasivonewview.title" :boo_modal="modalprocinvasivonewview.modal_form" @mtdclosemodalprocinvasivonewview="mtdclosemodalprocinvasivonewview" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalInsumo from '../modals/cModalInsumo.vue';
import cModalOxigeno from '../modals/cModalOxigeno.vue';
import cModalProcInvasivo from '../modals/cModalProcInvasivo.vue';
import cModalProcInvasivoView from '../modals/cModalProcInvasivoView.vue';
import Medicine from '@/components/Asistencial/UciAdult/modals/cModalMedicine.vue';
import cModalProcInvasivosNew from '../modals/cModalProcInvasivosNew.vue';
import cModalProcInvasivoNewView from '../modals/cModalProcInvasivoNewView.vue';
import moment from "moment";

export default {
    name: 'c-asistencial-uciadulto-historia-kardex',
    data() {
        return {
            swal: null,
            datatime: [],
            datatime2: [],
            medicamentos: [],
            data: [],
            plan_laboratorio: [],
            plan_imagenes: [],
            plan_procedimientos: [],
            plan_interconsultas: [],
            pos: 0,
            nursing_kardexes_1: [],
            nursing_kardexes_2: [],
            nursing_kardexes_3: [],
            nursing_kardexes_4: [],
            nursing_kardexes_5: [],
            nursing_kardexes_6: [],
            dietas: [],
            historys: [],
            notes: [],
            modal: {
                title: '',
                modal_form: false
            },
            posmedicine: null,
            receta: '',
            modalmedicine: {
                title: "",
                modal_form: false,
            },
            number: null,
            receta_details: {},
            id_medical: null,
            patient_id: null,
            type_kardex: '',
            modaloxigeno: {
                title: '',
                modal_form: false
            },
            insulina: '',
            upp: {
                zona: '',
                grado: ''
            },
            type_product: 0,
            modalprocinvasivo: {
                title: '',
                modal_form: false
            },
            modalprocinvasivo2: {
                title: '',
                modal_form: false
            },
            modalprocinvasivoview: {
                title: '',
                modal_form: false
            },
            modalprocinvasivonewview: {
                title: '',
                modal_form: false
            },
            procedimiento_invasivo: [{
                    id: 1,
                    name: 'CVP',
                    idx: 'cvp',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 2,
                    name: 'CVC COLOCACIÓN',
                    idx: 'cvc_colocacion',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 3,
                    name: 'CVC CURACIÓN',
                    idx: 'cvc_curacion',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 4,
                    name: 'CATÉTER ARTERIAL',
                    idx: 'cateter',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 5,
                    name: 'CAF',
                    idx: 'caf',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 6,
                    name: 'FAV',
                    idx: 'fav',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 7,
                    name: 'SONDA FOLEY',
                    idx: 'sonda',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 8,
                    name: 'SNG/SOG',
                    idx: 'sng_sog',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 9,
                    name: 'TOT/TQT',
                    idx: 'tot_tqt',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 10,
                    name: 'VM',
                    idx: 'vm',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 11,
                    name: 'CORRUGADOS',
                    idx: 'corrugado',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 12,
                    name: 'FILTRO HME',
                    idx: 'hme',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 13,
                    name: 'FILTROS ANTIVIRAL',
                    idx: 'antiviral',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 14,
                    name: 'SACC',
                    idx: 'sacc',
                    date_start: '',
                    date_end: ''
                },
                {
                    id: 15,
                    name: 'LÍNEAS',
                    idx: 'linea',
                    date_start: '',
                    date_end: ''
                }
            ],
            procedimiento_invasivo2: [{
                    id: 1,
                    name: 'HEMODIALISIS',
                    idx: 'hemodialisis',
                    date_start: '',
                    detalle: '',
                    pdf: ''
                },
                {
                    id: 2,
                    name: 'DREN',
                    idx: 'dren',
                    date_start: '',
                    detalle: '',
                    pdf: ''
                },
                {
                    id: 3,
                    name: 'TORACOCENTESIS',
                    idx: 'toracocentesis',
                    date_start: '',
                    detalle: '',
                    pdf: ''
                },
                {
                    id: 4,
                    name: 'DRENAJE PLEURAL',
                    idx: 'drenaje_pleural',
                    date_start: '',
                    detalle: '',
                    pdf: ''
                },
                {
                    id: 5,
                    name: 'DRENAJE',
                    idx: 'drenaje',
                    date_start: '',
                    detalle: '',
                    pdf: ''
                },
                {
                    id: 6,
                    name: 'PUNCIÓN LUMBAR',
                    idx: 'puncion_lumbar',
                    date_start: '',
                    detalle: '',
                    pdf: ''
                },
                {
                    id: 7,
                    name: 'PARACENTESIS',
                    idx: 'paracentesis',
                    date_start: '',
                    detalle: '',
                    pdf: ''
                },
                {
                    id: 8,
                    name: 'TRANSFUSIONES SANGUÍNEAS',
                    idx: 'transfusiones_sanguineas',
                    date_start: '',
                    detalle: '',
                    pdf: ''
                }

            ],
            id_medical_new: 0,
            id_medical_view:0,
            recipes: []
        }
    },
    components: {
        SweetAlert,
        cModalInsumo,
        cModalOxigeno,
        cModalProcInvasivo,
        cModalProcInvasivoView,
        Medicine,
        cModalProcInvasivosNew,
        cModalProcInvasivoNewView
    },

    props: {
        medical_act_id: {
            type: Number,
            default: 0
        },
        datapatients: {
            type: Object,
            default: () => ({})
        },
        dete_out: {
            type: Boolean
        }
    },
    

    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0) {
                    this.mtdupadatetime();
                    this.mtdGetplanes();
                    this.indextime();
                }
            },
            deep: true,
            immediate: true,
        },

    },
    created() {
        /* this.mtdtypepatient(); */
    },
    methods: {
        ...mapActions(['get', 'post']),
        indextime: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Uciadulto/indexschedule/" + this.medical_act_id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.insulina = response.data.insulina ? response.data.insulina.insulin : '',
                        this.upp = {
                            zona: response.data.upp ? response.data.upp.zona : '',
                            grado: response.data.upp ? response.data.upp.level : ''
                        }
                    this.datatime = response.data.allDates;
                    this.datatime2 = response.data.allDates2;
                    this.procedimiento_invasivo = response.data.procedimiento_invasivo;
                    this.procedimiento_invasivo2 = response.data.procedimiento_invasivo2;
                    let ids = [];
                    response.data.results.forEach(recipe => {
                        ids.push(recipe.id)
                        recipe.recipedetails.forEach(recipedetail => {
                            let medicenedetail = {
                                recipe_id: recipedetail.recipe_id,
                                recipedetail_id: recipedetail.id,
                                name: recipedetail.molecule + ' ' + recipedetail.presentation ,
                                quantity: recipedetail.quantity,
                                group: recipedetail.mix,
                                dosis: recipedetail.dosis,
                                via: recipedetail.via,
                                hora: recipedetail.frequency,
                                start: recipedetail.start_date ? true : false,
                                prn: recipedetail.prn == 1 ? true : false,
                                cancelation: recipedetail.cancellation_date ? true : false,
                                insuline: recipedetail.insulin,
                                type: recipedetail.type,
                                indicaciones:recipedetail.indicaciones,
                                count : recipedetail.count+1,
                                horario: []
                            }
                            this.datatime.forEach(item => {
                                let date = {
                                    date: item,
                                    mañana: [],
                                    tarde: [],
                                    noche: [],
                                }
                                medicenedetail.horario.push(date);
                            });
                            let aplicaciones = 0
                            recipedetail.schedule.forEach(horario => {
                                aplicaciones++
                                let num = 0
                                medicenedetail.horario.forEach(hour => {
                                    if (hour.date == horario.date) {
                                        if (medicenedetail.type == 3) {
                                            horario.aplicacion = `(${this.convertirARomanos(aplicaciones)})`
                                        }
                                        if (horario.time_kardex >= "07:31:00" && horario.time_kardex < "13:31:00") {
                                            hour.mañana.push(horario);
                                        } else if (horario.time_kardex >= "13:31:00" && horario.time_kardex < "19:30:00") {
                                            hour.tarde.push(horario);
                                        } else if (horario.time_kardex >= "19:30:00" && horario.time_kardex <= "23:59:00") {
                                            hour.noche.push(horario);
                                        } else {
                                            medicenedetail.horario[num - 1].noche.push(horario)
                                        }
                                    }
                                    num++
                                })
                            })
                            this.medicamentos.push(medicenedetail)
                        })

                    });
                    let num = 0
                    this.medicamentos.forEach(me => {
                        if (me.horario[this.datatime.length - 1].mañana.length == 0 &&
                            me.horario[this.datatime.length - 1].tarde.length == 0 &&
                            me.horario[this.datatime.length - 1].noche.length == 0
                        ) {
                            this.pos = 0;
                        } else {
                            num = num + 1
                        }

                    })
                    if (num == 0) {
                        this.datatime.pop();
                        this.medicamentos.forEach(me => {
                            me.horario.pop()
                        })
                    }
                    this.recipes = [];
                    ids.forEach(item => {
                        let recipe = {
                            id: item,
                            medicamentos: []
                        }
                        this.medicamentos.forEach(item2 => {
                            if (item == item2.recipe_id) {
                                recipe.medicamentos.push(item2)
                            }
                        })
                        this.recipes.push(recipe);

                    })

                    // kardex 
                    let datedataK_1 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_1.push(date);
                    });

                    response.data.nursing_kardexes_1.forEach(kardex => {
                        let numkardex = 0
                        datedataK_1.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    // if (numkardex > 0) {
                                    //     datedataK_1[numkardex - 1].noche.push(kardex)
                                    // } else {
                                    datedataK_1[numkardex].noche.push(kardex)
                                    // }
                                }
                            }
                            numkardex++
                        })
                    });
                    this.nursing_kardexes_1 = datedataK_1;

                    let datedataK_2 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_2.push(date);
                    });
                    response.data.nursing_kardexes_2.forEach(kardex => {
                        let numkardex = 0
                        datedataK_2.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    // if (numkardex > 0) {
                                    //     datedataK_2[numkardex - 1].noche.push(kardex)
                                    // } else {
                                    datedataK_2[numkardex].noche.push(kardex)
                                    // }
                                }
                            }
                            numkardex++
                        })
                    });
                    this.nursing_kardexes_2 = datedataK_2;

                    let datedataK_3 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_3.push(date);
                    });
                    response.data.nursing_kardexes_3.forEach(kardex => {
                        let numkardex = 0
                        datedataK_3.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    // if (numkardex > 0) {
                                    //     datedataK_3[numkardex - 1].noche.push(kardex)
                                    // } else {
                                    datedataK_3[numkardex].noche.push(kardex)
                                    // }
                                }
                            }
                            numkardex++
                        })
                    });
                    this.nursing_kardexes_3 = datedataK_3;

                    let datedataK_4 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_4.push(date);
                    });
                    response.data.nursing_kardexes_4.forEach(kardex => {
                        let numkardex = 0
                        datedataK_4.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    // if (numkardex > 0) {
                                    //     datedataK_4[numkardex - 1].noche.push(kardex)
                                    // } else {
                                    datedataK_4[numkardex].noche.push(kardex)
                                    // }
                                }
                            }
                            numkardex++
                        })
                    });
                    this.nursing_kardexes_4 = datedataK_4;

                    let datedataK_5 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_5.push(date);
                    });
                    response.data.nursing_kardexes_5.forEach(kardex => {
                        let numkardex = 0
                        datedataK_5.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    // if (numkardex > 0) {
                                    //     datedataK_5[numkardex - 1].noche.push(kardex)
                                    // } else {
                                    datedataK_5[numkardex].noche.push(kardex)
                                    // }
                                }
                            }
                            numkardex++
                        })
                    });
                    this.nursing_kardexes_5 = datedataK_5;

                    let datedataK_6 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_6.push(date);
                    });
                    response.data.nursing_kardexes_6.forEach(kardex => {
                        let numkardex = 0
                        datedataK_6.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    // if (numkardex > 0) {
                                    //     datedataK_6[numkardex - 1].noche.push(kardex)
                                    // } else {
                                    datedataK_6[numkardex].noche.push(kardex)
                                    // }
                                }
                            }
                            numkardex++
                        })
                    });
                    this.nursing_kardexes_6 = datedataK_6;

                    // dieta 
                    let datedata = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedata.push(date);
                    });
                    response.data.dietas.forEach(dieta => {
                        let numdieta = 0
                        datedata.forEach(date => {
                            if (dieta.date == date.date) {
                                if (dieta.hour >= "07:31:00" && dieta.hour < "13:31:00") {
                                    date.mañana.push(dieta);
                                } else if (dieta.hour >= "13:31:00" && dieta.hour < "19:30:00") {
                                    date.tarde.push(dieta);
                                } else if (dieta.hour >= "19:30:00" && dieta.hour <= "23:59:00") {
                                    date.noche.push(dieta);
                                } else {
                                    datedata[numdieta - 1].noche.push(dieta)
                                }
                            }
                            numdieta++
                        })
                    });

                    this.dietas = datedata;
                    // curaciones

                    let datedataC = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataC.push(date);
                    });
                    response.data.historys.forEach(item => {
                        let numhistory = 0
                        datedataC.forEach(date => {
                            if (item.aplication_date == date.date) {
                                if (item.hour >= "07:31:00" && item.hour < "13:31:00") {
                                    date.mañana.push(item);
                                } else if (item.hour >= "13:31:00" && item.hour < "19:30:00") {
                                    date.tarde.push(item);
                                } else if (item.hour >= "19:30:00" && item.hour <= "23:59:00") {
                                    date.noche.push(item);
                                } else {
                                    datedataC[numhistory - 1].noche.push(item)
                                }
                            }
                            numhistory++
                        })
                    })
                    this.historys = datedataC
                    // notes 
                    let datedataN = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: '',
                            tarde: '',
                            noche: '',
                        }
                        datedataN.push(date);
                    });
                    response.data.notes.forEach(item => {

                        let numnote = 0
                        datedataN.forEach(date => {
                            if (item.date == date.date) {
                                if (item.hour >= "07:31:00" && item.hour < "13:31:00") {
                                    date.mañana = item;
                                } else if (item.hour >= "13:31:00" && item.hour < "19:30:00") {
                                    date.tarde = item;
                                } else if (item.hour >= "19:30:00" && item.hour <= "23:59:00") {
                                    date.noche = item;
                                } else {
                                    datedataN[numnote - 1].noche = item
                                }
                            }
                            numnote++
                        })
                    })
                    this.notes = datedataN

                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdChange: function (payload) {
            if (payload.status == 2) {
                if (payload.user_id) {
                    let comment = {
                        name: payload.user.name + ' ' + payload.user.last_name,
                        date: this.formatDateHour(payload.date_comment),
                        comment: payload.comment,
                        glucosa: payload.glucose,
                        insulina: payload.insulin_collected,
                    }
                    this.$refs.SweetAlert.comment(comment);
                } else {
                    if (payload.recipedetails.molecule.toLowerCase().includes('insulina')) {
                        this.$refs.SweetAlert.Showescalaobservation(payload);
                    } else {
                        this.$refs.SweetAlert.showComment(payload);
                    }
                }
            } else if (payload.status == 0) {
                if (payload.recipedetails.molecule.toLowerCase().includes('insulina')) {
                    this.$refs.SweetAlert.Showescala(payload);
                } else {
                    this.$refs.SweetAlert.showUpdateTime(payload);
                }
            } else {
                let comment = {
                    name: payload.user.name + ' ' + payload.user.last_name,
                    date: this.formatDateHour(payload.date_aplicate),
                    glucosa: payload.glucose,
                    insulina: payload.insulin_collected,
                }
                this.$refs.SweetAlert.comment(comment);
            }

        },
        mtdInputKardexnew: function (description, date, type) {
            this.post({
                url: this.$store.getters.get__url + "/Uciadulto/nursingkardex",
                token: this.$store.getters.get__token,
                params: {
                    description: description,
                    date: date,
                    type: type,
                    medical_act_id: this.medical_act_id
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    if (response.data != []) {
                        this.medicamentos = [];
                        this.indextime()
                        this.$refs.SweetAlert.showSuccess(response.message);
                    } else {
                        this.$refs.SweetAlert.showWarning(response.message);
                    }
                }
            });
        },
        mtdInputKardex: function (payloadK, type) {
            this.$refs.SweetAlert.showCommentKardex(payloadK, type);
        },
        mtdInputKardexView: function (payload) {
            let comment = {
                name: payload.nurse.name,
                date: this.formatDate(payload.date),
                date_comment: this.formatDateFull(payload.created_at) + ', ' + payload.hour,
                comment: payload.description
            }
            this.$refs.SweetAlert.commentKardex(comment);
        },
        mtdInputOxigenoView: function (payload) {
            let comment = {
                name: payload.nurse.name,
                date: this.formatDate(payload.date),
                date_comment: this.formatDateFull(payload.created_at) + ', ' + payload.hour,
                comment: 'FIO2: ' + payload.fio2 + ' | LITROS: ' + payload.liter
            }
            this.$refs.SweetAlert.commentKardex(comment);
        },
        hasElements(variable) {
            return Array.isArray(variable) && variable.length > 0;
        },
        formatDateHour(dateString) {
            const options = {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            };
            const date = new Date(dateString);
            return date.toLocaleDateString('es-ES', options);
        },
        formatDateFull(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${day}/${month}/${year}`;
            return formattedDate;
        },
        formatDate(dateString) {
            const options = {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            };
            const [day, month, year] = dateString.split('/');
            const date = new Date(year, month - 1, day);
            return date.toLocaleDateString('es-ES', options);
        },
        mtdChangenew: function (newpayload) {
            this.post({
                url: this.$store.getters.get__url + "/Uciadulto/kardexupdate",
                token: this.$store.getters.get__token,
                params: newpayload
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.indextime()
                    this.medicamentos = [];
                    this.$refs.SweetAlert.showSuccess(response.message);
                }
            });
        },
        mtdupadatetime: function () {
            this.get({
                url: this.$store.getters.get__url + "/Uciadulto/kardexupdatehour/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.data = response.data
                }
            });
        },
        formatTime(timeString) {
            const [hours, minutes] = timeString.split(':');
            return `${hours}:${minutes}`;
        },
        mtdGetplanes: function () {
            this.get({
                url: this.$store.getters.get__url + "/Uciadulto/indexRp/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    let plandetails = response.data.planes;
                    this.plan_laboratorio = []
                    this.plan_imagenes = []
                    this.plan_procedimientos = []
                    this.plan_interconsultas = []
                    plandetails.forEach(item => {
                        let concepto = {
                            id:item.id,
                            medical_act_id:item.medical_act_id,
                            subsubcategoria_id:item.subsub_categorie_id,
                            subcategoria: item.subsubcategorie.subategory_id,
                            name: item.subsubcategorie.name,
                            aplicacion: item.aplication_date_new,
                            resultado: item.result_date_new,
                            aplicacion_nurse: item.application_date_nurse_new,
                            interconsulta: item.interconsulta ? item.interconsulta : '-'
                        }

                        if (item.type == 1) {
                            this.plan_laboratorio.push(concepto)
                        } else if (item.type == 2) {
                            this.plan_imagenes.push(concepto)
                        } else if (item.type == 3) {
                            this.plan_procedimientos.push(concepto)
                        } else {
                            this.plan_interconsultas.push(concepto)
                        }

                    });
                }
            });
        },

        wiewdieta: function (payload) {
            let comment = {
                name: payload.doctor.name,
                date: this.formatDate(payload.date) + ', ' + payload.hour,
                comment: payload.name
            }
            this.$refs.SweetAlert.comment(comment);
        },
        OpenModal: function (ind) {
            this.modal = {
                title: ind == 2 ? 'Listado de Insumos' : ' Listado de Soluciones',
                modal_form: true
            }
            this.id_medical = this.medical_act_id
            this.type_product = ind
        },
        OpenModalOxigeno: function () {
            this.modaloxigeno = {
                title: 'Oxigenoterapia',
                modal_form: true
            }
            this.id_medical = this.medical_act_id
            this.patient_id = this.datapatients.patient_id
        },
        OpenModalProcInvasivo: function (name, type_kardex) {
            this.modalprocinvasivo = {
                title: 'Procedimientos Invasivos: ' + name,
                modal_form: true
            }
            this.id_medical = this.medical_act_id,
                this.patient_id = this.datapatients.patient_id,
                this.type_kardex = type_kardex
        },
        OpenModalProcInvasivo2: function (name, type_kardex) {
            this.modalprocinvasivo2 = {
                title: 'Procedimientos Invasivos: ' + name,
                modal_form: true
            }
            this.id_medical_new = this.medical_act_id,
                this.patient_id = this.datapatients.patient_id,
                this.type_kardex = type_kardex
        },
        OpenModalProcInvasivoView: function (name, type_kardex) {
            this.modalprocinvasivoview = {
                title: 'Procedimiento Invasivo: ' + name,
                modal_form: true
            }
            this.id_medical = this.medical_act_id,
                this.patient_id = this.datapatients.patient_id,
                this.type_kardex = type_kardex
        },
        OpenModalProcInvasivoNewView: function (name, type_kardex) {
            this.modalprocinvasivonewview = {
                title: 'Procedimiento Invasivo: ' + name,
                modal_form: true
            }
            this.id_medical_view = this.medical_act_id,
                this.patient_id = this.datapatients.patient_id,
                this.type_kardex = type_kardex
        },
        mtdclosemodalinsumo: function () {
            this.modal = {
                title: '',
                modal_form: false
            }
            this.id_medical = 0
            if (this.type_product == 3) {
                this.medicamentos = []
                this.indextime();
            }
        },
        mtdclosemodaloxigeno: function () {
            this.modaloxigeno = {
                title: '',
                modal_form: false
            }
            this.medicamentos = [];
            this.indextime();
            this.id_medical = 0,
                this.patient_id = 0
        },
        mtdclosemodalprocinvasivo: function () {
            this.modalprocinvasivo = {
                title: '',
                modal_form: false
            }
            this.medicamentos = []
            this.indextime();
            this.id_medical = 0,
                this.patient_id = 0,
                this.date_start = null,
                this.date_end = null
        },
        mtdclosemodalprocinvasivo2: function () {
            this.modalprocinvasivo2 = {
                title: '',
                modal_form: false
            }
            this.medicamentos = []
            this.indextime();
            this.id_medical_new = 0,
                this.patient_id = 0,
                this.date_start = null,
                this.date_end = null
        },
        mtdclosemodalprocinvasivoview: function () {
            this.modalprocinvasivoview = {
                title: '',
                modal_form: false
            }
            this.medicamentos = []
            this.indextime();
            this.id_medical = 0,
                this.patient_id = 0
        },
        mtdclosemodalprocinvasivonewview: function () {
            this.modalprocinvasivonewview = {
                title: '',
                modal_form: false
            }
            this.medicamentos = []
            this.indextime();
            this.id_medical_view = 0,
            this.patient_id = 0
        },
        mtdopenmodal: function (receta) {
            this.receta = receta
            this.posmedicine = 1;
            this.modalmedicine = {
                title: this.type_product == 2 ? "Lista de Insumos" : 'Lista de Soluciones',
                modal_form: true,
                type: this.type_product
            }
        },
        mtdclosemodalmedicine: function () {
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            this.number = 1

        },
        addreceta: function (payload) {
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            this.receta_details = payload

        },
        mtdAnswer: function (payload) {
            let comment = {
                doctor: payload.doctor1.name + ' ' + payload.doctor1.last_name,
                doctorI: payload.doctor2.name + ' ' + payload.doctor2.last_name,
                fecha: this.formatDateHour(payload.updated_at),
                respuesta: payload.answer
            }
            this.$refs.SweetAlert.commentAnswer(comment);
        },

        mtdaddhorario: function (payload) {

            if (payload.prn) {
                if (payload.name.toLowerCase().includes('insulina')) {
                    this.$refs.SweetAlert.horarioPrnInsulina(payload);
                } else {
                    this.$refs.SweetAlert.horarioPrn(payload);
                }
            } else {
                this.$refs.SweetAlert.horario(payload);
            }

        },

        mtdhorario: function (hour, payload) {
            let data = {
                hour: hour,
                recipedetail_id: payload.recipedetail_id,
                prn: payload.prn,
                glucosa: payload.glucosa,
                escala_recogida: payload.escala_recogida
            }
            this.post({
                url: this.$store.getters.get__url + "/admision/KardexTime",
                token: this.$store.getters.get__token,
                params: data
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.medicamentos = []
                    this.indextime();
                }
            });
        },

        mtdinsule: function (payload) {
            this.$refs.SweetAlert.showInsuline(payload);
        },
        mtdaddinsulina: function (payload) {
            this.$refs.SweetAlert.showUpdateTime(payload);
        },
        formatDateString(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "";
        },
        mtdviewdetalle: function (payload) {
            let comment = {
                name: payload.nurse.name + ' ' + payload.nurse.last_name,
                date: this.formatDate(payload.date) + ', ' + payload.hour,
                zona: payload.zona,
                grado: payload.level,
                comment: payload.healing_detail ? payload.healing_detail : 'sin resultados'

            }
            this.$refs.SweetAlert.ViewNotesDetalle(comment);
        },
        convertirARomanos: function (num) {
            const romanos = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];
            const valores = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
            let resultado = '';

            for (let i = 0; i < valores.length; i++) {
                while (num >= valores[i]) {
                    resultado += romanos[i];
                    num -= valores[i];
                }
            }
            return resultado;
        },
        openPdf: function (url) {
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, '_blank');
        },
        shortenedDetalle(detalle) {
            const maxLength = 25; 
            return detalle.length > maxLength ? detalle.substring(0, maxLength) + '...' : detalle;
        },
        aplicacion:function(payload){
            this.$refs.SweetAlert.aplication(payload);
        },
        aplicaciondata:function(payload){
            this.post({
                url: this.$store.getters.get__url + "/Emergency/aplicationNurse",
                token: this.$store.getters.get__token,
                params: payload,
            }).then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdGetplanes();   
            });
        }
    }
}
</script>

<style scoped>
.libre {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: green;
    border-radius: 50%;
}

.reservado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 50%;
}

.ocupado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
}

/* table */

.table-container {
    display: flex;
    overflow-x: auto;
    position: relative;
}

.fixed-column-wrapper {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
    border-right: 1px solid #dee2e6;
}

.scrollable-table-wrapper {
    overflow-x: auto;
    margin-left: -1px;
    /* Adjust to eliminate border overlap */
}

.table,
.table-2 {
    margin-bottom: 0;
}

.fixed-column-wrapper .table,
.fixed-column-wrapper .table-2 {
    margin-right: -1px;
    /* Adjust to eliminate border overlap */
}

.table-responsive-edit {
    position: relative;
    overflow-x: auto;
}

.table,
.table-2 {
    display: table;
    width: 100%;
    min-width: max-content;
}

.table th,
.table td,
.table-2 th,
.table-2 td {
    position: relative;
    z-index: 1;
}

.table th {
    background: #343a40;
    color: #fff;
    white-space: nowrap;
}

.table-2 th {
    padding: 6px;
    background: #343a40;
    color: #fff;
    white-space: nowrap;
}

.plan th {
    background: #343a40;
    color: #fff;
}

.table tbody th,
.table tbody td {
    position: relative;
    z-index: 1;
}

.table-2 tbody th,
.table-2 tbody td {
    padding: 8px;
    position: relative;
    z-index: 1;
}

.table-2 tbody td.column-second1,
.table-2 tbody td.column-second2:first-child,
.table-2 tbody td.column-second3,
.table-2 tbody td.column-second4:first-child {
    position: sticky;
    background: #9e9e9e;
    width: 100px !important;
    left: 103px;
    z-index: 10;
}

.table thead tr#cabecera th:first-child,
.table-2 thead tr#cabecera th:first-child {
    z-index: 9 !important;
}

.table thead tr#cabecera th:nth-child(2),
.table thead tr#cabecera th:nth-child(3),
.table thead tr#cabecera th:nth-child(4),
.table thead tr#cabecera th:nth-child(5) {
    z-index: 9 !important;
}

.table thead tr#cabecera-fija th:first-child {
    position: sticky;
    width: 10px !important;
    left: 0;
    z-index: 10;
}

.table-2 thead tr#cabecera-fija th:first-child {
    position: sticky;
    width: 10px !important;
    left: 0;
    z-index: 10;
}

.table tbody th:first-child,
.table tbody td:first-child {
    position: sticky;
    width: 10px !important;
    left: 0;
    background: #9e9e9e;
    z-index: 2;
}

.table-2 tbody th:first-child,
.table-2 tbody td:first-child {
    position: sticky;
    width: 10px !important;
    left: 0;
    background: #9e9e9e;
    z-index: 2;
}

.table thead tr th:nth-child(2) {
    position: sticky;
    width: 50px !important;
    left: 29px;
    z-index: 10;
}

.table tbody th:nth-child(2),
.table tbody td:nth-child(2) {
    position: sticky;
    width: 50px !important;
    left: 29px;
    background: #9e9e9e;
    z-index: 2;
}

.table thead tr th:nth-child(3) {
    position: sticky;
    left: 164px;
    z-index: 10;
}

.table tbody th:nth-child(3),
.table tbody td:nth-child(3) {
    position: sticky;
    width: 160px !important;
    left: 164px;
    background: #9e9e9e;
    z-index: 2;
}

.table thead tr th:nth-child(4) {
    position: sticky;
    left: 324px;
    z-index: 10;
}

.table tbody th:nth-child(4),
.table tbody td:nth-child(4) {
    position: sticky;
    left: 324px;
    background: #9e9e9e;
    z-index: 2;
}

.table thead tr th:nth-child(5) {
    position: sticky;
    left: 357px;
    z-index: 10;
}

.table tbody th:nth-child(5),
.table tbody td:nth-child(5) {
    position: sticky;
    left: 357px;
    background: #9e9e9e;
    z-index: 2;
}

.text-start {
    text-align: left !important;
}

.cabecera-kardex {
    background: #9e9e9e !important;
    color: #000000 !important;
    font-size: 9px;
}

.cell-with-icon {
    position: relative;
    text-align: center;
}

.icon-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background-color: #1db2e4;
    color: white;
    border-radius: 50%;
    font-size: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.icon-circle-proc-view {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background-color: #1db2e4;
    color: white;
    border-radius: 50%;
    border: #1db2e4;
    font-size: 10px;
    padding: 10px;
    cursor: pointer;
}

.icon-circle-proc {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background-color: #4CAF50;
    color: white;
    border-radius: 50%;
    border: #4CAF50;
    font-size: 10px;
    position: absolute;
    right: 10px;
    top: 50%;
    padding: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.icon-circle-proc2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background-color: red;
    color: white;
    border-radius: 50%;
    border: red;
    font-size: 10px;
    position: absolute;
    right: 32px;
    top: 50%;
    padding: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}
</style>
