<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'lg'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">#</th>
                        <th scope="col">VIA</th>
                        <th scope="col">DOSIS</th>
                        <th scope="col">MOLECULA</th>
                        <th scope="col">PRESENTACIÓN</th>
                        <th scope="col">DURACION</th>
                        <th scope="col">FRECUENCIA</th>
                      </tr>
                  </thead>
                  <tbody class="border-main" style="font-size: 12px">
                    <tr v-for="(det, index) in cpData" :key="index" class="text-center align-middle">
                      <template>
                        <td>{{ index + 1 }}</td>
                        <td>{{ det.via}}</td>
                        <td>{{ det.dosis ? det.dosis : '-'}}</td>
                        <td>{{ det.molecule}}</td>
                        <td>{{ det.presentation ? det.presentation : '-'}}</td>
                        <template v-if="det.type == 1">
                          <td>{{ det.duration}}</td>
                          <td>{{ det.frequency}}</td>
                        </template>
                        <td v-else colspan="2">{{ det.total}}</td>
                      </template>
                    </tr>
                  </tbody>
              </table>
          </div>

      </div>
    </div>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import { mapActions } from "vuex";
export default {
  name: "c-Pharmacy-cModalViewDetail",
  components: {
    CModal,
  },
  data() {
    return {
      tabla: [],
    };
  },
  created() {},
  computed: {
    cpData() {
      let show = [];
      this.item.forEach((element) => {
        show.push(element);
      });
      this.tabla = show;
      return show;
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
  },
  methods: {
    ...mapActions(["get", "post"]),
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },

    mtdClose() {
      this.$emit("mtdCloseModalView");
    },
  },
};
</script>
<style scoped>
.align-middle{
  vertical-align: middle;
}
</style>
