<template>
  <div>
    <div class="container" v-if="view == 'data'">
      <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
          <button type="button" class="btn btn-outline-main" @click="returnHome">
              <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
      </div>
      <div id="div-modal-cxc" class="d-flex justify-content-center">
          <div id="div-cxc" class="card w-100">
              <div class="card-header text-white h4 bg-main">
                    <div class="row d-flex text-center">
                        <div class="h5 col-xl-11 col-lg-11 col-md-10 col-sm-9 col-xs-8 my-auto">
                            <strong>RECETAS DE {{acto.patient.name}}</strong>
                        </div>
                        <div class="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-xs-4">
                            <div class="d-flex justify-content-end">
                              <button class="btn btn-primary rounded-circle carMedic" type="button"  @click="openDestino()">
                                <i class="far fa-plus"></i>
                              </button>
                            </div>
                        </div>
                    </div>
                </div>
              <div class="card-body w-100">
                  <div class="row">
                      <div class="col-md-12 mt-2" id="content-timelinetable">
                          <table id="tb-cxc" class="table table-bordered border-main">
                              <caption class="py-0 my-0"></caption>
                              <thead>
                                  <tr class="text-center text-white border-white bg-main align-middle">
                                      <th scope="col">FECHA</th>
                                      <th scope="col">DOCTOR</th>
                                      <th scope="col">ORIGEN</th>
                                      <th scope="col">ESTADO</th>
                                      <th scope="col">ACCIONES</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr v-if="!recipe || recipe.length === 0">
                                  <td colspan="5" class="text-center">No hay registros</td>
                                </tr>
                                <template v-else>
                                  <tr  v-for="(item, index) in recipe" :key="index" class="text-center align-middle">
                                    <td>{{ item.created_at | formatDateTime }}</td>
                                    <td>{{  item.doctor ? item.doctor.name : '-' }}</td>
                                    <td class="text-nowrap" v-if="item.origin">
                                      {{ item.origin }} - {{ item.type | formatType }}
                                    </td>
                                    <td class="text-nowrap" v-else>
                                      {{ item.type | formatType }}
                                    </td>
                                    <td class="text-center">
                                      <div class="badge text-bg-danger" v-if="item.state == 1">
                                        <div><strong>SIN ATENDER</strong></div>
                                      </div>
                                      <div
                                        class="badge text-bg-warning"
                                        v-else-if="item.state == 2"
                                      >
                                        <div>
                                          <strong>EN PROCESO</strong>
                                        </div>
                                      </div>
                                      <div
                                        class="badge text-bg-success"
                                        v-else-if="item.state == 3"
                                      >
                                        <div>
                                          <strong>COMPLETADO</strong>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="d-flex d-md-block">
                                        <button
                                            @click="openDetail(item)" class="btn btn-primary btn-sm rounded-circle bootom me-1">
                                            <i class="fas fa-pills icono-pills"></i>
                                        </button>
                                        <button 
                                            @click="viewDetail(item)" class="btn btn-primary btn-sm rounded-circle bootom me-1">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                        <button 
                                            @click="viewRecomend(item.indicaciones)" class="btn btn-primary btn-sm rounded-circle bootom" title="Indicaciones Medicas">
                                            <i class="fas fa-notes-medical"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="px-3" v-else-if="view == 'detailHos'">
      <cDetailHos
        @mtdBack="mtdBack"
        :acto="acto"
        :emit="emit"
        :recipe="selectedItem"
        @updateDataHos="updateData"
      />
    </div>
    <div class="px-3" v-else-if="view == 'uciAdult'">
      <cUciAdult
        @mtdBack="mtdBack"
        :acto="acto"
        :emit="emit"
        :recipe="selectedItem"
        @updateDataUciAdult="updateData"
      />
    </div>
    <div class="px-3" v-else-if="view == 'emergencia'">
      <cEmergencia
        @mtdBack="mtdBack"
        :acto="acto"
        :emit="emit"
        :recipe="selectedItem"
        @updateDataEmergencia="updateData"
      />
    </div>
    <div class="px-3" v-else-if="view == 'detailCon'">
      <cDetailCon
        @mtdBack="mtdBack"
        :acto="acto"
        :emit="emit"
        :recipe="selectedItem"
        @updateDataCon="updateData"
      />
    </div>
    <div class="px-3" v-else-if="view == 'detailOpe'">
      <cDetailOpe
        @mtdBack="mtdBack"
        :acto="acto"
        :emit="emit"
        :recipe="selectedItem"
        @updateDataOpe="updateData"
      />
    </div>
    <div class="" v-else-if="view == 'carrito'">
      <cCarrito
        @mtdBack="mtdBackCar"
        @mtdSendCarrito="mtdSendCarritoCli"
        :recipeId="carrito.recipeId"
        :actoHisto="actoHisto"
        :type="carrito.type"
      />
    </div>
    <cModalViewDetailVue
      :title="modalDeta.title"
      :boo_modal="modalDeta.boo_modal"
      :item="modalDeta.item"
      @mtdCloseModalView="mtdCloseModal"
    />
    <cModalAddDestinoCarroVue
        :title="modalDestino.title"
        :boo_modal="modalDestino.boo_modal"
        :item="modalDestino.item"
        @mtdCloseModalDestino="mtdCloseModalDestino"
        @addReci="addReci"
    />
    <cModalViewRecomendVue
        :title="modalRe.title"
        :boo_modal="modalRe.boo_modal"
        :item="modalRe.item"
        @mtdCloseModalRe="mtdCloseModalRe"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import cDetailHos from "./cHospiAc.vue";
import cDetailCon from "./cConsuAc.vue";
import cDetailOpe from "./cOpeAc.vue";
import cUciAdult from "./cUciAdult.vue";
import cEmergencia from "./cEmergencia.vue";
import cModalViewDetailVue from "../Modals/cModalViewDetail.vue";
import cCarrito from "./cCarritoCli.vue";
import cModalAddDestinoCarroVue from "../Modals/cModalAddDestinoCarro.vue";
import cModalViewRecomendVue from "../Modals/cModalViewRecomend.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import moment from "moment";
export default {
  name: "c-pharmacy-cRecipes",
  components: {
    SweetAlert,
    cCarrito,
    cDetailHos,
    cDetailCon,
    cDetailOpe,
    cUciAdult,
    cEmergencia,
    cModalViewDetailVue,
    cModalAddDestinoCarroVue,
    cModalViewRecomendVue
  },
  props: {
    acto: [],
    recipe: [],
    actoHisto: [],
    emit: Number,
  },
  data() {
    return {
      swal: null,
      view: "data",
      search: "",
      page: 0,
      modalDeta: {
        title: "Detalles de Receta",
        item: [],
        boo_modal: false,
        pos: null,
      },
      modalRe: {
        title: "Recomendación",
        item: null,
        boo_modal: false,
      },
      carrito: {
        data: [],
        recipeId: 0,
        patient_id: null,
        medical_act_id: null,
        type: "",
        histo_id: null
      },
      modalDestino: {
          title: "Agregar Tipo",
          item: [],
          boo_modal: false,
      },
      selectedItem: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions(["get", "post"]),

    returnHome: function () {
      this.$emit("mtdBack");
    },

    openDetail: function (item) {
      if (item.clinical_historie_type == 6) {
        this.mtdRecipeDetail(item.id);
        this.view = "detailOpe";
      } else if (
        item.clinical_historie_type == 1
      ) {
        this.mtdRecipeDetail(item.id);
        this.view = "detailCon";
      } else if ( item.clinical_historie_type == 3) {
        this.mtdRecipeDetail(item.id);
        this.view = "detailHos";
      } else if ( item.clinical_historie_type == 4) {
        this.mtdRecipeDetail(item.id);
        this.view = "uciAdult";
      }  else if ( item.clinical_historie_type == 7) {
        this.mtdRecipeDetail(item.id);
        this.view = "emergencia";
      }
    },

    mtdRecipeDetail: function (id) {
      this.get({
            url: this.$store.getters.get__url + "/Pharmacy/mtdRecipeDetail/" + id,
            token: this.$store.getters.get__token,
        })
      .then((response) => {
          this.selectedItem = response.data;
      })
      .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
      });
    },

    mtdBack: function (check) {
      if(check == 1){
        this.view = "data";
      }else if(check == 2){
        this.$emit("retrocederCheck");
      }
    },
    
    /*Recargado*/
    updateData: function (recipeId, data) {
      this.openDetailUp(recipeId, data);
      this.$emit("updateData", this.acto, this.emit);
    },

    openDetailUp: function (recipeId, data) {
      if (data.clinical_historie_type == 6) {
        this.mtdRecipeDetail(recipeId);
        this.view = "detailOpe";
      } else if (
        data.clinical_historie_type == 1
      ) {
        this.mtdRecipeDetail(recipeId);
        this.view = "detailCon";
      } else if (data.clinical_historie_type == 3) {
        this.mtdRecipeDetail(recipeId);
        this.view = "detailHos";
      } else if (data.clinical_historie_type == 4) {
        this.mtdRecipeDetail(recipeId);
        this.view = "uciAdult";
      } else if (data.clinical_historie_type == 7) {
        this.mtdRecipeDetail(recipeId);
        this.view = "emergencia";
      } 
    },
     /*Fin Recargado*/

    mtdCloseModal: function () {
      this.modalDeta = {
        title: "Detalles de Despacho",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    viewDetail(item) {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/viewDetail",
        params: { id: item.id },
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.modalDeta.item = response.data;
            this.modalDeta.boo_modal = true;
          } else {
            this.modalDeta.boo_modal = false;
            this.$refs.SweetAlert.showWarning("Sin Detalles de Receta");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    viewRecomend(indicaciones) {
      if (indicaciones && indicaciones.trim()) {
        this.modalRe.title = "Recomendación";
        this.modalRe.item = indicaciones.charAt(0).toUpperCase() + indicaciones.slice(1);
        this.modalRe.boo_modal = true;
      } else {
        this.modalRe.boo_modal = false;
        this.$refs.SweetAlert.showWarning("No existe Recomendación");
      }
    },

    mtdCloseModalRe() {
      this.modalRe = {
        title: "",
        item: null,
        boo_modal: false,
      };
    },

    mtdBackCar: function() {
      this.carrito.data = [];
      this.view = "data";
    },

    mtdSendCarritoCli: function (carrito, type) {
      this.carrito.data = carrito;
      this.carrito.patient_id = this.acto.patient_id;
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/add/carritoCli",
        token: this.$store.getters.get__token,
        params: this.carrito,
      })
        .then((response) => {
          if (response.data.state == 0) {
            this.$refs.SweetAlert.showSuccess("Registro Exitoso");
            this.mtdBack(1);
            this.$emit("updateData" , this.acto, this.emit);
          } else {
            this.$refs.SweetAlert.showError("Registro Incorrecto");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },
    //Agregar Medicamentos
    mtdAddMedic: function () {
        this.carrito.recipeId = this.recipe.length > 0 ? this.recipe[0].id : null;
        this.view = "carrito";
    },

    openDestinoo: function () {
        this.modalDestino.item = this.actoHisto;
        this.modalDestino.boo_modal = true;
    },

    openDestino: function () {
        if (this.actoHisto.length === 1) {
            this.addReci(this.actoHisto[0].id);
        } else {
            this.modalDestino.item = this.actoHisto;
            this.modalDestino.boo_modal = true;
        }
    },

    addReci: function(actoHistoId) {
      const actoHisto = this.actoHisto.find(acto => acto.id === actoHistoId);
      const type = actoHisto.valor;
      const hc = actoHisto.id;
      //const filteredRecipe = this.recipe.filter(recipe => recipe.type === type);
      const filteredRecipe = this.recipe.filter(recipe => 
        recipe.type === type && recipe.clinical_historie_id === hc
      );
      this.carrito.recipeId = filteredRecipe.length > 0 ? filteredRecipe[0].id : null;
      this.carrito.type = type;
      this.carrito.histo_id = actoHistoId;
      this.carrito.medical_act_id = actoHisto.medical_act_id;
      this.carrito.doctor_id = actoHisto.doctor_id;
      this.view = "carrito";
    },
    
    mtdCloseModalDestino: function () {
      this.modalDestino = {
        title: "Agregar Tipo",
        item: [],
        boo_modal: false,
      };
    },
  },
  filters: {
      formatDate(date) {
          return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatDateTime(dateTime) {
          return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
      },
      formatType(type) {
          const types = {
              1: 'CONSULTAS',
              2: 'INTERCONSULTA',
              3: 'HOSPITALIZACIÓN',
              4: 'UCI ADULT.',
              5: 'UCINEO',
              6: 'SOP Y URPA',
              7: 'EMERGENCIA'
          };
          return types[type] || '-';
      }
  },
};
</script>
<style scoped>
@media (max-width: 992px) {
  .flex-row {
    flex-direction: column !important;
    align-items: center;
    padding-top: 20px !important;
  }

  #div-listcola {
    width: 300px !important;
    margin-bottom: 20px !important;
  }
}
.bootom {
  background-color: #900052 !important; 
  border: 0.5px solid #900052 !important;
}
.font-size-cabezera {
  font-size: 12px;
}
.carMedic {
  background-color: #ffffff !important; 
  border: 2px solid #ffffff !important;
  color: #900052;
}
</style>

