<template>
<div style="height: 100%;">

    <!-- GERSON (11-06-24) -->
    <div class="py-3">
        <span class="background">
            <section id="myTabContentHijoHC" class="col-md-12 gallery mb-2">

                <article id="div1" class="card active-section" @click="toggleClass(1)">
                    <div id="div1-content" class="row p-2">

                        <div class="col-xl-4 col-lg-12">
                          <div class="panel-border my-1 text-xs">
                                <div class="row px-3 d-sm-flex ">
                                    <div class="col-sm-6 my-1">
                                        <div id="div-te" class="form-group row align-left">
                                            <label for="te" class="col-sm-5 color-main fw-bold ">G.Sanguíneo</label>
                                            <div class="col-xl-7 col-sm-12">
                                                <input type="text" v-model="clinica_history.grupo" class="form-control form-control-input form-control-sm" id="te" :disabled="showHistory">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 my-1">
                                        <div id="div-fi" class="form-group row align-left">
                                            <label for="fi" class="col-sm-5 color-main fw-bold">F.Sanguíneo</label>
                                            <div class="col-xl-7 col-sm-12">
                                                <input type="text" v-model="clinica_history.factor" class="form-control form-control-input form-control-sm" id="fi" :disabled="showHistory">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(0)">
                                <h6 class="fw-bold color-main d-inline-block">Antecedentes</h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[0].state && 'active']">
                            </div>
                            <div class="panel-border mb-0 pt-1 px-3 text-xs" v-if="titleDropdown[0].state">
                                <div class="row d-inline-flex">
                                    <div class="col-md-6 mt-1">
                                        <div id="div-dm" class="form-group row align-left">
                                            <input type="checkbox" id="switch-dm" v-model="background[0].state" :disabled="showHistory">
                                            <label for="switch-dm" class="col-sm-2 lbl"> </label>
                                            <label for="dm" class="col-sm-3 color-main fw-bold">DM</label>
                                            <div class="col-sm-7">
                                                <input type="text" v-model="background[0].value" class="form-control form-control-input form-control-sm" id="dm" :disabled="validationinputdm || showHistory">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-1">
                                        <div id="div-ar" class="form-group row align-left">
                                            <input type="checkbox" id="switch-ar" v-model="background[1].state" :disabled="showHistory">
                                            <label for="switch-ar" class="col-sm-2 lbl "></label>
                                            <label for="ar" class="col-sm-3 color-main fw-bold">AR</label>
                                            <div class="col-sm-7">
                                                <input type="text" v-model="background[1].value" class="form-control form-control-input form-control-sm" id="ar" :disabled="validationinputar || showHistory">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mt-1">
                                        <div id="div-hta" class="form-group row align-left  ">
                                            <input type="checkbox" id="switch-hta" v-model="background[2].state" :disabled="showHistory">
                                            <label for="switch-hta" class="col-sm-2 lbl "> </label>
                                            <label for="hta" class="col-sm-3 color-main fw-bold">HTA</label>
                                            <div class="col-sm-7">
                                                <input type="text" v-model="background[2].value" class="form-control form-control-input form-control-sm" id="hta" :disabled="validationinputhta || showHistory">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 mt-1">
                                        <div id="div-otro" class="form-group row align-left">
                                            <input type="checkbox" id="switch-otro" v-model="background[3].state" :disabled="showHistory">
                                            <label for="switch-otro" class="col-sm-2 lbl "></label>
                                            <label for="otro" class="col-sm-3 color-main fw-bold">Otro</label>
                                            <div class="col-sm-7">
                                                <input type="text" v-model="background[3].value" class="form-control form-control-input form-control-sm" id="otro" :disabled="validationinputotro || showHistory">
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-md-12 mt-1">
                                        <div id="div-otro" class="form-group row align-left">
                                            <input type="checkbox" id="switch-otro" v-model="background[3].state" :disabled="showHistory">
                                            <label for="switch-otro" class="col-sm-2 lbl "> </label>
                                            <label for="otro" class="col-sm-6 color-main fw-bold">Otro</label>
                                            <div class="col-sm-12 mt-1 mb-1">
                                                <textarea v-model="background[3].value" class="form-control form-control-sm" id="otro" rows="3" style="resize: none;" :disabled="validationinputalergias || showHistory" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-1">
                                        <div id="div-alergia" class="form-group row align-left">
                                            <input type="checkbox" id="switch-alergia" v-model="background[4].state" :disabled="showHistory">
                                            <label for="switch-alergia" class="col-sm-2 lbl "> </label>
                                            <label for="alergia" class="col-sm-6 color-main fw-bold">Alergias</label>
                                            <div class="col-sm-12 mt-1 mb-1">
                                                <textarea v-model="background[4].value" class="form-control form-control-sm" id="alergia" rows="3" style="resize: none;" :disabled="validationinputalergias || showHistory" />
                                            </div>
                                        </div>
                                    </div>

                      <div class="col-md-12 mt-1">
                        <div id="div-cirugia" class="form-group row align-left">
                          <input type="checkbox" id="switch-cirugia" v-model="background[5].state" :disabled="showHistory">
                          <label for="switch-cirugia" class="col-sm-2 lbl "> </label>
                          <label for="cirugia" class="col-sm-6 color-main fw-bold">Cirugías</label>
                          <div class="col-sm-12 mt-1 mb-1">
                            <textarea v-model="background[5].value" class="form-control form-control-sm" id="cirugia" rows="3"
                              style="resize: none;" :disabled="validationinputcirugias || showHistory" />
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 mt-1" id="content-timelinetable">
                        <div id="div-medicamento" class="form-group row align-left">
                          <input type="checkbox" id="switch-medicamento" v-model="background[6].state" :disabled="showHistory">
                          <label for="switch-medicamento" class="col-sm-2 lbl "> </label>
                          <label for="medicamento" class="col-sm-10 color-main fw-bold">Medicamentos Frecuentes</label>
                          <div class="col-sm-12 mt-1 mb-2">
                            <textarea v-model="background[6].value" class="form-control form-control-sm" id="medicamento" rows="3"
                              style="resize: none;" :disabled="validationinputmedicamentos || showHistory" />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="col-xl-3 col-lg-12 text-xs">
                  <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(2)">
                    <h6 class="fw-bold color-main d-inline-block">Signos / Síntomas</h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                      class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[2].state && 'active']">
                  </div>
                  <div class="panel-border mb-1" v-if="titleDropdown[2].state">
                    <div id="content-timelinetable" class="d-sm-flex d-lg-block my-1">
                      <div v-for="(item, index) in checklist" :key="index"
                        class="custom-control custom-checkbox color-main align-left px-2">
                        <input type="checkbox" class="custom-control-input visually-hidden" :id="'checkbox' + index"
                          v-model="item.state" :disabled="showHistory">
                        <label class="custom-control-label fw-bold" :for="'checkbox' + index">
                          <span class="checkmark" :style="{ backgroundColor: item.state ? '#900052' : 'transparent' }"></span>
                          {{ item.label }}
                        </label>
                      </div>
                    </div>

                    <div v-if="checklist[8].state == true">

                      <div class="row mb-1">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                          <textarea class="form-control" v-model="checklist[8].value" rows="2" placeholder="" style="resize: none;" />
                        </div>
                        <div class="col-md-1"></div>
                      </div>
                    </div>
                  </div>

                  <div class="panel-border my-1">
                    <div class="row px-3 d-sm-flex ">
                      <div class="col-sm-12 my-1">
                        <div id="div-te" class="form-group row align-left">
                          <label for="te" class="col-sm-4 color-main fw-bold ">T.E.</label>
                          <div class="col-xl-8 col-sm-12">
                            <input type="text" v-model="clinica_history.fe" class="form-control form-control-input form-control-sm"
                              id="te" :disabled="showHistory">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 my-1">
                        <div id="div-fi" class="form-group row align-left">
                          <label for="fi" class="col-sm-4 color-main fw-bold">F.I.</label>
                          <div class="col-xl-8 col-sm-12">
                            <input type="text" v-model="clinica_history.fi" class="form-control form-control-input form-control-sm"
                              id="fi" :disabled="showHistory">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 my-1">
                        <div id="div-curso" class="form-group row align-left">
                          <label for="curso" class="col-sm-4 color-main fw-bold">Curso</label>
                          <div class="col-xl-8 col-sm-12">
                            <input type="text" v-model="clinica_history.course"
                              class="form-control form-control-input form-control-sm" id="curso" :disabled="showHistory">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel-border my-1">
                    <div class="row px-3 d-sm-flex ">
                      <div class="col-sm-12 my-1">
                        <div id="div-te" class="form-group row align-left">
                          <label for="antecedente_fam" class="col-sm-6 color-main fw-bold">Antecedentes Familiares</label>
                          <div class="col-sm-12 mt-1 mb-1">
                              <textarea v-model="clinica_history.background_parent" class="form-control form-control-sm" id="antecedente_fam" :rows="3" style="resize: none;" :disabled="showHistory" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-xl-2 col-lg-12 text-xs">
                  <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(3)">
                    <h6 class="fw-bold color-main d-inline-block">Signos Vitales</h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                      class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[3].state && 'active']">
                  </div>
                  <div class="panel-border mb-1" v-if="titleDropdown[3].state">
                    <div class="row px-3">
                      <div class="col-md-12 mt-1 align-left" v-for="(item2, index) in signosVitales" :key="index">
                        <div id="div-estado" class="form-group row">
                          <label :for="'input2' + index" class="col-sm-5 color-main fw-bold">{{ item2.label }}</label>
                          <div class="col-xl-7 col-sm-12">
                            <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm"
                              :id="'input2' + index" :disabled="showHistory">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(8)">
                    <h6 class="fw-bold color-main d-inline-block">Función Biológica</h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                      class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[8].state && 'active']">
                  </div>
                  <div class="panel-border mb-1" v-if="titleDropdown[8].state">
                    <div class="row px-3">
                      <div class="col-md-12 mt-1 align-left" v-for="(item2, index) in clinica_history.biological_function" :key="index">
                        <div id="div-estado" class="form-group row">
                          <label :for="'input2' + index" class="col-sm-5 color-main fw-bold">{{ item2.label }}</label>
                          <div class="col-xl-7 col-sm-12">
                            <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm"
                              :id="'input2' + index" :disabled="showHistory">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-12 text-xs">
                  <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(9)">
                    <h6 class="fw-bold color-main d-inline-block">Examen Regional</h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                      class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[9].state && 'active']">
                  </div>
                  <div class="panel-border mb-1" v-if="titleDropdown[9].state">
                    <div class="row px-3">
                      <div class="col-md-12 mt-1 align-left" v-for="(item3, index) in regional_exam" :key="index">
                        <div id="div-estado" class="form-group row">
                          <!-- <label :for="'input3' + index" class="col-sm-5 color-main fw-bold">{{ item3.label }}</label>
                          <div class="col-xl-7 col-sm-12">
                            <input type="text" v-model="item3.value" class="form-control form-control-input form-control-sm"
                              :id="'input3' + index" :disabled="showHistory">
                          </div> -->
                          <label :for="item3.label" class="col-sm-6 color-main fw-bold">{{ item3.label }}</label>
                          <div class="col-sm-12 mt-1 mb-1">
                              <textarea v-model="item3.value" class="form-control form-control-sm" :id="item3.label" :rows="item3.rows" style="resize: none;" :disabled="showHistory" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </article>

            <article id="div2" class="card" @click="toggleClass(2)">
              <div id="div2-content" class="row p-2">

                <div class="col-xl-4 col-lg-12">
                  <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(6)">
                    <h6 class="fw-bold color-main d-inline-block">Relato</h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                      class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[6].state && 'active']">
                  </div>
                  <div class="panel-border mb-0 pt-1" v-if="titleDropdown[6].state">
                    <div class="row px-3">
                      <div class="col-md-12 my-1">
                        <textarea id="relato" v-model="clinica_history.story" class="form-control form-control-sm" rows="8"
                          placeholder="" style="resize: none;" :disabled="showHistory" />
                      </div>
                    </div>
                  </div>

                  <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(7)">
                    <h6 class="fw-bold color-main d-inline-block">Otros examenes</h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                      class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[7].state && 'active']">
                  </div>
                  <div class="panel-border mb-0 pt-1" v-if="titleDropdown[7].state">
                    <div class="row px-3">
                      <div class="col-md-12 my-1">
                        <textarea id="otros" v-model="clinica_history.another_file" class="form-control form-control-sm" rows="3"
                          placeholder="" style="resize: none;" :disabled="showHistory" />
                      </div>
                    </div>
                  </div>

                  <div class="panel-border my-1">
                    <div class="row px-3 d-sm-flex ">
                      <div class="col-md-12 mt-1 text-xs">
                        <div id="div-otro" class="form-group row align-left">
                          <label for="otro" class="col-sm-5 color-main fw-bold">S/S Pregunta Lab. Emg</label>
                          <div class="col-sm-7 mb-2">
                            <input type="text" class="form-control form-control-input form-control-sm" id="otro"
                              v-model="clinica_history.lab_question" :disabled="showHistory">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-8 col-lg-12">
                  <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(1)">
                    <h6 class="fw-bold color-main d-inline-block">Examen Físico</h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                      class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[1].state && 'active']">
                  </div>
                  <div class="panel-border mb-0 pt-1 px-3 text-xs" v-if="titleDropdown[1].state">
                    <div class="row px-3" v-for="(itemE, indexE) in physicalExam" :key="indexE">
                      <div class="col-md-12 mt-1 align-left">
                        <div id="div-estado" class="form-group row">
                          <label :for="itemE.label" class="col-sm-3 color-main fw-bold">{{ itemE.label }}</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control form-control-input form-control-sm" v-model="itemE.value"
                              :id="itemE.label" :disabled="showHistory">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(5)">
                    <h6 class="fw-bold color-main d-inline-block">Diagnosticos</h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                      class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[5].state && 'active']">
                  </div>
                  <div class="panel-border mb-0 pt-1" v-if="titleDropdown[5].state">
                    <div class="row my-1 px-3">
                      <div class="col-md-12">
                        <table class="w-100  ">
                          <caption class="div-text"></caption>
                          <thead class=" text-xs">
                            <tr>
                              <th  colspan="4">Selecione Diagnostico :</th>
                            </tr>
                          </thead>
                          <tbody class="div-text">
                            <tr  class="px-1">
                              <td >
                                <input  v-model="inputchecked"  class="form-check-input me-1" type="checkbox"
                                  id="flexCheckDefault"   @click="openmodaldx" :disabled="showHistory" >
                                <label class="form-check-label" for="flexCheckDefault">
                                  Otros
                                </label>
                              </td>
                              <td class="ps-2" >
                                <v-select
                                  :options="dataCies"
                                  v-model="diagnostico"
                                  :reduce="(cie) => cie"
                                  label="name"
                                  placeholder="- Buscar Cie -"
                                  required
                                  style="width: 433px"
                                  :clearable="false"
                                  @search="mtdgetCies"
                                  @input="mtdaddDiagnosticoUnit"
                                  :disabled="showHistory" 
                                >
                                  <template slot="no-options">
                                    No hay diagnosticos para la busqueda.
                                  </template>
                                </v-select>
                              </td>
                              <td>
                                <input type="text" v-model="diagnostics_unit.codigo" class="form-control  form-control-input form-control-sm" placeholder="CIE10" :disabled="showHistory"  readonly/>
                              </td>
                              <td>
                                <input type="text"  v-model="diagnostics_unit.type" class="form-control form-control-input form-control-sm" placeholder="TIPO" :disabled="showHistory"  readonly />
                              </td>
                              <td>
                                <button
                                  type="button"
                                  @click="mtdaddDiagnostico"
                                  :disabled="disabledx"
                                  class="btn btn-success btn-sm px-1 py-0"
                                >
                              <i class="fas fa-plus text-white"></i>
                            </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="w-100  ">
                          <caption class="div-text"></caption>
                          <thead class="div-text div-title">
                            <tr>
                              <th class="color-main"> N°</th>
                              <th class="color-main" >Dx</th>
                              <th class="color-main" >CIE10</th>
                              <th class="color-main">TIPO</th>
                            </tr>
                          </thead>
                          <tbody v-if="clinica_history.diagnostics.length" class="div-text">
                            <tr
                          v-for="(item1, index) in clinica_history.diagnostics"
                          :key="index"
                          class="px-1"
                        >
                          <td>
                            {{ index + 1 }}
                          </td>
                          <td>
                            {{ item1.name }}
                          </td>
                          {{
                            item1.codigo
                          }}
                          <td>
                            {{ item1.type }}
                          </td>
                          <td></td>
                        </tr>
                          </tbody>
                          <tbody v-else class="div-text">
                                  <tr> 
                                      <td colspan="4" class="text-center">No hay diagnosticos</td>
                                  </tr>
                              </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </article>

            <article id="div3" class="card" @click="toggleClass(3)">
              <div id="div3-content" class="row p-2">

                <div class="col-xl-8 col-lg-12">
                  <div class="panel-border my-1">
                    <div class="row my-1 px-3">
                      <div class="col-md-12" id="content-timelinetable">
                        <table class=" mb-2">
                          <caption class="div-text py-0 my-0"></caption>
                          <thead class="">
                            <tr>
                              <th colspan="7" scope="col" class="text-xs">Seleccione Medicamentos:</th>
                            </tr>
                          </thead>
                          <tbody class="div-text ">
                            <tr class="px-1">
                              <td colspan="2" class="w-25 z-index-select">
                                <input type="text" v-model="receta" placeholder="Molecula" class="form-control form-control-input form-control-sm" :disabled="showHistory" @keyup.enter="mtdopenmodal" />
                                <!-- <v-select :options="productdetails" :reduce="(product) => product" label="molecule" :clearable="false"
                                  placeholder="molecula" @search="mtdgetMedicamentos" @input="addreceta" required v-model="selectedProduct"  :disabled="showHistory"/> -->
                              </td>
                              <td v-if = "recipe_unit.presentation != '-'">
                                <input type="text" v-model="recipe_unit.presentation"
                                  class="form-control form-control-input form-control-sm" placeholder="Presentación" disabled/>
                              </td>
                              <td v-if = "!type">
                                <input type="text" v-model="recipe_unit.quantity"
                                  class="form-control form-control-input form-control-sm" placeholder="Concentración" disabled/>
                              </td>
                              <td  v-if = "!type || type_name == 3">
                                <input type="text" v-model="recipe_unit.via"
                                  class="form-control form-control-input form-control-sm" placeholder="Via"  :disabled="showHistory"/>
                              </td>
                              <!-- <td  v-if = "!type">
                                <input  type="text" v-model="recipe_unit.quantity"
                                  class="form-control form-control-input form-control-sm" placeholder="Frecuencia"  :disabled="showHistory"/>
                              </td> -->
                              <td v-if="!type">
                                  <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.frequency" placeholder="Frecuencia" class="form-control form-control-input form-control-sm" :disabled="showHistory" />
                              </td>
                              <td  v-if = "!type">
                                <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.duration"
                                  class="form-control form-control-input form-control-sm" placeholder="Frecuencia" :disabled="showHistory" />
                              </td>
                              <td v-if = "type">
                                <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.total" placeholder="Total" class="form-control form-control-input form-control-sm" :disabled="showHistory" />
                              </td>
                              <td v-if = "!type">
                                <button type="button" :disabled="validationInputrecipe ||showHistory" @click="mtdaddrecipe()"
                                  class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                              </td>
                              <td v-else  style=" width: 50px;" >    
                                  <button :disabled="validationtotal" type="button"  @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div v-if="!showHistory" class="d-flex justify-content-end mb-2">
                            <button @click="mtdViewMezclas"  type="button" class="btn  btn-info btn-sm px-4 py-0 text-xs text-white" :disabled="noInsumos.length <  2" >Mezclas</button>
                        </div>

                        <table class="w-100">
                          <caption class="div-text"></caption>
                          <thead class="div-text div-title text-center">
                            <tr>
                                  <th class="encabezado-rounded" scope="col">Rp.</th>
                                  <th class="encabezado-rounded w-25">Medicamentos</th>
                                  <th class="encabezado-rounded">Presentacion</th>
                                  <th class="encabezado-rounded">Concentración</th>
                                  <th class="encabezado-rounded">Via</th>
                                  <th class="encabezado-rounded">Dosis de Mezcla</th>
                                  <th class="encabezado-rounded">Frecuencia</th>
                                  <th class="encabezado-rounded">Duracion</th>
                                  <th class="encabezado-rounded">Tipo</th>
                                  <th class="encabezado-rounded">Grupo</th>
                                  <th class="encabezado-rounded">PRN</th>
                                  <th class="encabezado-rounded">Acciones</th>
                              </tr>
                          </thead>
                          <tbody class="div-text " v-if="recipe.length>0">
                            <tr v-for="(recipeunit, index) in recipe" :key="index" class="px-1 text-center">
                              <td>
                                <span>{{ index + 1 }}</span>
                              </td>
                              <td>
                                <span>{{ recipeunit.molecule }}</span>
                              </td>
                              <td>
                                <span>{{ recipeunit.presentation }}</span>
                              </td>
                              <td>
                                <span>{{recipeunit.group != 0  ?  recipeunit.dosis  : recipeunit.quantity }}</span>
                              </td>
                              <td>
                                <span>{{ recipeunit.via }}</span>
                              </td>
                              <td>
                                <span>{{ recipeunit.group == 0  ? (recipeunit.type == 1 ? 'SIN DOSIS': '-') : recipeunit.quantity}}</span>
                              </td>
                              <td>
                                <span>{{ recipeunit.frequency }}</span>
                              </td>
                              <td>
                                <span>{{ recipeunit.duration }}</span>
                              </td>
                              <td>
                                <span>{{ recipeunit.type == 2 ? 'INSUMO' : recipeunit.type == 3 ? 'SOLUCION': 'MEDICAMENTO' }}</span>
                              </td>
                              <td >
                                  <span>{{ recipeunit.group == 0 ? (recipeunit.type == 1 ? 'SIN GRUPO': '-') : 'GRUPO ' + recipeunit.group  }}</span>
                              </td>
                              <td>
                                <span v-if="recipeunit.type == 1 ">
                                  <div class="form-check form-switch">
                                    <input @input="mtdPrnGroup(recipeunit)" :disabled="showHistory" v-model="recipeunit.prn" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                    <label class="form-check-label" for="flexSwitchCheckDefault">{{ recipeunit.prn ? 'SI':'NO' }}</label>
                                  </div>
                                </span>
                                <span v-else>-</span>
                              </td>
                              <td>
                                <button type="button" @click="mtddelete(index, 'mtddeleterecipe')"
                                  class=" btn btn-danger btn-sm px-1 py-0 " :disabled="showHistory"> <i class="fas fa-trash-alt text-white"></i> </button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody class=" text-center" v-else>
                              <tr>
                                  <td colspan="12" class=" text-center">
                                      <span class="fw-bold text-xxs"> No hay resultados</span>
                                  </td>
                              </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div v-if="insuline_state" class="panel-border my-1">
                    <div class="row px-3">
                      <div class="col-md-12 my-1">
                        <div id="div-recomendacion" class="form-group row align-left">
                          <label for="recomendacion" class="col-sm-3 color-main fw-bold"> Insulina</label>
                          <div class="col-xl-9 col-sm-12">
                            <textarea id="recomendacion" class="form-control form-control-sm" rows="4"
                              v-model="clinica_history.insuline" style="resize: none;" :disabled="showHistory" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>
                  </div>
                  <div class="panel-border my-1">
                    <div class="row px-3">
                      <div class="col-md-12 my-1">
                        <div id="div-recomendacion" class="form-group row align-left">
                          <label for="recomendacion" class="col-sm-3 color-main fw-bold"> Indicaciones y/o Recomendaciones</label>
                          <div class="col-xl-9 col-sm-12">
                            <textarea id="recomendacion" class="form-control form-control-sm" rows="3"
                              v-model="clinica_history.recomendations" style="resize: none;" :disabled="showHistory" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>
                  </div>
                  <div class="panel-border my-1">
                    <div class="row px-3">
                      <div class="col-md-12 my-1">
                        <div id="div-recomendacion" class="form-group row align-left">
                          <label for="recomendacion" class="col-sm-3 color-main fw-bold">Dieta</label>
                          <div class="col-xl-9 col-sm-12">
                            <textarea id="recomendacion" class="form-control form-control-sm" rows="3"
                              v-model="clinica_history.dieta" style="resize: none;" :disabled="showHistory" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-12">
                  <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(4)">
                    <h6 class="fw-bold color-main d-inline-block">Plan</h6>
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
                      class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[4].state && 'active']">
                  </div>
                  <div class="panel-border mb-0 pt-1" v-if="titleDropdown[4].state">
                    <div class="row mx-0 px-3">
                      <div class="col-md-12 py-2">
                        <div class="input-group align-items-center">
                          <span class="color-main fw-bold">PLAN</span>
                        </div>
                      </div>
                      <div class="col-xl-12 col-sm-12 py-2 ">
                        <div class=" flex-input  d-sm-flex row input-group input-group mx-0 mb-2">
                          <span class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                              class="fas fa-search"></i></span>
                          <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" :options="dataLaboratorio"
                            :reduce="(laboratorio) => laboratorio" label="name" placeholder="Laboratorio" required
                            @input="mtdaddPlan" v-model="plan_unit" :disabled="showHistory" />
                        </div>
                      </div>
                      <div class="col-xl-12 col-sm-12 py-2  ">
                        <div class="flex-input  d-sm-flex row input-group input-group mx-0 mb-2">
                          <span class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                              class="fas fa-search"></i></span>
                          <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" :options="dataImagenes"
                            :reduce="(Imagenes) => Imagenes" label="name" placeholder="Imagenes" required @input="mtdaddPlan"
                            v-model="plan_unit" :disabled="showHistory"  />
                        </div>
                      </div>
                      <div class="col-xl-12 col-sm-12 py-2  ">
                        <div class="flex-input d-sm-flex row input-group input-group mx-0 mb-2">
                          <span class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                              class="fas fa-search"></i></span>
                          <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" :options="dataProcedimiento"
                            :reduce="(Procedimiento) => Procedimiento" label="name" placeholder="Procedimiento" required
                            @input="mtdaddPlan" v-model="plan_unit" :disabled="showHistory" />
                        </div>
                      </div>
                      <div class="col-xl-12 col-sm-12 py-2  ">
                        <div class="flex-input d-sm-flex row input-group input-group mx-0 mb-2">
                          <span class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                              class="fas fa-search"></i></span>
                          <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" :options="dataInterconsultas"
                            :reduce="(Interconsulta) => Interconsulta" label="name" placeholder="Interconsulta" required
                            @input="mtdaddPlan" v-model="plan_unit" :disabled="showHistory" />
                        </div>
                      </div>
                    </div>
                    <div class="row mx-0 px-3">
                      <div v-for="(planunit, index) in plan " :key="index" class="col-md-12 py-1  ">
                        <div 
                          class="input-group input-group btn-home px-0 div-pointer text-white fw-bold border bg-main h-100 border-2 align-middle  ">
                          <button @click="mtddelete(index, 'mtddeleteplan')" type=" button" class="w-100 text-start" :disabled="showHistory">{{ planunit.name }} </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </article>
          </section>
        </span>
      </div>
      <!--  -->
      <Medicine :posmedicine="posmedicine" :receta="receta" :title="modalmedicine.title" :boo_modal="modalmedicine.modal_form" @mtdclosemodalmedicine="mtdclosemodalmedicine" @addreceta="addreceta" />
      <cModaldiagnosticoVue
          :title="modaldx.title"
          :boo_modal="modaldx.modal_form"
          @mtdclosemodaldx="mtdclosemodaldx"
          @mtdcie="mtdcie"
        />
      <SweetAlert :swal="swal" ref="SweetAlert" @mtddeleteplan="mtddeleteplan" @mtddeleterecipe="mtddeleterecipe" />
      <cModaldestino :title="modal.title" :boo_modal="modal.modal_form" @mtdclosemodal="mtdclosemodal"
        @mtdstoredata="mtdstoredata" />
        <cModalcomment @mtdaddComment="mtdaddComment" :plans="plans" :title="modalcomment.title" :boo_modal="modalcomment.modal_form" @mtdclosemodalcomment="mtdclosemodalcomment" @mtdcie="mtdcie" />
        <Mezclas @mtdchange="mtdchange" :medicinemezclas="medicinemezclas" :title="modalmezcla.title" :boo_modal="modalmezcla.modal_form" @mtdclosemodalmezclas="mtdclosemodalmezclas"/>
    </div>
  
</template>

<style scoped>
.background {
    background-color: #ffffff;
    display: grid;
    place-items: center;
    min-height: 260px;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    width: 100%;
}

.card {
    position: relative;
    left: 0px;
    width: 50px;
    border-radius: 16px;
    height: 560px;
    /* height: 100%; */
    overflow: hidden;
    transition: 0.4s ease-in-out;
    /* background-color: #900052; */
    color: #ffffff;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
    flex: 0.18;
}

.card:hover {
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
}

.active-section {
    flex: 4;
    background-color: #ffffff !important;
    cursor: auto !important;
    border-radius: 16px !important;
    color: #000000;
}

.orden1 {
    background-image: url('../../../../assets/orden_01.jpg');
    background-position: center 10%;
    background-repeat: no-repeat;
    background-size: cover;
}

.orden2 {
    background-image: url('../../../../assets/orden_02.jpg');
    background-position: center 10%;
    background-repeat: no-repeat;
    background-size: cover;
}

.orden3 {
    background-image: url('../../../../assets/orden_03.jpg');
    background-position: center 10%;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModaldestino from '../modals/cModaldestino.vue';
import cModaldiagnosticoVue from "../modals/cModaldiagnostico.vue";
import Medicine from '@/components/Asistencial/UciAdult/modals/cModalMedicine.vue';
import cModalcomment from '../modals/cModalcommet.vue';
import Mezclas from '@/components/Asistencial/UciAdult/modals/cModalMezclas.vue';
export default {
    name: 'c-asistencial-uciadulto-historia-hcdehospitalizaciondesktop',
    components: {
        SweetAlert,
        cModaldestino,
        cModaldiagnosticoVue,
        Medicine,
        cModalcomment,
        Mezclas
    },
    data() {
        return {
            productdetails: [],
            dataCies: [],
            diagnostico: null,
            swal: null,
            checklist: [{
                    id: 1,
                    label: "Tos sin flema",
                    state: false
                },
                {
                    id: 2,
                    label: "Tos con flema",
                    state: false
                },
                {
                    id: 3,
                    label: "Moco",
                    state: false
                },
                {
                    id: 4,
                    label: "Dolor de pecho",
                    state: false
                },
                {
                    id: 5,
                    label: "Dolor de cabeza",
                    state: false
                },
                {
                    id: 6,
                    label: "Dolor de espalda",
                    state: false
                },
                {
                    id: 7,
                    label: "Nauseas / vómitos x/día",
                    state: false
                },
                {
                    id: 8,
                    label: "Diarrea x/día",
                    state: false
                },
                {
                    id: 9,
                    label: "Otros",
                    state: false,
                    value: ''
                }
            ],
            signosVitales: [{
                    id: 1,
                    label: "FC",
                    value: ''
                },
                {
                    id: 2,
                    label: "T (°C)",
                    value: ''
                },
                {
                    id: 3,
                    label: "FR",
                    value: ''
                },
                {
                    id: 4,
                    label: "SATO2",
                    value: ''
                },
                {
                    id: 5,
                    label: "HGT",
                    value: ''
                },
                {
                    id: 6,
                    label: "FIO 2",
                    value: ''
                },
                {
                    id: 7,
                    label: "CAS",
                    value: ''
                },
                {
                    id: 8,
                    label: "PAS",
                    value: ''
                },
                {
                    id: 9,
                    label: "PAD",
                    value: ''
                }
            ],
            physicalExam: [{
                    id: 1,
                    label: "Estado general",
                    value: ''
                },
                {
                    id: 2,
                    label: "SNC",
                    value: ''
                },
                {
                    id: 3,
                    label: "Respiratorio",
                    value: ''
                },
                {
                    id: 4,
                    label: "Tórax Cv Hemo",
                    value: ''
                },
                {
                    id: 5,
                    label: "Abdominal",
                    value: ''
                },
                {
                    id: 6,
                    label: "Renal",
                    value: ''
                },
                {
                    id: 7,
                    label: "Otros",
                    value: ''
                }
            ],

            inputchecked: false,
            clinica_history: {
                id: null,
                user_id: null,
                biological_function: [{
                        id: 1,
                        label: "Apetito",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "Sed",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "Orina",
                        value: ''
                    },
                    {
                        id: 4,
                        label: "Heces",
                        value: ''
                    },
                    {
                        id: 5,
                        label: "Sueño",
                        value: ''
                    },

                ],
                physical_exam: null,
                lab_question: '',
                symploms: null,
                vital_sings: null,
                fe: '',
                fi: '',
                course: '',
                background_parent: '',
                story: '',
                another_file: '',
                diagnostics: [],
                plan: [],
                recipe: [],
                recomendations: '',
                physical_exam_text: '',
                background: null,
                patient_id: null,
                model_id: null,
                medical_act_id: null,
                dieta: '',
                insuline: '',
                grupo:'',
                factor:''
            },
            clinica_history_plantilla: {
                id: null,
                user_id: null,
                biological_function: [{
                        id: 1,
                        label: "Apetito",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "Sed",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "Orina",
                        value: ''
                    },
                    {
                        id: 4,
                        label: "Heces",
                        value: ''
                    },
                    {
                        id: 5,
                        label: "Sueño",
                        value: ''
                    },

                ],
                physical_exam: null,
                lab_question: '',
                symploms: null,
                vital_sings: null,
                fe: '',
                fi: '',
                course: '',
                background_parent: '',
                story: '',
                another_file: '',
                diagnostics: [],
                plan: [],
                recipe: [],
                recomendations: '',
                physical_exam_text: '',
                background: null,
                patient_id: null,
                model_id: null,
                medical_act_id: null,
                dieta: '',
                insuline: '',
                grupo:'',
                factor:''
            },
            background: [{
                    id: 1,
                    label: "DM",
                    state: false,
                    value: ''
                },
                {
                    id: 2,
                    label: "AR",
                    state: false,
                    value: ''
                },
                {
                    id: 3,
                    label: "HTA",
                    state: false,
                    value: ''
                },
                {
                    id: 4,
                    label: "Otro",
                    state: false,
                    value: ''
                },
                {
                    id: 5,
                    label: "Alergias",
                    state: false,
                    value: ''
                },
                {
                    id: 6,
                    label: "Cirugías",
                    state: false,
                    value: ''
                },
                {
                    id: 7,
                    label: "Medicamentos Frecuentes",
                    state: false,
                    value: ''
                }
            ],
            selectedProduct: null,
            recipe_unit: {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null,
                quantity: '',
                group: 0,
                prn: false
            },
            titleDropdown: [{
                    title: 'Antecedentes',
                    state: true,
                }, 
                {
                    title: 'Examen Físico',
                    state: true,
                }, 
                {
                    title: 'Signos',
                    state: true,
                }, 
                {
                    title: 'Signos Vitales',
                    state: true,
                },
                {
                    title: 'Plan',
                    state: true,
                },
                {
                    title: 'DX',
                    state: true,
                }, 
                {
                    title: 'Relato',
                    state: true,
                }, 
                {
                    title: 'Otros Exámenes',
                    state: true,
                }, 
                {
                    title: 'Función Biológica',
                    state: true,
                }, 
                {
                    title: 'Examen Regional',
                    state: true,
                }
            ],
            regional_exam: [{
                    id: 1,
                    label: "Cabeza y Cuello",
                    value: '',
                    rows: 1
                },
                {
                    id: 2,
                    label: "Piel y Faneras",
                    value: '',
                    rows: 1
                },
                {
                    id: 3,
                    label: "TCSC",
                    value: '',
                    rows: 1
                },
                {
                    id: 4,
                    label: "Sist. Linfático",
                    value: '',
                    rows: 2
                },
                {
                    id: 5,
                    label: "Ap. Respiratorio",
                    value: '',
                    rows: 2
                },
                {
                    id: 6,
                    label: "Ap. Cardiovascular",
                    value: '',
                    rows: 2
                },
                {
                    id: 7,
                    label: "Ap. Digestivo",
                    value: '',
                    rows: 2
                },
                {
                    id: 8,
                    label: "Ap. Genitourinario",
                    value: '',
                    rows: 2
                },
                {
                    id: 9,
                    label: "Sist. Nervioso",
                    value: '',
                    rows: 2
                },
                {
                    id: 10,
                    label: "Ap. Locomotor",
                    value: '',
                    rows: 2
                }
            ],
            recipe: [],
            dataLaboratorio: [],
            dataImagenes: [],
            dataProcedimiento: [],
            dataInterconsultas: [],
            plan_unit: null,
            plan: [],
            modal: {
                title: "",
                modal_form: false,
            },
            diagnostics_unit: {
                id: "",
                codigo: "",
                name: "",
                cie: "",
                type: "",
            },
            modaldx: {
                title: "",
                modal_form: false,
            },
            modalmedicine: {
                title: "",
                modal_form: false,
            },
            posmedicine: null,
            receta: '',
            modalcomment: {
                title: "",
                modal_form: false,
            },
            plans: {},
            type: false,
            modalmezcla: {
                title: "",
                modal_form: false,
            },
            medicinemezclas: [],
            group_state: false,
            noInsumos: [],
            insuline_state: false,
            type_name : 0,
        }
    },
    created() {
        // this.mtdgetproductdetails();
        this.mtdgetSubsubcategories();
        this.mtdfilldata();

    },
    mounted() {
        this.mtdToggleClassInit();

    },
    watch: {
        metodoadd: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        }
    },

    computed: {
        validationInputrecipe: function () {
            let value = true
            if (this.receta && this.recipe_unit.frequency && this.recipe_unit.via && this.recipe_unit.duration) {
                value = false
            }
            return value
        },
        estadoChecklist: function () {
            const completadas = this.checklist.filter(item => item.checked).length;
            return `${completadas} de ${this.checklist.length} tareas completadas.`;
        },
        validationinputdm: function () {
            let value = true
            if (this.background[0].state) {
                value = false
            }
            return value

        },
        validationinputar: function () {
            let value = true
            if (this.background[1].state) {
                value = false
            }
            return value
        },
        validationinputhta: function () {
            let value = true
            if (this.background[2].state) {
                value = false
            }
            return value
        },
        validationinputotro: function () {
            let value = true
            if (this.background[3].state) {
                value = false
            }
            return value
        },
        validationinputalergias: function () {
            let value = true
            if (this.background[4].state) {
                value = false
            }
            return value
        },
        validationinputcirugias: function () {
            let value = true
            if (this.background[5].state) {
                value = false
            }
            return value
        },
        validationinputmedicamentos: function () {
            let value = true
            if (this.background[6].state) {
                value = false
            }
            return value
        },
        disabledx: function () {
            return !this.diagnostics_unit.name;
        },
        validationtotal: function () {
            let value = true
            if (this.recipe_unit.total) {
                value = false
            }
            return value
        }
    },
    props: {
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory: {
            type: Object,
            default: () => ({})
        },
        viewMedico: Number,
        metodoadd: {
            type: Number,
            default: 0
        },
        showHistory: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdstoredata: function () {
            this.clinica_history.user_id = this.$store.getters.get__user.id;
            this.clinica_history.patient_id = this.datapatients.patient_id
            this.clinica_history.model_id = this.datapatients.model_id
            this.clinica_history.background = this.background;
            this.clinica_history.symploms = this.checklist;
            this.clinica_history.vital_sings = this.signosVitales;
            this.clinica_history.regional_exam = this.regional_exam;
            this.clinica_history.recipe = this.recipe;
            this.clinica_history.physical_exam = this.physicalExam;
            this.clinica_history.plan = this.plan;
            this.clinica_history_plantilla.user_id = this.$store.getters.get__user.id;
            this.clinica_history_plantilla.patient_id = this.datapatients.patient_id
            this.clinica_history_plantilla.model_id = this.datapatients.model_id
            const areEqual = JSON.stringify(this.clinica_history) === JSON.stringify(this.clinica_history_plantilla);
            if (!areEqual) {
                this.post({
                        url: this.$store.getters.get__url + "/Uciadulto/store",
                        token: this.$store.getters.get__token,
                        params: this.clinica_history,
                    })
                    .then((response) => {
                        let newclinica_history = response.data;
                        this.clinica_history.id = newclinica_history.id;
                        this.background = JSON.parse(newclinica_history.background)
                        this.plan = JSON.parse(newclinica_history.plan);
                        this.recipe = JSON.parse(newclinica_history.recipe);
                        this.checklist = JSON.parse(newclinica_history.symploms);
                        this.signosVitales = JSON.parse(newclinica_history.vital_sings);
                        this.regional_exam = JSON.parse(newclinica_history.regional_exam);
                        this.physicalExam = JSON.parse(newclinica_history.physical_exam);
                        this.clinica_history.fe = newclinica_history.fe;
                        this.clinica_history.fi = newclinica_history.fi;
                        this.clinica_history.course = newclinica_history.course;
                        this.clinica_history.background_parent = newclinica_history.background_parent;
                        this.clinica_history.story = newclinica_history.story;
                        this.clinica_history.another_file = newclinica_history.another_file;
                        this.clinica_history.recomendations = newclinica_history.recomendations;
                        this.clinica_history.dieta = newclinica_history.dieta;
                        this.clinica_history.lab_question = newclinica_history.lab_question;
                        this.clinica_history.medical_act_id = newclinica_history.medical_act_id;
                        this.clinica_history.biological_function = JSON.parse(newclinica_history.biological_function);
                        this.clinica_history.insuline = newclinica_history.insulin;
                        this.clinica_history.grupo = newclinica_history.blood_group;
                        this.clinica_history.factor = newclinica_history.blood_factor;
                        this.insuline_state = false
                        this.recipe.forEach(item => {
                            if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                                this.insuline_state = true
                            }
                        })
                        this.$emit('mtdaddexit', 1, newclinica_history)
                        this.$refs.SweetAlert.showSuccess(response.message);
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });

            } else {
                this.$refs.SweetAlert.showWarning("Registre Historial Clinico");
                this.$emit('mtdaddexit', 2)
            }

        },

        mtdhiderecipeunit: function () {
            this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null,
                quantity: '',
                group: 0,
                prn: false
            }

            this.selectedProduct = null
            this.plan_unit = null
        },

        mtdaddrecipe: function () {
            const exists = this.recipe.some(item => item.molecule + ' ' + item.presentation + ' ' + item.dosis === this.recipe_unit.molecule + ' ' + this.recipe_unit.presentation + ' ' + this.recipe_unit.dosis);
            if (exists) {
                this.$refs.SweetAlert.showWarning("Medicamento ya agregado");
            } else {
                this.recipe.push(this.recipe_unit);
            }
            this.type = false
            this.receta = null
            this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null,
                quantity: '',
                group: 0,
                prn: false
            }
            this.noInsumos = []
            this.insuline_state = false
            this.recipe.forEach(item => {
                if (item.type == 1) {
                    this.noInsumos.push(item)
                }
                if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                    this.insuline_state = true
                }
            })

        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);

        },
        mtddeleterecipe: function (index) {
            let num = this.recipe[index].group
            if (this.recipe[index].group != 0) {
                for (let j = this.recipe.length - 1; j >= 0; j--) {
                    if (this.recipe[j].group == num) {
                        this.recipe.splice(j, 1);
                    }
                }
            } else {
                this.recipe.splice(index, 1);
            }
            this.insuline_state = false
            for (let i = 0; i < this.recipe.length; i++) {
                this.recipe[i].order = i + 1;
                if (this.recipe[i].type == 1 && this.recipe[i].molecule.toLowerCase().includes('insulina')) {
                    this.insuline_state = true
                }
            }
            if(!this.insuline_state){
              this.clinica_history.insuline = null
            }
        },
        mtddeleteplan: function (index) {
            this.plan.splice(index, 1);
        },
        toggleDropdown(title) {
            this.titleDropdown[title].state = !this.titleDropdown[title].state;
        },
        mtdgetSubsubcategories: function () {
            this.post({
                url: this.$store.getters.get__url + "/Consulting/subsubcategorie",
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.dataLaboratorio = response.data.dataLaboratorio;
                    this.dataImagenes = response.data.dataImagenes;
                    this.dataProcedimiento = response.data.dataProcedimiento;
                    this.dataInterconsultas = response.data.dataInterconsulta;
                }
            });
        },
        mtdaddPlan: function (laboratorioSeleccionado) {
            const exist = this.plan.some(item => item.subsubcategory_id === laboratorioSeleccionado.subsubcategory_id);

            if (exist) {
                this.$refs.SweetAlert.showWarning("El item seleccionado a sido agregado");
            } else {
                if (laboratorioSeleccionado.category_id == 1) {
                    this.modalcomment = {
                        title: this.plan_unit.name,
                        modal_form: true,
                    }
                    this.plan_unit = laboratorioSeleccionado
                    this.plan_unit.comment = ''
                    this.plan_unit.medical_act_id= this.clinica_history.medical_act_id
                    this.plans = this.plan_unit
                } else {
                    this.plan_unit = laboratorioSeleccionado
                    this.mtdaddComment(this.plan_unit)
                }
            }
            // this.plan.push(this.plan_unit);

            this.plan_unit = null
        },
        mtdaddComment: function (payload) {
            this.mtdclosemodalcomment()
            this.plan.push(Object.assign({}, payload));
        },
        mtdclosemodal: function () {
            this.modal = {
                title: "",
                modal_form: false,
            }
        },
        mtdfilldata: function () {
            if (Object.keys(this.Clinicahistory).length !== 0) {
                this.clinica_history.id = this.Clinicahistory.id;
                this.background = JSON.parse(this.Clinicahistory.background)
                this.plan = JSON.parse(this.Clinicahistory.plan);
                this.recipe = JSON.parse(this.Clinicahistory.recipe);
                this.checklist = JSON.parse(this.Clinicahistory.symploms);
                this.signosVitales = JSON.parse(this.Clinicahistory.vital_sings);
                this.regional_exam = JSON.parse(this.Clinicahistory.regional_exam);
                this.physicalExam = JSON.parse(this.Clinicahistory.physical_exam);
                this.clinica_history.fe = this.Clinicahistory.fe;
                this.clinica_history.fi = this.Clinicahistory.fi;
                this.clinica_history.course = this.Clinicahistory.course;
                this.clinica_history.background_parent = this.Clinicahistory.background_parent;
                this.clinica_history.story = this.Clinicahistory.story;
                this.clinica_history.another_file = this.Clinicahistory.another_file;
                this.clinica_history.recomendations = this.Clinicahistory.recomendations;
                this.clinica_history.dieta = this.Clinicahistory.dieta;
                this.clinica_history.lab_question = this.Clinicahistory.lab_question;
                this.clinica_history.medical_act_id = this.Clinicahistory.medical_act_id;
                this.clinica_history.biological_function = JSON.parse(this.Clinicahistory.biological_function);
                this.clinica_history.diagnostics = JSON.parse(this.Clinicahistory.diagnostics);
                this.clinica_history.insuline = this.Clinicahistory.insulin;
                this.clinica_history.grupo = this.Clinicahistory.blood_group;
                this.clinica_history.factor = this.Clinicahistory.blood_factor;

                this.clinica_history_plantilla.id = this.Clinicahistory.id;
                this.clinica_history_plantilla.background = JSON.parse(this.Clinicahistory.background)
                this.clinica_history_plantilla.plan = JSON.parse(this.Clinicahistory.plan);
                this.clinica_history_plantilla.recipe = JSON.parse(this.Clinicahistory.recipe);
                this.clinica_history_plantilla.symploms = JSON.parse(this.Clinicahistory.symploms);
                this.clinica_history_plantilla.vital_sings = JSON.parse(this.Clinicahistory.vital_sings);
                this.clinica_history_plantilla.regional_exam = JSON.parse(this.Clinicahistory.regional_exam);
                this.clinica_history_plantilla.physical_exam = JSON.parse(this.Clinicahistory.physical_exam);
                this.clinica_history_plantilla.fe = this.Clinicahistory.fe;
                this.clinica_history_plantilla.fi = this.Clinicahistory.fi;
                this.clinica_history_plantilla.course = this.Clinicahistory.course;
                this.clinica_history_plantilla.background_parent = this.Clinicahistory.background_parent;
                this.clinica_history_plantilla.story = this.Clinicahistory.story;
                this.clinica_history_plantilla.another_file = this.Clinicahistory.another_file;
                this.clinica_history_plantilla.recomendations = this.Clinicahistory.recomendations;
                this.clinica_history_plantilla.dieta = this.Clinicahistory.dieta;
                this.clinica_history_plantilla.lab_question = this.Clinicahistory.lab_question;
                this.clinica_history_plantilla.medical_act_id = this.Clinicahistory.medical_act_id;
                this.clinica_history_plantilla.biological_function = JSON.parse(this.Clinicahistory.biological_function);
                this.clinica_history_plantilla.insuline = this.Clinicahistory.insulin;
                this.clinica_history_plantilla.grupo = this.Clinicahistory.blood_group;
                this.clinica_history_plantilla.factor = this.Clinicahistory.blood_factor;

                this.insuline_state = false
                this.recipe.forEach(item => {
                    if (item.type == 1 && item.molecule.toLowerCase().includes('insulina')) {
                        this.insuline_state = true
                    }
                })
            }
        },
        mtdToggleClassInit: function () {
            let div1 = document.getElementById('div1');
            let div2 = document.getElementById('div2');
            let div3 = document.getElementById('div3');
            let div1_content = document.getElementById('div1-content');
            let div2_content = document.getElementById('div2-content');
            let div3_content = document.getElementById('div3-content');
            div1.classList.remove('orden1');
            div1.classList.add('active-section');
            div1_content.classList.remove('visually-hidden');
            div2.classList.add('orden2');
            div2.classList.remove('active-section');
            div2_content.classList.add('visually-hidden');
            div3.classList.add('orden3');
            div3.classList.remove('active-section');
            div3_content.classList.add('visually-hidden');
        },
        toggleClass: function (id) {

            let div1 = document.getElementById('div1');
            let div2 = document.getElementById('div2');
            let div3 = document.getElementById('div3');
            let div1_content = document.getElementById('div1-content');
            let div2_content = document.getElementById('div2-content');
            let div3_content = document.getElementById('div3-content');

            if (id == 1) {
                div1.classList.remove('orden1');
                div1.classList.add('active-section');
                div1_content.classList.remove('visually-hidden');
                div2.classList.add('orden2');
                div2.classList.remove('active-section');
                div2_content.classList.add('visually-hidden');
                div3.classList.add('orden3');
                div3.classList.remove('active-section');
                div3_content.classList.add('visually-hidden');
            } else if (id == 2) {
                div1.classList.add('orden1');
                div1.classList.remove('active-section');
                div1_content.classList.add('visually-hidden');
                div2.classList.remove('orden2');
                div2.classList.add('active-section');
                div2_content.classList.remove('visually-hidden');
                div3.classList.add('orden3');
                div3.classList.remove('active-section');
                div3_content.classList.add('visually-hidden');
            } else if (id == 3) {
                div1.classList.add('orden1');
                div1.classList.remove('active-section');
                div1_content.classList.add('visually-hidden');
                div2.classList.add('orden2');
                div2.classList.remove('active-section');
                div2_content.classList.add('visually-hidden');
                div3.classList.remove('orden3');
                div3.classList.add('active-section');
                div3_content.classList.remove('visually-hidden');
            }
        },

        mtdgetCies: function (search, loading) {
            if (search.length > 4) {
                this.dataCies = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Uciadulto/cies/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataCies = response.data;

                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

        mtdaddDiagnosticoUnit: function (diagnostico) {
            this.diagnostics_unit = {
                id: diagnostico.id,
                codigo: diagnostico.code,
                name: diagnostico.name,
                type: diagnostico.type,
                cie: diagnostico.cie,
            };
            this.dataCies = [];
        },
        mtdaddDiagnostico: function () {
            const exists = this.clinica_history.diagnostics.some(
                (item) => item.id === this.diagnostics_unit.id
            );
            if (exists) {
                this.$refs.SweetAlert.showWarning("Diagnostico ya agregado");
                this.mtdhidediagnosticounit();
                return;
            }
            this.clinica_history.diagnostics.push(this.diagnostics_unit);
            this.mtdhidediagnosticounit();
        },
        mtdhidediagnosticounit: function () {
            this.diagnostics_unit = {
                id: "",
                codigo: "",
                name: "",
                cie: "",
                type: "",
            };
            this.diagnostico = null;
        },
        openmodaldx: function () {
            if (this.inputchecked == false) {
                this.modaldx = {
                    title: "Nuevo Diagnostico",
                    modal_form: true,
                };
                this.mtdhidediagnosticounit();
            }
        },
        mtdclosemodaldx: function () {
            this.modaldx = {
                title: "",
                modal_form: false,
            };
            this.inputchecked = false;
        },
        mtdcie: function (itemdetalle) {
            this.diagnostics_unit = {
                id: itemdetalle.id,
                codigo: itemdetalle.code,
                name: itemdetalle.name,
            };
            this.diagnostico = itemdetalle.name;
        },
        mtdgetMedicamentos: function (search, loading) {
            if (search.length > 4) {
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Uciadulto/productdetails/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.productdetails = response.data;

                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        addreceta: function (payload) {
            this.type = (payload.type == 2 || payload.type == 3) ? true : false
            this.type_name= payload.type
            this.receta = payload.molecule
            this.recipe_unit.order = this.recipe.length + 1;
            this.recipe_unit.molecule = payload.molecule ? payload.molecule : '-';
            this.recipe_unit.type = payload.type
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            if (this.type) {
                this.recipe_unit.via = this.type_name ==3 ? '': '-'
                this.recipe_unit.frequency = '-'
                this.recipe_unit.duration = '-'
                this.recipe_unit.dosis = payload.dosis ? payload.dosis : '-';
                this.recipe_unit.presentation = payload.presentation ? payload.presentation : payload.commercial;
            } else {
                this.recipe_unit.dosis = payload.dosis;
                this.recipe_unit.quantity = payload.dosis;
                this.recipe_unit.presentation = payload.presentation;
            }
        },

        mtdopenmodal: function () {
            if (this.receta) {
                this.posmedicine = 1;
                this.modalmedicine = {
                    title: "Lista de Medicamentos",
                    modal_form: true,
                }
            } else {
                this.$refs.SweetAlert.showWarning('Digite Molecula');
            }
        },
        mtdclosemodalmedicine: function () {
            this.posmedicine = null;
            this.modalmedicine = {
                title: "",
                modal_form: false,
            }
            this.receta = '',
                this.recipe_unit = {
                    order: null,
                    product_id: null,
                    molecule: '',
                    presentation: '',
                    dosis: '',
                    via: '',
                    frequency: '',
                    duration: '',
                    quantity: '',
                    group: 0,
                    prn: false
                }
        },
        mtdclosemodalcomment: function () {
            this.modalcomment = {
                title: "",
                modal_form: false,
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        mtdViewMezclas: function () {
            this.modalmezcla = {
                title: "Lista de Medicamentos",
                modal_form: true,
            }
            this.medicinemezclas = this.recipe
        },
        mtdclosemodalmezclas: function () {
            this.modalmezcla = {
                title: "",
                modal_form: false,
            }
        },
        mtdchange: function (payload) {
            this.recipe = payload
            this.group_state = true
            this.mtdclosemodalmezclas()
        },
        mtdPrnGroup: function (payload) {
            if (payload.group != 0) {
                this.recipe.forEach(item => {
                    if (item.group == payload.group) {
                        item.prn = !item.prn
                    }
                })
            }
        }

    }

}
</script>
