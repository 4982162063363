<template>
<div class="container">
    <div class="row">
        <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
            <button type="button" class="btn btn-outline-main" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div class="card w-100 mb-lg-5">
            <div class="text-center card-header text-white h4 bg-main">
                <strong>PLANES</strong>
            </div>
            <div class="card-body w-100">
                <div class="row mt-3 px-3">
                    <div class="col-xl-10 ">

                        <div class="">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text text-white bg-main cursor-pointer">
                                    <i class="fas fa-search"></i>&nbsp;&nbsp;Filtrar
                                </span>
                                <input type="text" class="form-control form-control-sm" placeholder="Buscar DNI o nombre de Paciente o tipo de documento" v-model="search" @input="mtdSearchFilter" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2   ">
                        <div class="form-check form-check-inline  me-0 px-0 ">
                            <input v-model="selected" class="form-check-input" type="checkbox" id="checkboxFactura" @click="mtdselected" :disabled="pos2" />
                            <label class="form-check-label" for="checkboxFactura"> Ver Todos </label>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center px-0">
                        <!-- paginado -->
                        <div v-if="cpData.length > 0" class="d-flex justify-content-end ">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination mb-1">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                        <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="col-md-12 mt-1 px-0" id="content-timelinetable">
                        <table id="tb-anular" class="table table-bordered border-main">
                            <caption class="py-0 my-0"></caption>
                            <thead>
                                <tr class=" table-th text-white text-center text-xs">
                                    <th scope="col">N°</th>
                                    <th scope="col">ORIGEN</th>
                                    <th scope="col">PACIENTE</th>
                                    <th scope="col"> CONCEPTO</th>
                                    <th scope="col">FECHA DE SOLICITUD</th>
                                    <th scope="col">MÉDICO SOLICITA</th>
                                    <th scope="col">PLAN</th>
                                    <th scope="col" colspan="1"> ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody class="text-xs">
                                <tr class="table-body text-center" v-if="cpData.length === 0 && pos ===0">
                                    <td colspan="8">Digite DNI O nombre del paciente </td>
                                </tr>
                                <tr class="table-body text-center" v-if="cpData.length === 0 && pos ===1">
                                    <td colspan="8">No se han encontrado resultados</td>
                                </tr>
                                <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td class="align-left"> 
                                        {{ 
                                            item.type_service == 1 ? 'CONSULTA' : 
                                            item.type_service == 3 ? 'HOSPITALIZACIÓN':
                                            item.type_service == 4 ? 'UCI ADULTO': 'EMERGENCIA' 
                                        }}
                                    </td>
                                    <td>{{ item.medicalact.patient.name }}</td>
                                    <td>{{ item.subsubcategorie.name }} </td>
                                    <td class="align-center">
                                        {{ item.aplication_date  }}
                                    </td>
                                    <td class="align-center">{{ item.doctor.name }}</td>
                                    <td class="align-center">
                                        <span v-if="item.type == 1">LABORATORIO</span>
                                        <span v-if="item.type == 2"> IMAGENES</span>
                                        <span v-if="item.type == 3"> PROCEDIMIENTOS</span>

                                    </td>
                                    <td v-if="item.file" class="text-center  align-middle">
                                        <div class="d-flex justify-content-center">
                                            <button class="btn btn-sm btn-danger text-white" @click="openPdf(item.file)">
                                                <i class="fa fa-file-pdf"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td v-else class="text-center  align-middle">
                                        <div class="d-flex justify-content-center">
                                            <button @click="triggerFileInput(index)" class="btn btn-sm btn-warning text-white">
                                                <i class="fas fa-upload"></i>
                                            </button>
                                            <input type="file" @change="handleFileUpload($event, index,item)" :ref="'fileInput' + index" style="display : none">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapActions
} from "vuex";
export default {
    name: "c-admision-Planes",
    data() {
        return {
            search: '',
            dataplanes: [],
            totalPagesToShow: 4,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            dataShow: [],
            pos: 0,
            pos2: true,
            selected: false
        }
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },
        mtdselected: function () {
            this.selected = !this.selected;
            this.mtdSearchFilter();
        },
        mtdSearchFilter: function () {

            if (this.search.length > 3) {
                this.post({
                        url: this.$store.getters.get__url + "/Admision/planes",
                        token: this.$store.getters.get__token,
                        params: {
                            search: this.search,
                            selected: this.selected
                        }
                    })
                    .then((response) => {
                        this.dataplanes = response.data
                        this.pos = 1;
                        this.pos2 = false
                        this.calculateData(10)

                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            }
            if (this.search.length == 0) {
                this.pos = 0;
                this.pos2 = true
                this.selected = false
                this.dataplanes = []
                this.calculateData(10)
            }

        },
        calculateData(items) {
            if (!this.dataplanes) {
                this.dataShow = [];
            }
            let filteredData = this.dataplanes;
            let selectPage = this.selectPage;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage = this.selectPage < this.stepPagination.length ? this.selectPage + 1 : this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        triggerFileInput(index) {
            this.$refs['fileInput' + index][0].click();
        },
        // handleFileUpload(event, index, item) {
        //     let file = event.target.files[0];
        //     if (item.type == 1) {
        //         if (!/\.pdf$/i.test(file.name)) {
        //             Swal.fire({
        //                 text: "Seleccione un archivo formato PDF",
        //                 icon: "info",
        //                 confirmButtonColor: "#900052",
        //             }).then((result) => {
        //                 if (result.isConfirmed) {
        //                     const input = this.$refs['fileInput' + index][0];
        //                     input.type = "text";
        //                     input.type = "file";

        //                 }
        //             });
        //         } else {
        //             this.filelaboratorio = file;
        //             let formData = new FormData();
        //             formData.append("id", item.id);
        //             formData.append("file", this.filelaboratorio);
        //             formData.append("document", item.medicalact.patient.document);
        //             formData.append('case', 1);

        //             this.post({
        //                     url: this.$store.getters.get__url + "/Admision/editHistoryPlanDetail",
        //                     token: this.$store.getters.get__token,
        //                     params: formData
        //                 })
        //                 .then((response) => {
        //                     if (response.statusCode == 200) {
        //                         Swal.fire({
        //                             icon: "success",
        //                             title: "Datos Guardados",
        //                             showConfirmButton: false,
        //                             timer: 1500,
        //                         });
        //                         this.mtdSearchFilter()
        //                     }
        //                 })
        //                 .catch((errors) => {});

        //         }
        //     }else{
        //         if (!/\.(png|jpg|jpeg)$/i.test(file.name)) {
        //             Swal.fire({
        //                 text: "Seleccione una imagen",
        //                 icon: "info",
        //                 confirmButtonColor: "#900052",
        //             }).then((result) => {
        //                 if (result.isConfirmed) {
        //                     const input = this.$refs['fileInput' + index][0];
        //                     input.type = "text";
        //                     input.type = "file";

        //                 }
        //             });
        //         } else {
        //             this.filelaboratorio = file;
        //             let formData = new FormData();
        //             formData.append("id", item.id);
        //             formData.append("file", this.filelaboratorio);
        //             formData.append("document", item.medicalact.patient.document);
        //             formData.append('case', 1);

        //             this.post({
        //                     url: this.$store.getters.get__url + "/Admision/editHistoryPlanDetail",
        //                     token: this.$store.getters.get__token,
        //                     params: formData
        //                 })
        //                 .then((response) => {
        //                     if (response.statusCode == 200) {
        //                         Swal.fire({
        //                             icon: "success",
        //                             title: "Datos Guardados",
        //                             showConfirmButton: false,
        //                             timer: 1500,
        //                         });
        //                         this.mtdSearchFilter()
        //                     }
        //                 })
        //                 .catch((errors) => {});

        //         }
        //     }

        // }
        handleFileUpload(event, index, item) {
            let file = event.target.files[0];
            let allowedExtensions =  /\.(png|jpg|jpeg|pdf)$/i  ;

            if (!allowedExtensions.test(file.name)) {
                let fileType =  "imagen o PDF";
                Swal.fire({
                    text: `Seleccione un archivo formato ${fileType}`,
                    icon: "info",
                    confirmButtonColor: "#900052",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const input = this.$refs['fileInput' + index][0];
                        input.type = "text";
                        input.type = "file";
                    }
                });
            } else {
                this.filelaboratorio = file;
                let formData = new FormData();
                formData.append("id", item.id);
                formData.append("file", this.filelaboratorio);
                formData.append("document", item.medicalact.patient.document);
                formData.append('case', 1);

                this.post({
                    url: this.$store.getters.get__url + "/Admision/editHistoryPlanDetail",
                    token: this.$store.getters.get__token,
                    params: formData
                }).then((response) => {
                    if (response.statusCode == 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Datos Guardados",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.mtdSearchFilter();
                    }
                }).catch((errors) => {});
            }
        },
        openPdf: function (url) {
        let nuevaUrl = "/assets/" + url;
        window.open(nuevaUrl, '_blank');
    },

    },
    

}
</script>
