<template>
<div class=" row max-0 px-0 pt-3 mb-3" v-if=" dias.length || noche.length">
    <div class="row d-flex justify-content-end mx-0 px-2">
        <div class="col-12 px-0">
            <nav aria-label="Page navigation example" class="pagination-container">
                <ul class="pagination pagination-xs justify-content-end">
                    <li class="page-item">
                        <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                    </li>
                    <template>
                        <li class="page-item">
                            <a class="page-link active" href="javascript:void(0)">{{ date_name }}</a>
                        </li>
                    </template>
                    <li class="page-item">
                        <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div class=" col-xl-12 row mx-0 px-2">
        <div class="col-xl-6 px-0 ">
            <div class="row mx-0 border border-2 border-main py-2">
                <strong class=" text-xs"> TURNO DIA :</strong>
                <div class="col-xl-3 col-lg-12 pt-2 mt-2">
                    <div v-for="(item, index) in dia.itemsNota" :key="index">
                        <div class="row">
                            <div class="col-md-12 mb-1">
                                <div class="row">
                                    <div class=" col-md-6 div-text text-start">{{ item.name}}</div>
                                    <div class="col-md-6">
                                        <input v-model="item.value" type="text" class="form-control form-control-input" autocomplete="off" :disabled="item.state || state_dia || validation">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-12 text-xs ">
                    <div class="row pt-3">
                        <div class=" col-md-4 col-sm-6 mb-3">
                            <input v-model="dia.date" type="date" class="form-control form-control-input form-control-sm " autocomplete="off" readonly>
                        </div>
                    </div>
                    <div class="row  mb-3">
                        <div class="  d-flex mb-3 justify-content-between">
                            <div class="py-0 d-flex ">
                                <div class="form-check form-check-inline ps-0">
                                    <label class="form-check-label text-content ">Escaras: </label>
                                </div>
                                <div class="form-check">
                                    <input v-model="dia.encaras" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="si" :disabled="state_dia || encaradia || validation">
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Si
                                    </label>
                                </div>
                                <div class="form-check ms-3">
                                    <input v-model="dia.encaras" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="no" :disabled="state_dia || encaradia || validation">
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="py-0 d-flex " v-if="this.date_name == 'NOTA ACTUAL'">
                                <div class="form-check form-check-inline ps-0">
                                    <label class="form-check-label text-content ">Escala Correctiva: </label>
                                </div>
                                <div class="form-check">
                                    <input v-model="escala_dia" class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault5" value="si" :disabled="state_dia || validation">
                                    <label class="form-check-label" for="flexRadioDefault5">
                                        Si
                                    </label>
                                </div>
                                <div class="form-check ms-3">
                                    <input v-model="escala_dia" class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault6" value="no" :disabled="state_dia ||  validation">
                                    <label class="form-check-label" for="flexRadioDefault6">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class=" d-inline-flex  px-0">
                                <button v-if="!state_dia && !validation" @click="horario(1)" type="button" class="btn text-white px-3 btn-sm btn-info text-xs w-100">
                                    Guardar Nota
                                </button>
                            </div>
                        </div>
                        
                        <div v-if="escala_dia == 'si'" :class="dia.encaras == 'si' ? 'col-xl-6' : 'col-xl-12'">
                            <div class="mb-1">
                                <label class=" fw-bold"> Escala Correctiva:</label>
                            </div>
                            <div v-if="escalatext" class="input-group">
                                <textarea v-model="escalatext" class="form-control text-content" rows="5"  readonly></textarea>
                            </div>
                            <div v-else class="text-center">
                                <strong>No hay Resultado</strong>
                            </div>
                        </div>
                        <div v-if="dia.encaras == 'si'" class="d-flex" :class="escala_dia == 'si' ? 'col-xl-6 ps-0' : 'col-xl-12'">
                            <div class="col-xl-8">
                                <div class="mb-1">
                                    <label class=" fw-bold"> Zona:</label>
                                </div>
                                <div class="input-group">
                                    <textarea v-model="dia.zona" class="form-control text-content" rows="3"  :disabled="state_dia || encaradia || validation"></textarea>
                                </div>
                            </div>
                            <div class="col-xl-4 ps-2">
                                <div class="mb-1">
                                    <label class=" fw-bold"> Grado:</label>
                                </div>
                                <div class="input-group">
                                    <select v-model="dia.level" class=" form-control form-control-input form-control-sm" :disabled="state_dia || encaradia || validation">
                                        <option value="I">I</option>
                                        <option value="II">II</option>
                                        <option value="III">III</option>
                                        <option value="IV">IV</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="dia.encaras == 'si'" class=" mt-2" >
                            <label class=" fw-bold"> Detalle de la Curación:</label>
                            <textarea v-model="dia.detalle" class="form-control text-content" rows="5" :disabled="state_dia || detalledia || validation"></textarea>
                        </div>
                        <div class="mb-1 mt-1 d-flex justify-content-between">
                            <div>
                                <label class=" fw-bold"> Notas:</label>
                            </div>
                            <div class="d-flex" v-if="!state_dia && !validation">

                                <button @click="triggerFileInput(0)" class="btn btn-sm btn-warning text-white me-1 py-0" :disabled="nota_dia == ''">
                                    <i class="fas fa-upload"></i>
                                </button>
                                <input v-model="filename" type="text" class="form-control form-control-sm form-control-input" autocomplete="off" readonly>
                                <input type="file" @change="handleFileUpload($event,0)" :ref="'fileInput'+ 0" style="display : none">
                            </div>
                        </div>
                        <div class="input-group">
                            <textarea v-model="nota_dia" class="form-control text-content" rows="5" :disabled="state_dia || validation"></textarea>
                        </div>
                        <div class="mt-3">
                            <div class=" d-flex justify-content-between px-3 py-3 mb-2 rounded rounded-2" style=" background-color:#D2E0F2;" v-for="( nota, index) in dia.notas" :key="index">
                                <div>
                                    <strong> {{ nota.hour }}</strong>
                                </div>
                                <div class="w-100 px-3">
                                    <strong>{{ nota.doctor_name }}</strong>
                                    <div v-html="nota.name.replace(/\n/g, '<br>')"></div>
                                </div>
                                <div >
                                    <button v-if="nota.user_id == user_id && !validation" @click="editNote(1,nota,index)" type="button" class="btn btn-sm btn-warning"><i class="fas fa-edit"></i></button>
                                    <button @click="ViewNoteEdit(1,nota)" type="button" class="btn btn-sm btn-info mt-1"><i class="fas fa-eye"></i></button>
                                    <div>
                                        <button v-if="nota.file" @click="openPdf(nota.file)" type="button" class="btn btn-sm btn-danger mt-1"><i class="fa fa-file-pdf"></i></button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-xl-6 px-xl-1 px-0 mt-2 mt-xl-0">
            <div class="row mx-0 border border-2 border-main py-2">
                <strong class=" text-xs"> TURNO NOCHE :</strong>
                <div class="col-xl-3 col-lg-12 pt-2 mt-2">
                    <div v-for="(item1, index) in noche.itemsNota" :key="index">
                        <div class="row">
                            <div class="col-md-12 mb-1">
                                <div class="row">
                                    <div class=" col-md-6 div-text text-start">{{ item1.name }}</div>
                                    <div class="col-md-6">
                                        <input v-model="item1.value" type="text" class="form-control form-control-input" autocomplete="off" :disabled="item1.state || state_noche || validation">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-12 text-xs ">
                    <div class="row pt-3">
                        <div class=" col-md-4 col-sm-6 mb-3">
                            <input v-model="noche.date" type="date" class="form-control form-control-input form-control-sm " autocomplete="off" readonly>
                        </div>
                        <!-- <div class="col-md-6 col-sm-6 justify-content-end mb-3">
                            <input v-model="noche.doctor_name" type="text" class="form-control form-control-input text-content form-control-sm" placeholder="Nombre del doctor" autocomplete="off" readonly>
                        </div> -->
                    </div>
                    <div class="row  mb-3">
                        <div class="  d-flex mb-3 justify-content-between">
                            <div class="py-0 d-flex ">
                                <div class="form-check form-check-inline ps-0">
                                    <label class="form-check-label text-content ">Escaras: </label>
                                </div>
                                <div class="form-check">
                                    <input v-model="noche.encaras" class="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault3" value="si" :disabled="state_noche || encaranoche || validation">
                                    <label class="form-check-label" for="flexRadioDefault3">
                                        Si
                                    </label>
                                </div>
                                <div class="form-check ms-3">
                                    <input v-model="noche.encaras" class="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault4" value="no" :disabled="state_noche || encaranoche || validation">
                                    <label class="form-check-label" for="flexRadioDefault4">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class="py-0 d-flex " v-if="this.date_name == 'NOTA ACTUAL'">
                                <div class="form-check form-check-inline ps-0">
                                    <label class="form-check-label text-content ">Escala Correctiva: </label>
                                </div>
                                <div class="form-check">
                                    <input v-model="escala_noche" class="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault3" value="si" :disabled="state_noche || validation">
                                    <label class="form-check-label" for="flexRadioDefault7">
                                        Si
                                    </label>
                                </div>
                                <div class="form-check ms-3">
                                    <input v-model="escala_noche" class="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault4" value="no" :disabled="state_noche  || validation">
                                    <label class="form-check-label" for="flexRadioDefault8">
                                        No
                                    </label>
                                </div>
                            </div>
                            <div class=" d-inline-flex  px-0">
                                <button v-if="!state_noche && !validation" @click="horario(2)" type="button" class="btn text-white btn-info px-3 btn-sm  text-xs w-100">
                                    Guardar Nota
                                </button>
                            </div>
                        </div>
                        
                        <div v-if="escala_noche == 'si'" :class="noche.encaras == 'si' ? 'col-xl-6' : 'col-xl-12'">
                            <div class="mb-1">
                                <label class=" fw-bold"> Escala Correctiva:</label>
                            </div>
                            <div v-if="escalatext" class="input-group">
                                <textarea  v-model="escalatext" class="form-control text-content" rows="5" readonly></textarea>
                            </div>
                            <div v-else class="text-center">
                                <strong>No hay Resultado</strong>
                            </div>
                        </div>
                        <div v-if="noche.encaras == 'si'" class="d-flex" :class="escala_noche == 'si' ? 'col-xl-6 ps-0' : 'col-xl-12'">
                            <div class="col-xl-8">
                                <div class="mb-1">
                                    <label class=" fw-bold"> Zona:</label>
                                </div>
                                <div class="input-group">
                                    <textarea v-model="noche.zona" class="form-control text-content" rows="3"  :disabled="state_noche || encaranoche || validation"></textarea>
                                </div>
                            </div>
                            <div class="col-xl-4 ps-2">
                                <div class="mb-1">
                                    <label class=" fw-bold"> Grado:</label>
                                </div>
                                <div class="input-group">
                                    <select v-model="noche.level" class=" form-control form-control-input form-control-sm" :disabled="state_noche || encaranoche || validation">
                                        <option value="I">I</option>
                                        <option value="II">II</option>
                                        <option value="III">III</option>
                                        <option value="IV">IV</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="noche.encaras == 'si'" class=" mt-2" >
                            <label class=" fw-bold"> Detalle de la Curación:</label>
                            <textarea v-model="noche.detalle" class="form-control text-content" rows="5" :disabled="state_noche || detallenoche || validation"></textarea>
                        </div>
                        <div class="mb-1 mt-1 d-flex justify-content-between">
                           <div>
                               <label class=" fw-bold"> Notas:</label>
                           </div>   
                           <div class="d-flex" v-if="!state_noche && !validation">
                                <button @click="triggerFileInput(1)" class="btn btn-sm btn-warning text-white me-1 py-0" :disabled="nota_noche ==''">
                                    <i class="fas fa-upload"></i>
                                </button>
                                <input v-model="filename" type="text" class="form-control form-control-sm form-control-input" autocomplete="off" readonly>
                                <input type="file" @change="handleFileUpload($event,1)" :ref="'fileInput'+ 1" style="display : none">
                            </div>
                        </div>
                        <div class="input-group">
                            <textarea v-model="nota_noche" class="form-control text-content" rows="5" :disabled="state_noche || validation"></textarea>
                        </div>
                        <div class="mt-3">
                            <div class=" d-flex justify-content-between px-3 py-3 mb-2 rounded rounded-2" style=" background-color:#D2E0F2;" v-for="( nota, index) in noche.notas" :key="index">
                                <div >
                                    <strong> {{ nota.hour }}</strong>
                                </div>
                                <div  class="w-100 px-3">
                                    <strong>{{ nota.doctor_name }}</strong>
                                    <div v-html="nota.name.replace(/\n/g, '<br>')"></div>
                                </div>
                                <div >
                                    <button v-if="nota.user_id == user_id && !validation" @click="editNote(2,nota,index)"  type="button" class="btn btn-sm btn-warning"><i class="fas fa-edit"></i></button>
                                    <button @click="ViewNoteEdit(2,nota)" type="button" class="btn btn-sm btn-info mt-1"><i class="fas fa-eye"></i></button>
                                    <div>
                                        <button v-if="nota.file" @click="openPdf(nota.file)" type="button" class="btn btn-sm btn-danger mt-1"><i class="fa fa-file-pdf"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="col-xl-12 px-1 ">
        <div class="row mx-0 px-1 py-2">
            <div class=" col-xl-6 responsive-container-div  px-0    mb-3">
                <div>
                    <table class=" plan w-100 table-bordered border-white  text-center ">
                        <caption class=" py-0 my-0"></caption>
                        <thead class=" text-white text-xxs ">
                            <tr>
                                <th class="py-1" colspan="4">LABORATORIOS</th>
                            </tr>
                            <tr>
                                <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                <th class="py-1">FECHA DE SOLICITUD</th>
                                <th class="py-1">FECHA DE RESULTADO</th>
                                <th class="py-1">FECHA DE APLICACIÓN</th>
                            </tr>
                        </thead>
                        <tbody class="div-text" v-if="plan_laboratorio.length > 0">
                            <tr class="px-1 mb-1" v-for="(laboratorio , index) in plan_laboratorio" :key="index">

                                <td class="border">
                                    {{ laboratorio.name }}
                                </td>
                                <td class="border">
                                    {{ laboratorio.aplicacion }}
                                </td>
                                <td class="border">
                                    {{ laboratorio.resultado ? laboratorio.resultado : '-' }}
                                </td>
                                <td v-if="laboratorio.aplicacion_nurse" class="border">
                                    {{ laboratorio.aplicacion_nurse }}
                                </td>
                                <td v-else class="border py-1">
                                    <button @click="aplicacion(laboratorio)"  type="button" class="btn py-0 text-white  btn-sm  btn-success text-xs">
                                      Aplicar
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                        <tbody class=" text-center" v-else>
                            <tr>
                                <td colspan="4" class=" text-center text-xs">
                                    <strong> No hay resultados</strong>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xl-6 responsive-container-div ps-xl-1 px-0   mb-3">
                <div>
                    <table class=" plan w-100 table-bordered border-white  text-center ">
                        <caption class=" py-0 my-0"></caption>
                        <thead class=" text-white text-xxs ">
                            <tr>
                                <th class="py-1" colspan="4">IMAGENES</th>
                            </tr>
                            <tr>
                                <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                <th class="py-1">FECHA DE SOLICITUD</th>
                                <th class="py-1">FECHA DE RESULTADO</th>
                                <th class="py-1">FECHA DE APLICACIÓN</th>
                            </tr>
                        </thead>
                        <tbody class="div-text" v-if="plan_imagenes.length > 0">
                            <tr class="px-1 mb-1" v-for="(imagen , index) in plan_imagenes" :key="index">

                                <td class="border">
                                    {{ imagen.name }}
                                </td>
                                <td class="border">
                                    {{ imagen.aplicacion }}
                                </td>
                                <td class="border">
                                    {{ imagen.resultado ? imagen.resultado : '-' }}
                                </td>
                                <td v-if="imagen.aplicacion_nurse" class="border">
                                    {{ imagen.aplicacion_nurse }}
                                </td>
                                <td v-else class="border py-1">
                                    <button @click="aplicacion(imagen)"  type="button" class="btn py-0 text-white  btn-sm  btn-success text-xs">
                                      Aplicar
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                        <tbody class=" text-center" v-else>
                            <tr>
                                <td colspan="4" class=" text-center text-xs">
                                    <strong> No hay resultados</strong>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xl-6 responsive-container-div px-0  mb-3">
                <div>
                    <table class=" plan w-100 table-bordered border-white  text-center ">
                        <caption class=" py-0 my-0"></caption>
                        <thead class=" text-white text-xxs ">
                            <tr>
                                <th class="py-1" colspan="4">PROCEDIMIENTOS</th>
                            </tr>
                            <tr>
                                <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                <th class="py-1">FECHA DE SOLICITUD</th>
                                <th class="py-1">FECHA DE RESULTADO</th>
                                <th class="py-1">FECHA DE APLICACIÓN</th>
                            </tr>
                        </thead>
                        <tbody class="div-text" v-if="plan_procedimientos.length > 0">
                            <tr class="px-1 mb-1" v-for="(Procedimiento , index) in plan_procedimientos" :key="index">

                                <td class="border">
                                    {{ Procedimiento.name }}
                                </td>
                                <td class="border">
                                    {{ Procedimiento.aplicacion }}
                                </td>
                                <td class="border">
                                    {{ Procedimiento.resultado ? Procedimiento.resultado : '-' }}
                                </td>
                                <td v-if="Procedimiento.aplicacion_nurse" class="border">
                                    {{ Procedimiento.aplicacion_nurse }}
                                </td>
                                <td v-else class="border py-1">
                                    <button @click="aplicacion(Procedimiento)"  type="button" class="btn py-0 text-white  btn-sm  btn-success text-xs">
                                      Aplicar
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                        <tbody class=" text-center" v-else>
                            <tr>
                                <td colspan="4" class=" text-center text-xs">
                                    <strong> No hay resultados</strong>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xl-6 responsive-container-div  ps-xl-1 px-0   mb-3">
                <div>
                    <table class=" plan w-100 table-bordered border-white  text-center ">
                        <caption class=" py-0 my-0"></caption>
                        <thead class=" text-white text-xxs ">
                            <tr>
                                <th class="py-1" colspan="4">INTERCONSULTAS</th>
                            </tr>
                            <tr>
                                <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                <th class="py-1">FECHA DE SOLICITUD</th>
                                <th class="py-1">FECHA DE RESULTADO</th>
                                <th class="py-1">RESPUESTA</th>
                            </tr>
                        </thead>
                        <tbody class="div-text" v-if="plan_interconsultas.length > 0">
                            <tr class="px-1 mb-1" v-for="(interconsulta , index) in plan_interconsultas" :key="index">

                                <td class="border">
                                    {{ interconsulta.name }}
                                </td>
                                <td class="border">
                                    {{ interconsulta.aplicacion }}
                                </td>
                                <td class="border">
                                    {{ interconsulta.resultado ? interconsulta.resultado : '-' }}
                                </td>
                                <td v-if="interconsulta.resultado" class="border">
                                    <div>
                                        <span>
                                            <a @click="mtdAnswer(interconsulta.interconsulta)"><i class="color-main fas fa-solid fa-eye"></i></a>
                                        </span>
                                    </div>
                                </td>
                                <td v-else class="border">
                                    -
                                </td>
                            </tr>

                        </tbody>
                        <tbody class=" text-center" v-else>
                            <tr>
                                <td colspan="4" class=" text-center text-xs">
                                    <strong> No hay resultados</strong>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
    <SweetAlert @mtdhorario="mtdhorario"  @aplicaciondata="aplicaciondata" :swal="swal" ref="SweetAlert" />
    <NoteEdit :notedetail="notedetail" @editNurseNote="editNurseNote" :title="modalnote.title" :boo_modal="modalnote.modal_form" @mtdclosemodalnote="mtdclosemodalnote"  />
    <NoteEditView :notesedit="notesedit" :title="modalnoteview.title" :boo_modal="modalnoteview.modal_form" @mtdclosemodalview="mtdclosemodalview"  />
</div>
<div v-else>
    <div class="text-center mt-5">
        <h2>No se encontraron resultados</h2>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Enfermera from '@/components/Maintenance/pages/Honorarios-Asistencial/Enfermera.vue';
import NoteEdit from '@/components/Asistencial/Emergency/modals/cModalEditNote.vue'
import NoteEditView from '@/components/Asistencial/Emergency/modals/cModalViewNotesEdit.vue'
export default {
    name: 'c-asistencial-hospitalizacion-historia-notaenfermeria',
    data() {
        return {

            swal: null,
            dia: {
                time: 'dia',
                id: null,
                itemsNota: [{
                        id: 1,
                        name: 'FC',
                        value: '',
                        state: false
                    },
                    {
                        id: 2,
                        name: 'FR',
                        value: '',
                        state: false
                    },
                    {
                        id: 3,
                        name: 'HGT',
                        value: '',
                        state: false
                    },
                    {
                        id: 4,
                        name: 'PAS',
                        value: '',
                        state: false
                    },
                    {
                        id: 5,
                        name: 'PAD',
                        value: '',
                        state: false
                    },
                    {
                        id: 6,
                        name: 'PAM',
                        value: '',
                        state: false
                    },
                    {
                        id: 7,
                        name: 'SAT02',
                        value: '',
                        state: false
                    },
                    {
                        id: 8,
                        name: 'FO2',
                        value: '',
                        state: false
                    },
                    // {
                    //     id: 9,
                    //     name: 'HT6',
                    //     value: '',
                    //     state: false
                    // },
                    {
                        id: 10,
                        name: 'T',
                        value: '',
                        state: false
                    },
                    {
                        id: 11,
                        name: 'DIURESIS',
                        value: '',
                        state: false
                    },
                    {
                        id: 12,
                        name: 'DIAS VIAS CENT.',
                        value: '',
                        state: false
                    },
                    {
                        id: 13,
                        name: 'DIAS VIAS PERIF.',
                        value: '',
                        state: false
                    },

                ],
                date: null,
                doctor_name: '',
                doctor_id: null,
                notas: [],
                encaras: '',
                medical_act_id: null,
                zona:'',
                level:'',
                detalle:''
                // escala: '',
                // escalatext: ''

            },
            noche: {
                time: 'noche',
                id: null,
                itemsNota: [{
                        id: 1,
                        name: 'FC',
                        value: '',
                        state: false
                    },
                    {
                        id: 2,
                        name: 'FR',
                        value: '',
                        state: false
                    },
                    {
                        id: 3,
                        name: 'HGT',
                        value: '',
                        state: false
                    },
                    {
                        id: 4,
                        name: 'PAS',
                        value: '',
                        state: false
                    },
                    {
                        id: 5,
                        name: 'PAD',
                        value: '',
                        state: false
                    },
                    {
                        id: 6,
                        name: 'PAM',
                        value: '',
                        state: false
                    },
                    {
                        id: 7,
                        name: 'SAT02',
                        value: '',
                        state: false
                    },
                    {
                        id: 8,
                        name: 'FO2',
                        value: '',
                        state: false
                    },
                    // {
                    //     id: 9,
                    //     name: 'HT6',
                    //     value: '',
                    //     state: false
                    // },
                    {
                        id: 10,
                        name: 'T',
                        value: '',
                        state: false
                    },
                    {
                        id: 11,
                        name: 'DIURESIS',
                        value: '',
                        state: false
                    },
                    {
                        id: 12,
                        name: 'DIAS VIAS CENT.',
                        value: '',
                        state: false
                    },
                    {
                        id: 13,
                        name: 'DIAS VIAS PERIF.',
                        value: '',
                        state: false
                    },

                ],
                date: null,
                doctor_name: '',
                doctor_id: null,
                notas: [],
                encaras: '',
                medical_act_id: null,
                zona:'',
                level:'',
                detalle:''
                // escala: '',
                // escalatext: ''

            },
            nota_dia: '',
            nota_noche: '',
            plan_laboratorio: [],
            plan_imagenes: [],
            plan_procedimientos: [],
            plan_interconsultas: [],
            state_noche: false,
            state_dia: false,
            date_name: '',
            dates_names: [],
            dias: [],
            noches: [],
            pos: null,
            encaradia: false,
            encaranoche: false,
            escaladia: false,
            escalanoche: false,
            validation: false,
            escala_dia: '',
            escala_noche: '',
            escalatext: '',
            detallenoche: false,
            detalledia: false,
            user_id:null,
            posind:null,
            num:null,
            notedetail:{
                nursing_note_id:null,
                order:null,
                motive:'',
                comment_old: '',
                comment_new:'' ,
                notas:{}     
            },
            modalnote:{
                title:'',
                modal_form:false
            },
            modalnoteview:{
                title:'',
                modal_form:false
            },
            notesedit:[],
            filename: '',
            hour:''
        }
    },
    created() {

    },
    components: {
        SweetAlert,
        NoteEdit,
        NoteEditView
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0
        },
        dete_out: {
            type: Boolean
        }
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0) {
                    this.updateturn()
                    this.mtdGetplanes();
                    this.mtdGetNotas();
                    this.setCurrentDateTime();
                }
            },
            deep: true,
            immediate: true,
        },

    },
    methods: {
        ...mapActions(['get', 'post']),
        setCurrentDateTime() {
            const now = new Date();
            const timezoneOffset = now.getTimezoneOffset() * 60000;
            const localDate = new Date(now.getTime() - timezoneOffset);
            this.dia.date = localDate.toISOString().split('T')[0];
            this.noche.date = localDate.toISOString().split('T')[0];
            this.dia.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
            this.noche.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
            this.dia.medical_act_id = this.medical_act_id;
            this.noche.medical_act_id = this.medical_act_id;
        },
        mtdGetplanes: function () {
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/indexRp/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    let plandetails = response.data.planes;
                    this.plan_laboratorio = []
                    this.plan_imagenes = []
                    this.plan_procedimientos = []
                    this.plan_interconsultas = []
                    plandetails.forEach(item => {
                        let concepto = {
                            id:item.id,
                            medical_act_id:item.medical_act_id,
                            subsubcategoria_id:item.subsub_categorie_id,
                            subcategoria: item.subsubcategorie.subategory_id,
                            name: item.subsubcategorie.name,
                            aplicacion: item.aplication_date_new,
                            resultado: item.result_date_new,
                            aplicacion_nurse: item.application_date_nurse_new,
                            interconsulta: item.interconsulta ? item.interconsulta : '-'
                        }

                        if (item.type == 1) {
                            this.plan_laboratorio.push(concepto)
                        } else if (item.type == 2) {
                            this.plan_imagenes.push(concepto)
                        } else if (item.type == 3) {
                            this.plan_procedimientos.push(concepto)
                        } else {
                            this.plan_interconsultas.push(concepto)
                        }

                    });
                }
            });
        },
        horario:function(ind){
            this.$refs.SweetAlert.horario(ind);
        },
        mtdhorario:function(hour,payload){
          this.hour=hour
          if(payload ==1){
            this.addnotadia()
          }else{
            this.addnotanoche()
          }
        },
        addnotadia: function () {
            const now = new Date();
            if (this.nota_dia) {
                let dia_nota = {
                    hour: this.hour,
                    name: this.nota_dia,
                    doctor_name: this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name,
                    user_id: this.$store.getters.get__user.id,
                    order: this.dia.notas.length + 1,
                    file:''
                }
                this.dia.notas.push(dia_nota)
            }
            this.dia.itemsNota.forEach(item => {
                if (item.value) {
                    item.state = true
                }
            })

            this.addnotas(this.dia)
        },

        addnotanoche: function () {
            const now = new Date();
            if (this.nota_noche) {
                let noche_nota = {
                    hour: this.hour,
                    name: this.nota_noche,
                    doctor_name: this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name,
                    user_id: this.$store.getters.get__user.id,
                    order: this.noche.notas.length + 1,
                    file:''
                }
                this.noche.notas.push(noche_nota)

            }
            this.noche.itemsNota.forEach(item => {
                if (item.value) {
                    item.state = true
                }
            })

            this.addnotas(this.noche)
        },

        addnotas: function (payload) {
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/notes",
                token: this.$store.getters.get__token,
                params: payload,
            }).then((response) => {
                if (response.statusCode === 200) {
                    let data = response.data
                    if(this.filelaboratorio !=''){
                        this.mtdaddfile(data.id)
                    }else{
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdGetNotas()
                        this.nota_dia = '',
                        this.nota_noche = '',
                        this.filelaboratorio = ''
                        this.filename = ''
                        this.hour = ''
                    }
                }
            });
        },
        updateturn: function () {
            const now = new Date();
            let hour = now.toTimeString().split(' ')[0]
            if (hour >= "07:31:00" && hour < "19:31:00") {
                this.state_noche = true
            } else {
                this.state_dia = true
            }
        },

        mtdGetNotas: function () {
            this.user_id = this.$store.getters.get__user.id,
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/indexnotes/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                     this.escalatext = response.data.insulina ? response.data.insulina.insulin :''
                    const now = new Date();
                    const timezoneOffset = now.getTimezoneOffset() * 60000;
                    const localDate = new Date(now.getTime() - timezoneOffset);
                    const day = String(localDate.getDate()).padStart(2, '0');
                    const month = String(localDate.getMonth() + 1).padStart(2, '0');
                    const year = localDate.getFullYear();
                    const date = `${day}/${month}/${year}`;
                    this.dates_names = []
                    this.dias = []
                    this.noches = []
                    // fecha
                    response.data.fecha.forEach(item => {
                        this.dates_names.push(item)
                    })

                    // dia
                    let numdia = 0
                    response.data.dia.forEach(item => {
                        if (item) {
                            let dia = {
                                time: 'dia',
                                id: item.id,
                                itemsNota: JSON.parse(item.vital_signs),
                                date: item.date,
                                // doctor_name: item.doctor.name,
                                // doctor_id: item.doctor_id,
                                notas: JSON.parse(item.notes),
                                encaras: item.wounds,
                                medical_act_id: item.medical_act_id,
                                zona:item.zona,
                                level:item.level,
                                detalle:item.healing_detail
                                // escala: item.scale,
                                // escalatext: item.scale_text

                            }
                            this.dias.push(dia)
                        } else {
                            let dia = {
                                time: 'dia',
                                id: null,
                                itemsNota: this.dia.itemsNota,
                                date: response.data.fechaold[numdia],
                                // doctor_name: this.dia.doctor_name,
                                // doctor_id: null,
                                notas: [],
                                encaras: '',
                                medical_act_id: this.medical_act_id,
                                zona: '',
                                level: '',
                                detalle: ''
                                // escala: '',
                                // escalatext: '',
                            }
                            this.dias.push(dia)
                        }
                        numdia++
                    });

                    // noche
                    let numnoche = 0
                    response.data.noche.forEach(item => {
                        if (item) {
                            let noche = {
                                time: 'noche',
                                id: item.id,
                                itemsNota: JSON.parse(item.vital_signs),
                                date: item.date,
                                // doctor_name: item.doctor.name,
                                // doctor_id: item.doctor_id,
                                notas: JSON.parse(item.notes),
                                encaras: item.wounds,
                                medical_act_id: item.medical_act_id,
                                zona:item.zona,
                                level:item.level,
                                detalle:item.healing_detail
                                // escala: item.scale,
                                // escalatext: item.scale_text

                            }
                            this.noches.push(noche)
                        } else {
                            let noche = {
                                time: 'noche',
                                id: null,
                                itemsNota: this.noche.itemsNota,
                                date: response.data.fechaold[numnoche],
                                // doctor_name: this.noche.doctor_name,
                                // doctor_id: null,
                                notas: [],
                                encaras: '',
                                medical_act_id: this.medical_act_id,
                                zona: '',
                                level: '',
                                detalle: ''
                                // escala: '',
                                // escalatext: '',
                            }
                            this.noches.push(noche)
                        }
                        numnoche++
                    });

                    let dateactual
                    let hour = now.toTimeString().split(' ')[0]
                    if (hour >= "00:00:00" && hour <= '07:30:00') {
                        localDate.setDate(localDate.getDate() - 1);
                        const prevDay = String(localDate.getDate()).padStart(2, '0');
                        const prevMonth = String(localDate.getMonth() + 1).padStart(2, '0');
                        const prevYear = localDate.getFullYear();
                        dateactual = `${prevDay}/${prevMonth}/${prevYear}`;
                    } else {
                        dateactual = date
                    }

                    if (!this.dete_out && this.dates_names[this.dates_names.length - 1] != dateactual) {
                        this.dates_names.push('NOTA ACTUAL')
                        this.dias.push(this.dia)
                        this.noches.push(this.noche)
                    }
                    if (!this.dete_out) {
                        this.dates_names[this.dates_names.length - 1] = 'NOTA ACTUAL'
                    }
                    if (this.dias.length > 0 || this.noches.length > 0) {
                        this.date_name = this.dates_names[this.dates_names.length - 1]
                        this.dia = this.dias[this.dias.length - 1]
                        this.noche = this.noches[this.noches.length - 1]
                        this.pos = this.dates_names.length - 1

                        this.encaradia = this.dias[this.dias.length - 1].encaras ? true : false
                        this.encaranoche = this.noches[this.noches.length - 1].encaras ? true : false
                        this.detalledia = this.dias[this.dias.length - 1].detalle ? true : false,
                        this.detallenoche = this.noches[this.noches.length - 1].detalle ? true : false
                        this.validation = this.date_name != 'NOTA ACTUAL'
                    }
                }
            });
        },
        nextPage: function () {
            const now = new Date();
            const timezoneOffset = now.getTimezoneOffset() * 60000;
            const localDate = new Date(now.getTime() - timezoneOffset);
            const day = String(localDate.getDate()).padStart(2, '0');
            const month = String(localDate.getMonth() + 1).padStart(2, '0');
            const year = localDate.getFullYear();
            const date = `${day}/${month}/${year}`;
            this.pos = (this.pos == this.dates_names.length - 1) ? this.pos : this.pos + 1
            this.date_name = this.dates_names[this.pos]
            this.dia = this.dias[this.pos]
            this.noche = this.noches[this.pos]
            this.validation = this.date_name != 'NOTA ACTUAL'
        },

        backPage: function () {
            const now = new Date();
            const timezoneOffset = now.getTimezoneOffset() * 60000;
            const localDate = new Date(now.getTime() - timezoneOffset);
            const day = String(localDate.getDate()).padStart(2, '0');
            const month = String(localDate.getMonth() + 1).padStart(2, '0');
            const year = localDate.getFullYear();
            const date = `${day}/${month}/${year}`;
            this.pos = (this.pos == 0) ? this.pos : this.pos - 1
            this.date_name = this.dates_names[this.pos]
            this.dia = this.dias[this.pos]
            this.noche = this.noches[this.pos]
            this.validation = this.date_name != 'NOTA ACTUAL'
        },
        mtdAnswer: function (payload) {
            let comment = {
                doctor: payload.doctor1.name + ' ' + payload.doctor1.last_name,
                doctorI: payload.doctor2.name + ' ' + payload.doctor2.last_name,
                fecha: this.formatDateHour(payload.updated_at),
                respuesta: payload.answer
            }
            this.$refs.SweetAlert.commentAnswer(comment);
        },
        formatDateHour(dateString) {
            const options = {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            };
            const date = new Date(dateString);
            return date.toLocaleDateString('es-ES', options);
        },
        editNote:function(num,payload,ind){
            this.posind = ind
            this.num = num
            this.notedetail={
                nursing_note_id: num == 1 ? this.dia.id  : this.noche.id,
                order: payload.order,
                motive:'',
                comment_old: payload.name,
                comment_new: payload.name      
            }
            this.modalnote={
                title: 'Editar Nota de enfermería',
                modal_form:true
            }
        } ,
        mtdclosemodalnote:function(){
            this.modalnote={
                title: '',
                modal_form:false
            }
            this.posind = null
            this.num = null
        },
        editNurseNote:function(payload){
            if(this.num==1){
                this.dia.notas[this.posind].name = payload.comment_new
                this.notedetail.notas = this.dia.notas 
            }else{
                this.noche.notas[this.posind].name =payload.comment_new
                this.notedetail.notas=this.noche.notas
            }
            this.post({
                url: this.$store.getters.get__url + "/Emergency/mtdEditNotasEnfermera",
                token: this.$store.getters.get__token,
                params: this.notedetail,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.mtdclosemodalnote()
                    this.$refs.SweetAlert.showSuccess('Editado Correctamente')
                    this.mtdGetNotas()
                    this.nota_dia = ''
                    this.nota_noche = ''
                    
                }
            });
        },
        ViewNoteEdit:function(num,payload){
            payload.nursing_note_id = num == 1 ? this.dia.id  : this.noche.id
            this.post({
                url: this.$store.getters.get__url + "/Emergency/ViewNotesEdit",
                token: this.$store.getters.get__token,
                params: payload,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.notesedit=response.data
                    this.modalnoteview={
                        title:'Notas de Enfermeria Editadas',
                        modal_form: true
                    }
            }
            });
        },
        mtdclosemodalview:function(){
            this.modalnoteview={
                title:'',
                modal_form: false
            }
        },
        triggerFileInput(index) {
            this.$refs['fileInput' + index].click();
        },

        handleFileUpload(event, index) {
            let file = event.target.files[0];
            let allowedExtensions = /\.(png|jpg|jpeg|pdf)$/i;

            if (!allowedExtensions.test(file.name)) {
                this.filelaboratorio = ''
                this.filename = ''
                let fileType = "imagen o PDF";
                Swal.fire({
                    text: `Seleccione un archivo formato ${fileType}`,
                    icon: "info",
                    confirmButtonColor: "#900052",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const input = this.$refs['fileInput' + index];
                        input.type = "text";
                        input.type = "file";

                    }
                });
            } else {
                this.filelaboratorio = file;
                this.filename = this.filelaboratorio.name
                console.log(this.filename)
            }

        },
        mtdaddfile: function (id) {
            console.log(id)
            let formData = new FormData();
            formData.append("id", id);
            formData.append("file", this.filelaboratorio);

            this.post({
                url: this.$store.getters.get__url + "/Emergency/addfile",
                token: this.$store.getters.get__token,
                params: formData,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdGetNotas()
                    this.nota_dia = '',
                    this.nota_noche = ''
                    this.filelaboratorio = ''
                    this.filename = ''
                    this.hour = ''
                }
            });
        },

        openPdf: function (url) {
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, '_blank');
        },
        aplicacion:function(payload){
            this.$refs.SweetAlert.aplication(payload);
        },
        aplicaciondata:function(payload){
            this.post({
                url: this.$store.getters.get__url + "/Emergency/aplicationNurse",
                token: this.$store.getters.get__token,
                params: payload,
            }).then((response) => {
               
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.updateturn()
                    this.mtdGetplanes();
                    this.mtdGetNotas(); 
                
            });
        }

    }
}
</script>

<style scoped>
.plan th {
    background: #343a40;
    color: #fff;
}
</style>
