<template>
<div>
    <div id="app" class=" mt-3 text-center ">
        <div class="order-md-1 order-lg-2 py-1 py-xl-0 px-0 d-flex justify-content-between">
            <div class="row d-flex px-1 mx-1">
                <div class="col-md-10 d-inline-flex px-0 w-100 ms-1">
                    <button type="button" class="btn btn-sm text-white btn-sm bg-main text-xs py-0 me-2 " @click="saveBalance">
                        <i class="fas fa-save"></i> Guardar Balance
                    </button>
                    <button type="button" class="btn btn-sm text-white btn-sm bg-danger text-xs py-0" @click="pdfBalance"  :disabled="balance.length <= 1">
                        <i class="fa fa-file-pdf"></i> Exportar PDF
                    </button>
                </div>
            </div>
            <div class="row d-flex justify-content-end">
                <div class="col-12 px-0">
                    <nav aria-label="Page navigation example" class="pagination-container">
                        <ul class="pagination pagination-xs justify-content-end mb-0">
                            <li class="page-item">
                                <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                            </li>
                            <template>
                                <li class="page-item">
                                    <a class="page-link active" href="javascript:void(0)">{{ water_balance.date_name }}</a>
                                </li>
                            </template>
                            <li class="page-item">
                                <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-2 d-flex responsive-container mb-3">
            <div v-if="this.type_patient == 'particular'" class="font-size-ca">
                <div class="row mx-0 px-0 mb-2 text-start ">
                    <div class="px-0">
                        <span class="color-main fw-bold ">PACIENTE: </span><span >{{ this.patient_name }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA INGRESO: </span><span >{{ formatDate(this.date_in) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA SALIDA: </span><span >{{ formatDate(this.date_out) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">PESO: </span><span >{{ peso }}<span v-if="peso !='-'">KG</span></span>
                    </div>
                </div>
            </div>
            <div v-if="this.type_patient == 'eps'" class="font-size-ca">
                <div class="row mx-0 px-0 mb-2 text-start ">
                    <div class="px-0">
                        <span class="color-main fw-bold ">PACIENTE: </span><span >{{ this.patient_name }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">CONVENIO: </span><span >{{ this.insurance_name }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA INGRESO: </span><span >{{ formatDate(this.date_in) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">FECHA SALIDA: </span><span >{{ formatDate(this.date_out) }}</span>
                    </div>
                    <div class="px-0">
                        <span class="color-main fw-bold ">PESO: </span><span >{{ peso }} <span v-if="peso !='-'">KG</span></span>
                    </div>
                </div>
            </div>

            <!-- Fecha -->
            <div class="row mx-0 px-2 text-start justify-content-start">
                <div class="col-auto px-1">
                    <label class="fw-bold font-size">FECHA DE INGRESO:</label>
                    <input 
                        type="date" 
                        class="form-control form-control-sm" 
                        style="width: 220px;" 
                        :disabled="balance.length > 1"
                        v-model="water_balance.date_entry" 
                        :max="currentDate" 
                    />
                </div>
                <div class="col-auto px-1" v-if="isDatePast">
                    <label class="fw-bold font-size">HORA DE INGRESO:</label>
                    <input 
                        type="time" 
                        class="form-control form-control-sm" 
                        style="width: 120px;" 
                        v-model="water_balance.hourBalance" 
                        :disabled="validation" 
                    />
                </div>
                <div class="col-auto px-1">
                    <label class="fw-bold font-size">PESO:</label>
                    <input 
                        type="text" 
                        class="form-control form-control-sm" 
                        style="width: 100px;" 
                        v-model="water_balance.weight" 
                        placeholder="Peso Kg."
                        v-on:keypress="isNumber($event)"
                        :disabled="validation" 
                    />
                </div>
                <div class="col-auto px-1 d-flex align-items-end" v-if="!validation">
                    <button 
                        class="btn btn-sm text-white btn-sm bg-main" 
                        @click="generateBalance"
                        :disabled="isGenerateButtonDisabled"
                    >
                        Generar Balance
                    </button>
                </div>
            </div>
            <!--Hora-->
            <div class="row mx-0 px-2 text-start justify-content-start" v-if=" water_balance.hourNum ">
                <div class="col-auto px-2">
                    <p><strong>Horas : {{ water_balance.hourNum }}</strong></p>
                </div>
            </div>
            <div class="row d-flex"  v-if="showTable || validation">
                <!-- Ingresos -->
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                    <div class="row mx-0 mb-3 px-0">
                        <table class="w-100">
                            <caption class="div-text my-0 py-0"></caption>
                            <thead class="txt-xs">
                                <tr>
                                    <th class="text-start text-xs pb-2" scope="col" colspan="5">INGRESOS:</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1">
                                    <td>
                                        <select v-model="ingreso.name" class="form-control form-control-input form-control-sm" v-if="ingreso.name != 'otros'">
                                            <option value="" disabled selected>Seleccione Ingreso</option>
                                            <option value="dieta">Dieta</option>
                                            <option value="tratamiento">Tratamiento Endocrino</option>
                                            <option value="via_oral">Vía Oral</option>
                                            <option value="sonda_nasogastrica">Por sonda Nasogástrica</option>
                                            <option value="tratamiento_entera">Tratamiento para Enterar</option>
                                            <option value="transfusiones">Transfusiones</option>
                                            <option value="otros">Otros</option>
                                        </select>
                                        <input v-if="ingreso.name === 'otros'" type="text" v-model="ingreso.otherText" placeholder="Ingresa Nombre" class="form-control form-control-input form-control-sm"/>
                                    </td>
                                    <td><input v-on:keypress="isNumber($event)" type="text" v-model="ingreso.mañana" placeholder="Turno Mañana" class="form-control form-control-input form-control-sm"/></td>
                                    <td><input v-on:keypress="isNumber($event)" type="text" v-model="ingreso.tarde" placeholder="Turno Tarde" class="form-control form-control-input form-control-sm"/></td>
                                    <td><input v-on:keypress="isNumber($event)" type="text" v-model="ingreso.noche" placeholder="Turno Noche" class="form-control form-control-input form-control-sm"/></td>
                                    <td><button type="button" @click="mtdSaveBalance(1)" :disabled="incompleteIngreso" class="btn btn-success btn-sm px-1 py-0"><i class="fas fa-plus text-white"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row div-text mx-0 px-0 mb-2">
                        <table class="table table-bordered border-main mb-0 px-0 text-xs">
                            <caption class="my-0 py-0"></caption>
                            <thead class="txt-xs">
                                <tr class="text-white border-white text-center bg-main">
                                    <th scope="col" class="ingreso-egreso" colspan="2">Nombre</th>
                                    <th scope="col" class="ingreso-egreso">7:30 am. - 1:30 pm.</th>
                                    <th scope="col" class="ingreso-egreso">1:30 pm. - 7:30 pm.</th>
                                    <th scope="col" class="ingreso-egreso">Parcial</th>
                                    <th scope="col" class="ingreso-egreso">7:30 pm. - 7:30 am.</th>
                                    <th scope="col" class="ingreso-egreso">Total</th>
                                    <th scope="col" class="ingreso-egreso">Acción</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr v-for="(item, index) in  water_balance.dataIngreso" :key="item.id" class="px-1 border border-main align">
                                    <td colspan="2"><span>{{ nameTableIngreso(item.name, item.otherText) }}</span></td>
                                    <!--
                                    <td><span>{{ item.mañana ? parseFloat(item.mañana).toFixed(2) : item.mañana}}</span></td>
                                    -->
                                    <td>
                                        <div v-if="posEditField !== index || editField !== 'mañana'" @dblclick="mtdEditField(index, 'mañana')">
                                            {{ item.mañana ? parseFloat(item.mañana).toFixed(2) : item.mañana }}
                                        </div>
                                        <div v-else>
                                            <input type="text" v-model="item.editMañana" 
                                                @keyup.enter="mtdSaveEdit(index, 'mañana')" 
                                                @blur="mtdSaveEdit(index, 'mañana')" 
                                                @keypress="isNumber($event)" 
                                                class="form-control form-control-sm text-center" 
                                                placeholder="Mañana" />
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="posEditField !== index || editField !== 'tarde'" @dblclick="mtdEditField(index, 'tarde')">
                                            {{ item.tarde ? parseFloat(item.tarde).toFixed(2) : item.tarde }}
                                        </div>
                                        <div v-else>
                                            <input type="text" v-model="item.editTarde" 
                                                @keyup.enter="mtdSaveEdit(index, 'tarde')" 
                                                @blur="mtdSaveEdit(index, 'tarde')" 
                                                @keypress="isNumber($event)" 
                                                class="form-control form-control-sm text-center" 
                                                placeholder="Tarde" />
                                        </div>
                                    </td>
                                    <td><span>{{ getParcial(item) }}</span></td>
                                    <td>
                                        <div v-if="posEditField !== index || editField !== 'noche'" @dblclick="mtdEditField(index, 'noche')">
                                            {{ item.noche ? parseFloat(item.noche).toFixed(2) : item.noche }}
                                        </div>
                                        <div v-else>
                                            <input type="text" v-model="item.editNoche" 
                                                @keyup.enter="mtdSaveEdit(index, 'noche')" 
                                                @blur="mtdSaveEdit(index, 'noche')" 
                                                @keypress="isNumber($event)" 
                                                class="form-control form-control-sm text-center" 
                                                placeholder="Noche" />
                                        </div>
                                    </td>
                                    <td><span>{{ getTotal(item) }}</span></td>
                                    <td><button type="button" @click="mtdDeleteBalance(item.id, 1)" class="btn btn-danger btn-sm px-1 py-0" ><i class="fas fa-trash-alt text-white"></i></button></td>
                                </tr>
                                <tr v-for="(item, index1) in  water_balance.metabolic_water" :key="index1"  class="px-1 border border-main align">
                                    <td colspan="2"><strong>AGUA METABOLICA</strong></td>
                                    <td><span>{{ item.mañanaIn }}</span></td>
                                    <td><span>{{ item.tardeIn }}</span></td>
                                    <td><span>{{ item.parcialIn }}</span></td>
                                    <td><span>{{ item.nocheIn }}</span></td>
                                    <td><span>{{ item.totalIn }}</span></td>
                                    <td>-</td>
                                </tr>
                                <tr class="px-1 border border-main align">
                                    <td colspan="2"><strong>SUB-TOTAL</strong></td>
                                    <td><span>{{ getSubtotal('mañana', 1) }}</span></td>
                                    <td><span>{{ getSubtotal('tarde',1) }}</span></td>
                                    <td><span>{{ getSubtotal('parcial',1) }}</span></td>
                                    <td><span>{{ getSubtotal('noche',1) }}</span></td>
                                    <td><span>{{ getSubtotal('total',1) }}</span></td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Egresos -->
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                    <div class="row mx-0 mb-3 px-0">
                        <table class="w-100">
                            <caption class="div-text my-0 py-0"></caption>
                            <thead class="txt-xs">
                                <tr>
                                    <th class="text-start text-xs pb-2" scope="col" colspan="5">EGRESOS:</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1">
                                    <td>
                                        <select v-model="egreso.name" class="form-control form-control-input form-control-sm"  v-if="egreso.name != 'otros'">
                                            <option value="" disabled selected>Seleccione Egreso</option>
                                            <option value="diuresis">Diuresis</option>
                                            <option value="deposiciones">Deposiciones</option>
                                            <option value="vomitos">Vómitos</option>
                                            <option value="drenes">Drenes</option>
                                            <option value="otros">Otros</option>
                                        </select>
                                        <input v-if="egreso.name === 'otros'" type="text" v-model="egreso.otherText" placeholder="Ingresa Nombre" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td><input v-on:keypress="isNumber($event)" type="text" v-model="egreso.mañana" placeholder="Turno Mañana" class="form-control form-control-input form-control-sm" /></td>
                                    <td><input v-on:keypress="isNumber($event)" type="text" v-model="egreso.tarde" placeholder="Turno Tarde" class="form-control form-control-input form-control-sm" /></td>
                                    <td><input v-on:keypress="isNumber($event)" type="text" v-model="egreso.noche" placeholder="Turno Noche" class="form-control form-control-input form-control-sm" /></td>
                                    <td><button type="button" @click="mtdSaveBalance(2)" :disabled="incompleteEgreso" class="btn btn-success btn-sm px-1 py-0"><i class="fas fa-plus text-white"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row div-text mx-0 px-0 mb-2">
                        <table class="table table-bordered border-main mb-0 px-0 text-xs">
                            <caption class="my-0 py-0"></caption>
                            <thead class="txt-xs">
                                <tr class="text-white border-white text-center bg-main">
                                    <th scope="col" class="ingreso-egreso" colspan="2">Nombre</th>
                                    <th scope="col" class="ingreso-egreso">7:30 am. - 1:30 pm.</th>
                                    <th scope="col" class="ingreso-egreso">1:30 pm. - 7:30 pm.</th>
                                    <th scope="col" class="ingreso-egreso">Parcial</th>
                                    <th scope="col" class="ingreso-egreso">7:30 pm. - 7:30 am.</th>
                                    <th scope="col" class="ingreso-egreso">Total</th>
                                    <th scope="col" class="ingreso-egreso">Acción</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr v-for="(item, index) in water_balance.dataEgreso" :key="item.id" class="px-1 border border-main align">
                                    <td colspan="2"><span>{{ nameTableEgreso(item.name, item.otherText) }}</span></td>
                                    <!--
                                    <td><span>{{ item.mañana ? parseFloat(item.mañana).toFixed(2) : item.mañana}}</span></td>
                                    -->
                                    <td>
                                        <div v-if="posEditField !== index || editField !== 'mañanaEgreso'" @dblclick="mtdEditField(index, 'mañanaEgreso')">
                                            {{ item.mañana ? parseFloat(item.mañana).toFixed(2) : item.mañana }}
                                        </div>
                                        <div v-else>
                                            <input type="text" v-model="item.editMañana" 
                                                @keyup.enter="mtdSaveEdit(index, 'mañanaEgreso')" 
                                                @blur="mtdSaveEdit(index, 'mañanaEgreso')" 
                                                @keypress="isNumber($event)" 
                                                class="form-control form-control-sm text-center" 
                                                placeholder="Mañana" />
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="posEditField !== index || editField !== 'tardeEgreso'" @dblclick="mtdEditField(index, 'tardeEgreso')">
                                            {{ item.tarde ? parseFloat(item.tarde).toFixed(2) : item.tarde }}
                                        </div>
                                        <div v-else>
                                            <input type="text" v-model="item.editTarde" 
                                                @keyup.enter="mtdSaveEdit(index, 'tardeEgreso')" 
                                                @blur="mtdSaveEdit(index, 'tardeEgreso')" 
                                                @keypress="isNumber($event)" 
                                                class="form-control form-control-sm text-center" 
                                                placeholder="Tarde" />
                                        </div>
                                    </td>
                                    <td><span>{{ getParcial(item) }}</span></td>
                                    <td>
                                        <div v-if="posEditField !== index || editField !== 'nocheEgreso'" @dblclick="mtdEditField(index, 'nocheEgreso')">
                                            {{ item.noche ? parseFloat(item.noche).toFixed(2) : item.noche }}
                                        </div>
                                        <div v-else>
                                            <input type="text" v-model="item.editNoche" 
                                                @keyup.enter="mtdSaveEdit(index, 'nocheEgreso')" 
                                                @blur="mtdSaveEdit(index, 'nocheEgreso')" 
                                                @keypress="isNumber($event)" 
                                                class="form-control form-control-sm text-center" 
                                                placeholder="Noche" />
                                        </div>
                                    </td>
                                    <td><span>{{ getTotal(item) }}</span></td>
                                    <td><button type="button" @click="mtdDeleteBalance(item.id, 2)" class="btn btn-danger btn-sm px-1 py-0" ><i class="fas fa-trash-alt text-white"></i></button></td>
                                </tr>
                                <tr v-for="(item, index2) in  water_balance.insensible_losses" :key="index2"  class="px-1 border border-main align">
                                    <td colspan="2"><strong>PERDIDAS INSENSIBLES</strong></td>
                                    <td><span>{{ item.mañanaEg }}</span></td>
                                    <td><span>{{ item.tardeEg }}</span></td>
                                    <td><span>{{ item.parcialEg }}</span></td>
                                    <td><span>{{ item.nocheEg }}</span></td>
                                    <td><span>{{ item.totalEg }}</span></td>
                                    <td>-</td>
                                </tr>
                                <tr class="px-1 border border-main align">
                                    <td colspan="2"><strong>SUB-TOTAL</strong></td>
                                    <td><span>{{ getSubtotal('mañana', 2) }}</span></td>
                                    <td><span>{{ getSubtotal('tarde',2) }}</span></td>
                                    <td><span>{{ getSubtotal('parcial',2) }}</span></td>
                                    <td><span>{{ getSubtotal('noche',2) }}</span></td>
                                    <td><span>{{ getSubtotal('total',2) }}</span></td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Balance -->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                    <div class="row mx-0 mb-1 px-0">
                        <table class="w-100">
                            <caption class="div-text my-0 py-0"></caption>
                            <thead class="txt-xs">
                                <tr>
                                    <th class="text-start text-xs pb-2" scope="col" colspan="5">BALANCE HIDRICO:</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="row div-text mx-0 px-0 mb-2">
                        <table class="table table-bordered border-main mb-0 px-0 text-xs">
                            <caption class="my-0 py-0"></caption>
                            <thead class="txt-xs">
                                <tr class="text-white border-white text-center bg-main">
                                    <th scope="col" class="ingreso-egreso">7:30 am. - 1:30 pm.</th>
                                    <th scope="col" class="ingreso-egreso">1:30 pm. - 7:30 pm.</th>
                                    <th scope="col" class="ingreso-egreso">Parcial</th>
                                    <th scope="col" class="ingreso-egreso">7:30 pm. - 7:30 am.</th>
                                    <th scope="col" class="ingreso-egreso">Total</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1 border border-main align">
                                    <td><span>{{ calculateDifference('mañana') }}</span></td>  
                                    <td><span>{{ calculateDifference('tarde') }}</span></td>   
                                    <td><span>{{ calculateDifference('parcial') }}</span></td>  
                                    <td><span>{{ calculateDifference('noche') }}</span></td>  
                                    <td><span>{{ calculateDifference('total') }}</span></td>    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-asistencial-hospitalizacion-historia-balance',
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            act_id: 0,
            ingreso: {
                id: null,
                otherText: '',
                name: '',
                mañana: '',
                tarde: '',
                noche: '',
            },
            dataIngreso: {
                id: null,
                name: '',
                otherText: '',
                mañana: '',
                tarde: '',
                noche: '',
            },
            egreso: {
                id: null,
                name: '',
                otherText: '',
                mañana: '',
                tarde: '',
                noche: '',
            },
            dataEgreso: {
                id: null,
                name: '',
                otherText: '',
                mañana: '',
                tarde: '',
                noche: '',
            },
            metabolic_water: {
                mañanaIn: '',
                tardeIn: '',
                parcialIn: '',
                nocheIn: '',
                totalIn: '',
            },
            insensible_losses: {
                mañanaEg: '',
                tardeEg: '',
                parcialEg: '',
                nocheEg: '',
                totalEg: '',
            },
            patient_name: '',
            patient_year: '',
            insurance_id: '',
            insurance_name: '',
            type_patient: null,
            peso: '',
            pos: null,
            validation: false,
            
            balance: [],
            water_balance: {
                date_name: 'BH ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                dataIngreso: [
                ],
                dataEgreso: [
                ],
                metabolic_water: [
                ],
                insensible_losses: [
                ],
                date_entry: null,
                weight: null,
                hourBalance: null,
                hourNum: null,
                shift: null
            },
            agua: [],
            showTable: false,
            selectedDate: moment().format('YYYY-MM-DD'),
            selectedTime: '',
            selectedPeso: '',
            isDatePast: true,
            currentDate: moment().format('YYYY-MM-DD'),
            posEditField: null, 
            editField: '', 
        }
    },

    created() {},
    props: {
        medical_act_id: {
            type: Number,
            default: 0
        },
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0) {
                    this.mtdGetBalance();
                    this.mtdtypepatient();
                }
            },
            deep: true,
            immediate: true,
        },
        'water_balance.date_entry'(newDate) {
            this.showTable = false;
            if (newDate === moment().format('YYYY-MM-DD')) {
                if (!this.validation) {
                    this.water_balance.hourBalance = null; 
                    this.water_balance.weight = null; 
                }
                this.isDatePast = true;  
            } else {
                if (!this.validation) {
                    this.water_balance.weight = null; 
                }
                this.water_balance.hourBalance = '24'; 
                this.isDatePast = false;  
            }
        },
        'water_balance.weight'(newDate) {
            this.showTable = false;
        },
        'water_balance.hourBalance'(newDate) {
            if (!this.validation) {
                this.water_balance.hourNum = '';
            }
        },
        pos: function () {
            this.checkDateEntry(); 
        },
    },
    computed: {
        incompleteIngreso() {
            return (
                !this.ingreso.name.trim() || 
                !(this.ingreso.mañana.trim() || this.ingreso.tarde.trim() || this.ingreso.noche.trim()) ||
                (this.ingreso.name === 'otros' && !this.ingreso.otherText.trim())
            );
        },
        incompleteEgreso() {
            return (
                !this.egreso.name.trim() || 
                !(this.egreso.mañana.trim() || this.egreso.tarde.trim() || this.egreso.noche.trim()) ||
                (this.egreso.name === 'otros' && !this.egreso.otherText.trim())
            );
        },
        isGenerateButtonDisabledaea() {
            const isPastDate = moment(this.water_balance.date_entry).isSame(this.currentDate);
            const isInvalidTime = this.water_balance.hourBalance <= 0 || this.water_balance.hourBalance >= 24 || this.water_balance.hourBalance === '' || this.water_balance.hourBalance === null
            || this.water_balance.weight <= 0 || this.water_balance.weight === "" || this.water_balance.weight === '' || this.water_balance.weight === null;

            return isPastDate && isInvalidTime;
        },
        isGenerateButtonDisabled() {
            const isDateEmptyOrInvalid = !this.water_balance.date_entry || moment(this.water_balance.date_entry).isSame(this.currentDate);
            const isInvalidTime = this.water_balance.hourBalance <= 0 || this.water_balance.hourBalance >= 24 || this.water_balance.hourBalance === '' || this.water_balance.hourBalance === null
                || this.water_balance.weight === "" || this.water_balance.weight === null;

            return isDateEmptyOrInvalid && isInvalidTime;
        },
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdtypepatient: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/salePatient",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id
                    },
                })
                .then((response) => {   
                    this.sale = response.data;
                        this.type_patient = response.data.type_patient;
                        this.date_in = response.data.date_ind;
                        this.date_out = response.data.date_out;
                        this.insurance_id = response.data.insurance ? response.data.insurance.id : null;
                        this.insurance_name = response.data.insurance ? response.data.insurance.name  : '';
                        this.patient_name = response.data.patient.name;
                        this.patient_year = response.data.patient.year;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        getParcial(item) {
            const mañana = parseFloat(item.mañana) || 0;
            const tarde = parseFloat(item.tarde) || 0;
            return (mañana + tarde).toFixed(2);
        },

        getTotal(item) {
            const parcial = parseFloat(this.getParcial(item)) || 0;     
            const noche = parseFloat(item.noche) || 0;
            return (parcial + noche).toFixed(2);
        },

        mtdSaveBalance: function (type) {
            if (type == 1) {
                //const exists = this.water_balance.dataIngreso.some(item => item.name.trim() === this.ingreso.name.trim());
                const exists = this.water_balance.dataIngreso.some(item => 
                    item.name.trim() === this.ingreso.name.trim() && item.name.toLowerCase() !== 'otros'
                );
                const i = this.nameTableIngreso(this.ingreso.name.trim(), this.ingreso.otherText);
                if (exists) {
                    this.$refs.SweetAlert.showWarning(`Ingreso "${i}" ya Agregado`);
                } else {
                    this.ingreso.id = this.water_balance.dataIngreso.length + 1;
                    //Cambiar a 0
                    this.ingreso.mañana = (this.ingreso.mañana == "" ? 0 : this.ingreso.mañana);
                    this.ingreso.tarde = (this.ingreso.tarde == "" ? 0 : this.ingreso.tarde);
                    this.ingreso.noche = (this.ingreso.noche == "" ? 0 : this.ingreso.noche);
                    //Fin cambiar a 0
                    this.water_balance.dataIngreso.push(this.ingreso);
                }
                this.ingreso = {
                    name: '',
                    otherText: '',
                    mañana: '',
                    tarde: '',
                    noche: '',
                }
            } else if (type == 2) {
                //const existsE = this.water_balance.dataEgreso.some(item => item.name.trim() === this.egreso.name.trim());
                const existsE = this.water_balance.dataEgreso.some(item => 
                    item.name.trim() === this.egreso.name.trim() && item.name.toLowerCase() !== 'otros'
                );
                const e = this.nameTableEgreso(this.egreso.name.trim(), this.egreso.otherText);
                if (existsE) {
                    this.$refs.SweetAlert.showWarning(`Egreso "${e}" ya Agregado`);
                } else {
                    this.egreso.id = this.water_balance.dataEgreso.length + 1;
                    //Cambiar a 0
                    this.egreso.mañana = (this.egreso.mañana == "" ? 0 : this.egreso.mañana);
                    this.egreso.tarde = (this.egreso.tarde == "" ? 0 : this.egreso.tarde);
                    this.egreso.noche = (this.egreso.noche == "" ? 0 : this.egreso.noche);
                    //Fin cambiar a 0
                    this.water_balance.dataEgreso.push(this.egreso);
                }
                this.egreso = {
                    name: '',
                    otherText: '',
                    mañana: '',
                    tarde: '',
                    noche: '',
                }
            }
        },

        saveBalance: function(){
            if (!this.water_balance.dataIngreso.length && !this.water_balance.dataEgreso.length) {
                this.$refs.SweetAlert.showWarning("Llena al menos un Ingreso o Egreso");
                return;
            }
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro de Guardar estos Balances?", "warning", "Guardar")
            .then((result) => {
                if (result.value) {
                    this.water_balance.medical_act_id = this.medical_act_id;
                    this.post({
                        url: this.$store.getters.get__url + "/Hospitalization/balance",
                        token: this.$store.getters.get__token,
                        params: this.water_balance,
                    })
                    .then((response) => {
                        if(response.data.state == 0){
                            this.$refs.SweetAlert.showSuccess(response.message);
                            this.water_balance = {
                                date_name: 'BH ACTUAL',
                                id: null,
                                medical_act_id: null,
                                date: null,
                                hour: null,
                                dataIngreso: [
                                ],
                                dataEgreso: [
                                ],
                                metabolic_water: [
                                ],
                                insensible_losses: [
                                ],
                                date_entry: null,
                                weight: null,
                                hourBalance: null,
                                hourNum: null,
                                shift: null
                            },
                            this.mtdGetBalance();
                        }else if(response.data.state == 1){
                            this.$refs.SweetAlert.showWarning(response.message);
                            this.water_balance = {
                                date_name: 'BH ACTUAL',
                                id: null,
                                medical_act_id: null,
                                date: null,
                                hour: null,
                                dataIngreso: [
                                ],
                                dataEgreso: [
                                ],
                                metabolic_water: [
                                ],
                                insensible_losses: [
                                ],
                                date_entry: null,
                                weight: null,
                                hourBalance: null,
                                hourNum: null,
                                shift: null
                            }
                        }
                        this.validation = false,
                        this.showTable = false,
                        this.posEditField = null, 
                        this.editField = ''
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
                }
            });
        },

        mtdDeleteBalance: function (id, type) {
            if (type == 1) {
                this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro eliminar este Balance de Ingreso?", "warning", "Eliminar")
                    .then((result) => {
                        if (result.value) {
                            const indice = this.water_balance.dataIngreso.findIndex(item => item.id === id);
                            if (indice !== -1) {
                                this.water_balance.dataIngreso.splice(indice, 1);
                                this.ingreso = {
                                    name: '',
                                    otherText: '',
                                    mañana: '',
                                    tarde: '',
                                    noche: '',
                                }
                            }
                        }
                    });
            } else if (type == 2) {
                this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro eliminar este Balance de Egreso?", "warning", "Eliminar")
                    .then((result) => {
                        if (result.value) {
                            const indice = this.water_balance.dataEgreso.findIndex(item => item.id === id);
                            if (indice !== -1) {
                                this.water_balance.dataEgreso.splice(indice, 1);
                                this.egreso = {
                                    name: '',
                                    otherText: '',
                                    mañana: '',
                                    tarde: '',
                                    noche: '',
                                }
                            }
                        }
                    });
            }
        },
        
        mtdGetBalance: function () {
                this.get({
                    url: this.$store.getters.get__url + "/Hospitalization/mtdGetBalance/" + this.medical_act_id,
                    token: this.$store.getters.get__token,
                }).then((response) => {
                    if (response.statusCode === 200) {
                        this.balance=[]

                        response.data.balance.forEach(item => {
                            let balancedetails = {
                                date_name: item.datename,
                                id: item.id,
                                medical_act_id: item.medical_act_id,
                                date: item.date,
                                hour: item.hour,
                                dataIngreso: JSON.parse(item.dataIngreso), 
                                dataEgreso:JSON.parse(item.dataEgreso),
                                metabolic_water:JSON.parse(item.metabolic_water),
                                insensible_losses:JSON.parse(item.insensible_losses),
                                date_entry: item.date_entry,
                                weight: item.weight,
                                hourBalance: item.hourBalance,
                                hourNum: item.hourNum,
                                shift: item.shift
                            }
                            this.balance.push(balancedetails);
                        })
                        this.balance.push(this.water_balance);
                        this.pos = this.balance.length - 1;

                        // Asigna el último date_entry si existe
                        if (this.balance.length > 1) {
                            const lastBalance = this.balance[this.balance.length - 2];
                            this.water_balance.date_entry = lastBalance.date_entry; 
                        }
                        this.peso = response.data.peso
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        generateBalance() {
            if (this.water_balance.date_entry === this.currentDate) {
                this.showTable = true;
                this.mtdGetAgua();
            } else  {
                this.water_balance.hourBalance = 24;
                this.mtdGetAgua();
                this.showTable = true;
            }
        },
        
        mtdGetAgua: function () {
            const fecha = this.water_balance.date_entry;
            const hora = this.water_balance.hourBalance;
            const peso = this.water_balance.weight;
            const year = this.patient_year;
                this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/mtdGetAgua",
                    token: this.$store.getters.get__token,
                    params: {
                        fecha: fecha,
                        hora: hora,
                        peso: peso,
                        year: year
                    },
                }).then((response) => {
                    this.water_balance.metabolic_water = [];
                    this.water_balance.insensible_losses = [];
                    if (response.statusCode === 200) {
                        this.metabolic_water = {
                            mañanaIn: response.data.mañanaIn,
                            tardeIn: response.data.tardeIn,
                            parcialIn: response.data.parcialIn,
                            nocheIn: response.data.nocheIn,
                            totalIn: response.data.totalIn,
                        }
                        this.insensible_losses = {
                            mañanaEg: response.data.mañanaEg,
                            tardeEg: response.data.tardeEg,
                            parcialEg: response.data.parcialEg,
                            nocheEg: response.data.nocheEg,
                            totalEg: response.data.totalEg,
                        }
                        this.water_balance.metabolic_water.push(this.metabolic_water);
                        this.water_balance.insensible_losses.push(this.insensible_losses);
                        this.water_balance.hourNum = response.data.horas;

                        this.metabolic_water = {
                            mañanaIn: '',
                            tardeIn: '',
                            parcialIn: '',
                            nocheIn: '',
                            totalIn: '',
                        },
                        this.insensible_losses = {
                            mañanaEg: '',
                            tardeEg: '',
                            parcialEg: '',
                            nocheEg: '',
                            totalEg: '',
                        }
                    }else{
                        this.water_balance.metabolic_water = [];
                        this.water_balance.insensible_losses = [];
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        getSubtotal(period, type) {
            let total = 0;
            if(type == 1){
                this.water_balance.dataIngreso.forEach(item => {
                    if (period === 'parcial') {
                        total += parseFloat(this.getParcial(item)) || 0;
                    } else if (period === 'total') {
                        total += parseFloat(this.getTotal(item)) || 0; 
                    } else {
                        total += parseFloat(item[period]) || 0; 
                    }
                });
                if (this.water_balance.metabolic_water && this.water_balance.metabolic_water[0]) {
                    const metabolicWater = this.water_balance.metabolic_water[0];
                    
                    if (period === 'mañana') {
                        total += parseFloat(metabolicWater.mañanaIn || 0);
                    } else if (period === 'tarde') {
                        total += parseFloat(metabolicWater.tardeIn || 0);
                    } else if (period === 'noche') {
                        total += parseFloat(metabolicWater.nocheIn || 0);
                    } else if (period === 'parcial') {
                        total += parseFloat(metabolicWater.parcialIn || 0);
                    } else if (period === 'total') {
                        total += parseFloat(metabolicWater.totalIn || 0);
                    }
                }
                return total.toFixed(2); 
            }else if(type==2) {
                this.water_balance.dataEgreso.forEach(item => {
                    if (period === 'parcial') {
                        total += parseFloat(this.getParcial(item)) || 0;
                    } else if (period === 'total') {
                        total += parseFloat(this.getTotal(item)) || 0; 
                    } else {
                        total += parseFloat(item[period]) || 0; 
                    }
                });
                
                if (this.water_balance.insensible_losses && this.water_balance.insensible_losses[0]) {
                    const insensibleLosses = this.water_balance.insensible_losses[0];

                    if (period === 'mañana') {
                        total += parseFloat(insensibleLosses.mañanaEg || 0);
                    } else if (period === 'tarde') {
                        total += parseFloat(insensibleLosses.tardeEg || 0);
                    } else if (period === 'noche') {
                        total += parseFloat(insensibleLosses.nocheEg || 0);
                    } else if (period === 'parcial') {
                        total += parseFloat(insensibleLosses.parcialEg || 0);
                    } else if (period === 'total') {
                        total += parseFloat(insensibleLosses.totalEg || 0);
                    }
                }
                return total.toFixed(2); 
            }
        },

        calculateDifference(period) {
            const subtotal1 = parseFloat(this.getSubtotal(period, 1)) || 0; 
            const subtotal2 = parseFloat(this.getSubtotal(period, 2)) || 0; 
            const difference = subtotal1 - subtotal2; 
            return difference.toFixed(2); 
        },

        nameTableIngreso(name, otherText) {
            switch (name) {
            case 'dieta':
                return "Dieta";
            case 'tratamiento':
                return "Tratamiento Endocrino";
            case 'via_oral':
                return "Via Oral";
            case 'sonda_nasogastrica':
                return "Por sonda Nasogástrica";
            case 'tratamiento_entera':
                return "Tratamiento para Enterar";
            case 'transfusiones':
                return "Transfusiones";
            case 'agua_metabolica':
                return "Agua Metabólica";
            case 'otros':
                return otherText;
            default:
                return "-";
            }
        },

        nameTableEgreso(name, otherText) {
            switch (name) {
            case 'diuresis':
                return "Diuresis";
            case 'deposiciones':
                return "Deposiciones";
            case 'vomitos':
                return "Vómitos";
            case 'drenes':
                return "Drenes";
            case 'perdidas':
                return "Pérdidas Insensibles";
            case 'otros':
                return otherText;
            default:
                return "-";
            }
        },

        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },

        //Funciones para Inputs
        mtdEditField(index, field) {
            this.resetEditFields();
            this.posEditField = index;
            this.editField = field;
            
            if (field === "mañana" || field === "mañanaEgreso") {
                this.water_balance[field === "mañana" ? 'dataIngreso' : 'dataEgreso'][index].editMañana = this.water_balance[field === "mañana" ? 'dataIngreso' : 'dataEgreso'][index].mañana;
            } else if (field === "tarde" || field === "tardeEgreso") {
                this.water_balance[field === "tarde" ? 'dataIngreso' : 'dataEgreso'][index].editTarde = this.water_balance[field === "tarde" ? 'dataIngreso' : 'dataEgreso'][index].tarde;
            } else if (field === "noche" || field === "nocheEgreso") {
                this.water_balance[field === "noche" ? 'dataIngreso' : 'dataEgreso'][index].editNoche = this.water_balance[field === "noche" ? 'dataIngreso' : 'dataEgreso'][index].noche;
            }
        },

        mtdSaveEdit(index, field) {
            if (field === "mañana" || field === "mañanaEgreso") {
                const newMañana = parseFloat(this.water_balance[field === "mañana" ? 'dataIngreso' : 'dataEgreso'][index].editMañana);
                if (!isNaN(newMañana)) {
                    this.water_balance[field === "mañana" ? 'dataIngreso' : 'dataEgreso'][index].mañana = newMañana.toFixed(2);
                }
            } else if (field === "tarde" || field === "tardeEgreso") {
                const newTarde = parseFloat(this.water_balance[field === "tarde" ? 'dataIngreso' : 'dataEgreso'][index].editTarde);
                if (!isNaN(newTarde)) {
                    this.water_balance[field === "tarde" ? 'dataIngreso' : 'dataEgreso'][index].tarde = newTarde.toFixed(2);
                }
            } else if (field === "noche" || field === "nocheEgreso") {
                const newNoche = parseFloat(this.water_balance[field === "noche" ? 'dataIngreso' : 'dataEgreso'][index].editNoche);
                if (!isNaN(newNoche)) {
                    this.water_balance[field === "noche" ? 'dataIngreso' : 'dataEgreso'][index].noche = newNoche.toFixed(2);
                }
            }

            this.resetEditFields();
        },

        resetEditFields() {
            this.posEditField = null;
            this.editField = '';
        },

        isNumber(evt) {
            evt = evt || window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        //Paginacion Balance
        nextPage: function () {
            this.pos = (this.pos == this.balance.length - 1) ? this.pos : this.pos + 1
            this.water_balance = this.balance[this.pos]
            this.validation = this.water_balance.date_name != 'BH ACTUAL'
        },

        backPage: function () {
            this.pos = (this.pos == 0) ? this.pos : this.pos - 1
            this.water_balance = this.balance[this.pos]
            this.validation = this.water_balance.date_name != 'BH ACTUAL'
        },

        checkDateEntry: function () {
            // Verificar si date_entry es menor que date
            if (this.water_balance.date !== null) {
                if (moment(this.water_balance.date_entry).isSame(this.water_balance.date)) {
                    this.isDatePast = true; // Si es igual que date
                } else {
                    this.isDatePast = false; 
                }
            } else {
                this.isDatePast = false;
            }
        },

        //Pdf
        pdfBalance(){
            window.open(
                this.$store.getters.get__url + "/Hospitalization/viewpdfBalance/" + this.medical_act_id,
                "_blank"
            );
        }
        
    }
}
</script>

<style>
.ingreso-egreso {
    white-space: nowrap !important;
    vertical-align: middle !important;
}

.align {
    vertical-align: middle !important;
}

.font-size{
    font-size: 13px;
}

.font-size-ca{
    font-size: 14px;
}
</style>
