<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0">
                <div class="mb-2 col-xl-12 px-1">
                    <label for="name" class="form-label">S/ Precio Particular</label>
                    <input type="text" class="form-control form-control-sm" @keypress="isNumber($event)"  v-model="data_detail.precio_farmacia_particular" placeholder=" Nombre">
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label for="name" class="form-label">S/ Precio Kairos</label>
                    <input type="text" class="form-control form-control-sm"   @keypress="isNumber($event)"  v-model="data_detail.precio_farmacia_kairos" placeholder=" Nombre">
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
                {{ (num == 0 ) ? 'Registrar':'Editar' }}
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            data_detail: {
                precio_farmacia_particular: '',
                precio_farmacia_kairos: '',
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if(this.num == 1){
                    if (newVal) {
                    this.data_detail = {
                        ...newVal,
                    };
                }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
    },
    computed: {
        cpButton() {
                return !(this.data_detail.precio_farmacia_kairos !== '' && this.data_detail.precio_farmacia_particular !== '');
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail.precio_farmacia_kairos = '';
            this.data_detail.precio_farmacia_particular = '';
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro de Editar estos Precios?","warning","Editar") 
            .then((result) => {
                if (result.value) {
                    this.$emit('Datadetail', this.data_detail, this.num)
                    this.mtdClose();
                }
            });
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
    

};
</script>