<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xs-12 text-center">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Cantidad</th>
              <th>Servicio</th>
              <th>Precio</th>
              <th>Subtotal</th>
              <th>Estado</th>
              <th v-if="permissionEli == 1">Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(det, index) in cpData" :key="index">
              <template v-if="det.subsub_categorie_id != 13061">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ det.quantity }}
                </td>
                <td>{{ det.subsub_categorie.name }}</td>
                <td
                  v-if="posEditPrice !== index"
                  @dblclick="mtdEditPrecies(index)"
                >
                  {{ det.price }}
                </td>
                <td v-else>
                  <template>
                    <input
                      :disabled="det.state === 1"
                      type="text"
                      class="form-control-sm w-50 text-center"
                      v-model="newPrice"
                      @keyup.enter="mtdOutFocusEditPrices"
                      @blur="mtdOutFocusEditPrices"
                      @keypress="validateNumberInput"
                    />
                  </template>
                  <!-- <template v-else>
                    <span @click="showNoPermissionAlert">{{ det.price }}</span>
                  </template> -->
                </td>
                <td>{{ parseFloat(det.quantity * det.price).toFixed(2) }}</td>
                <td>
                  <div v-if="det.state == 2">
                    <!--
                   <button @click="mtdDelCarrito(det.id)" class="btn btn-sm btn-danger"  style="margin-right: 10px;"> 
                    <i class="fas fa-trash"></i>
                  </button> 
                    -->
                    <span class="badge rounded-pill text-bg-danger"
                      >Por Pagar</span
                    >
                  </div>
                  <div v-else>
                    <span class="badge rounded-pill text-bg-success"
                      >Pagado</span
                    >
                  </div>
                </td>
                <td v-if="permissionEli == 1">
                  <div v-if="det.state == 2">
                    <button
                      @click="mtdDelCarrito(det.id)"
                      class="btn btn-sm btn-danger"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      <!-- &nbsp;
      <button class="btn btn-success btn-sm" @click="mtdEditValor">
        <i class="fas fa-save" />&nbsp; Editar
      </button> -->
      &nbsp;
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";

export default {
  name: "c-vigilancia-modal-control",
  components: {
    CModal,
    CButtonClose,
  },
  data() {
    return {
      ind: null,
      end: null,
      total: 0,
      posEditQuantity: null,
      newQuantity: null,
      oldQuantity: null,
      tabla: [],
      posEditPrice: null,
      newPrice: null,
      oldPrice: null,
      //Quitar Permiso
      permissionValue: 0,
    };
  },
  computed: {
    cpData() {
      let show = [];
      this.item.forEach((element) => {
        // if (element.subsub_categorie_id != 13061) {
        show.push(element);
        // }
      });
      this.tabla = show;
      return show;
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
    //Al quitar Permiso comento Props
    //permissionValue: Number,
    permissionEli: Number,
  },
  methods: {
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },
    mtdClose() {
      this.posEditPrice = null;
      this.posEditQuantity = null;
      this.$emit("mtdCloseModalEdiatCarrito");
    },
    mtdDelCarrito: function (id) {
      if (this.permissionEli == 1) {
        this.$emit("mtdCommitDelCarrito", id);
      } else {
        Swal.fire({
          title: "No tienes permiso para eliminar este ítem",
          icon: "warning",
          confirmButtonColor: "#900052",
          confirmButtonText: "OK",
          width: "400px",
        });
      }
    },
    // mtdEditQuantity: function (pos) {
    //   this.posEditQuantity = pos;
    //   this.oldQuantity = this.tabla[pos].quantity;
    //   this.newQuantity = this.tabla[pos].quantity;
    // },
    mtdEditPrecies: function (pos) {
      this.posEditPrice = pos;
      this.oldPrice = this.tabla[pos].price;
      this.newPrice = this.tabla[pos].price;
    },
    mtdOutFocusEditPrice: function () {
      if (this.oldQuantity != this.newQuantity && this.newQuantity > 0) {
        Swal.fire({
          icon: "warning",
          title: "¿Desea cambiar esta cantidad?",
          width: "400px",
          /** buttons */
          showDenyButton: true,
          denyButtonText: `Rechazar`,
          denyButtonColor: "#bb8a17",
          confirmButtonText: "Permitir",
          confirmButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit(
              "mtdCommitEditQuantity",
              this.posEditQuantity,
              this.newQuantity
            );
            this.posEditQuantity = null;
            this.oldQuantity = null;
            this.newQuantity = null;
          } else if (result.isDenied) {
            this.posEditQuantity = null;
            this.oldQuantity = null;
            this.newQuantity = null;
          }
        });
      } else {
        this.posEditQuantity = null;
        this.oldQuantity = null;
        this.newQuantity = null;
      }
    },
    mtdOutFocusEditPrices: function () {
      // if (
      //   this.oldPrice !== this.newPrice &&
      //   this.newPrice > 0 &&
      //   this.permissionValue === 1
      // ) {
        Swal.fire({
          icon: "warning",
          title: "¿Desea cambiar esta Precio?",
          width: "400px",
          /** buttons */
          showDenyButton: true,
          denyButtonText: `Rechazar`,
          denyButtonColor: "#bb8a17",
          confirmButtonText: "Permitir",
          confirmButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit("mtdCommitEditPrice", this.posEditPrice, this.newPrice);
            this.posEditPrice = null;
            this.oldPrice = null;
            this.newPrice = null;
          } else if (result.isDenied) {
            this.posEditPrice = null;
            this.oldPrice = null;
            this.newPrice = null;
          }
        });
      // } else {
      //   this.posEditPrice = null;
      //   this.oldPrice = null;
      //   this.newPrice = null;
      // }
    },
    showNoPermissionAlert() {
      Swal.fire({
        icon: "warning",
        text: "No tiene permiso para editar el precio",
        confirmButtonText: "OK",
        confirmButtonColor: "#900052",
      });
    },
  },
};
</script>
